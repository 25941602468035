import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import ListOperations from './ListOperations';
import ViewOperations from './ViewOperations';
import EditOperations from './EditOperations';
import CreateOperations from './CreateOperations';

class Operations extends BaseScreen {
  constructor (props) {
    super(props);
    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }
  }

  render () {
    return (
      <div>
        <div className="card my-4-custom">
          <Switch>
            <Route exact path={'/operations/list-operations'} component={ListOperations} />
            <Route exact path={'/operations/create-operations'} component={CreateOperations} />
            <Route exact path={'/operations/view-operations/:id'} component={ViewOperations} />
            <Route exact path={'/operations/edit-operations/:id'} component={EditOperations} />
            <Redirect to="/operations/list-operations" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Operations;
