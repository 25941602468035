import React from 'react';
import Grid from '../../../Component/grid/Grid';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { ImplementsService } from '../../../services/service.implements';
import i18n from 'i18next';

/**
 * Informations to be shown in implement listing grid, along with options per rows in support details page.
 */
let implementListConfig = {};

/**
 * List associated implement component
 */
class ListConcernedImplement extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    // initialize implementListConfig with updated i18n resources
    implementListConfig = {
      name: {
        label: i18n.t('tasks.list.implementName'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      },
      length: {
        label: i18n.t('tasks.list.implementLength'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      },
      options: {
        label: i18n.t('tasks.list.options.options'),
        sort: false,
        filter: false,
        display: true,
        list: [
          {
            actionType: 'link',
            action: 'view',
            label: i18n.t('tasks.list.options.view')
          }
        ]
      }
    };

    this.state = {
      isAuthenticated: isAuthenticated,
      implementId: this.props.implementId,
      implementData: [],
      loader: true
    };
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      if (this.props.implementId) {
        this.getImplementsById(this.props.implementId).then(response => {
          const implement = [];
          implement.push(response.data);
          this.setState({
            implementData: implement,
            loader: false
          });
        }, error => {
          this.setState(
            {
              implementData: [],
              loader: false
            });
        });
      } else {
        this.setState(
          {
            implementData: [],
            loader: false
          });
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.implementId !== this.props.implementId && this.props.implementId) {
      this.getImplementsById(this.props.implementId).then(response => {
        const implement = [];
        implement.push(response.data);
        this.setState({
          implementData: implement,
          loader: false
        });
      }, error => {
        this.setState(
          {
            implementData: [],
            loader: false
          });
      });
    }
  }

  /**
   * get implement by id from backend.
   * @param {*} id 
   */
  getImplementsById (id) {
    return ImplementsService.getImplement(id)
      .then(response => {
        return response;
      }, errResponse => {
        return Promise.reject(errResponse);
      });
  }

  /**
   * routing to relevant page based on row option selection
   * @param {*} actionType 
   * @param {*} row 
   */
  actionPaths (actionType, row) {
    if (actionType.action === 'view') {
      return ({
        pathname: '/user-vehicles/implements/view-implement/' + row.id
      });
    }
  }

  /**
   * render view form
   */
  render () {
    return (
      <div>
        <Grid id="taskImplementListGrid"
          keyColumn="id"
          rowData={this.state.implementData}
          columnDefs={implementListConfig}
          loading={this.state.loader}
          pagination={false}
          actionPaths={this.actionPaths.bind(this)} />
      </div>
    );
  }
}

export default ListConcernedImplement;
