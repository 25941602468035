import _ from 'lodash';
import i18n from 'i18next';
import AppInfoHelper from './appInfoHelper';
import { APP_COMMON_CONSTANTS } from '../../constants/app.constants';

/**
 * Set object to local storage.
 * @param {string} storageKey
 * @param {object} objToSave
 */
function setTokenToLocalStorage (storageKey, objToSave) {
  try {
    localStorage.setItem(storageKey, JSON.stringify(objToSave));
  } catch (err) {
    console.log('error while persisting json object to local storage');
  }
}

/**
 * Fetch saved token from local storage at the given key
 * @param {string} storageKey
 * @return {object}
 */
function getTokenFromLocalStorage (storageKey) {
  let token;
  try {
    token = JSON.parse(localStorage.getItem(storageKey));
  } catch (err) {
    console.log('error while retrieving json token from local storage');
  }
  return token;
}

/**
 * Remove token from local storage at the given key
 * @param {string} storageKey
 */
function removeTokenFromLocalStorage (storageKey) {
  localStorage.removeItem(storageKey);
}

/**
 * Clear all data from session and local storage.
 */
function clearApplicationData () {
  AppInfoHelper.reInitializeUserInfo();
  localStorage.removeItem(APP_COMMON_CONSTANTS.SELECTED_ACC_HOLDER);
  localStorage.removeItem(APP_COMMON_CONSTANTS.AUTH_INFO);
  sessionStorage.removeItem(APP_COMMON_CONSTANTS.USER_ROLES);
}

/**
 * Manipulate roleDisplayName to be UI displayable. Then save the available user
 * roleTypes information in session storage.
 * @param {array} allroles
 */
function setUserTypesToSessionStorage (allroles) {
  // manipulate roleDisplayName to be UI displayable
  const rolesWithRoleDisplayName = allroles.map(function (item) {
    const roleDisplayName = item.roleDisplayName.toLowerCase();
    if (roleDisplayName.indexOf('admin') > -1) {
      item.roleDisplayName = i18n.t('common.userRoleTypes.label.admin');
    } else if (roleDisplayName.indexOf('account holder') > -1) {
      item.roleDisplayName = i18n.t('common.userRoleTypes.label.accHolder');
    } else if (roleDisplayName.indexOf('operator') > -1) {
      item.roleDisplayName = i18n.t('common.userRoleTypes.label.operator');
    }
    return item;
  });

  try {
    sessionStorage.setItem(APP_COMMON_CONSTANTS.USER_ROLES, JSON.stringify(rolesWithRoleDisplayName));
  } catch (err) {
    console.log('error while persisting json object to session storage');
  }
}

/**
 * Fetch user roleTypes information from session storage
 * @return {array}
 */
function getUserTypesFromSessionStorage () {
  let userRoles;
  try {
    userRoles = JSON.parse(sessionStorage.getItem(APP_COMMON_CONSTANTS.USER_ROLES));
  } catch (err) {
    console.log('error while retrieving json user roles from local storage');
  }
  return userRoles;
}

/**
 * For an operator user, set the currently selected account holder information in localstorage.
 * @param {object} accountHolder
 */
function setSelectedAccHolderToLocalStorage (accountHolder) {
  try {
    localStorage.setItem(APP_COMMON_CONSTANTS.SELECTED_ACC_HOLDER, JSON.stringify(accountHolder));
  } catch (err) {
    console.log('error while persisting json object to local storage');
  }
}

/**
 * For an operator user, get currently selected account holder information from localstorage.
 * @return {object|undefined}
 */
function getSelectedAccHolderFromLocalStorage () {
  let selectedAccHolder;
  try {
    selectedAccHolder = JSON.parse(localStorage.getItem(APP_COMMON_CONSTANTS.SELECTED_ACC_HOLDER));
  } catch (err) {
    console.log('error while retrieving selected accountHolder from local storage');
  }
  return selectedAccHolder;
}

/**
 * Fetch language preference saved in local storage.
 * @return {object}
 */
function getPreferredLangFromLocalStorage () {
  const lang = getTokenFromLocalStorage(APP_COMMON_CONSTANTS.PREFERRED_LANGUAGE);
  return _.isObject(lang) ? lang : {};
}

/**
 * Save language preference in local storage.
 * @param {object} langObj
 */
function setPreferredLangToLocalStorage (langObj) {
  setTokenToLocalStorage(APP_COMMON_CONSTANTS.PREFERRED_LANGUAGE, langObj);
}

export const AppStorageHelper = {
  clearApplicationData,
  setTokenToLocalStorage,
  getTokenFromLocalStorage,
  removeTokenFromLocalStorage,
  setUserTypesToSessionStorage,
  getUserTypesFromSessionStorage,
  setSelectedAccHolderToLocalStorage,
  getSelectedAccHolderFromLocalStorage,
  getPreferredLangFromLocalStorage,
  setPreferredLangToLocalStorage
};
