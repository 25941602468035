import React from 'react';
import _ from 'lodash';
import { Route, Switch, Redirect, Link } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import Vehicles from './vehicles/Vehicles';
import Implements from './implements/Implements';
import Devices from './devices/Devices';
import ListSubscriptionsCodes from './subscriptions-code/ListSubscriptionsCodes';
import i18n from 'i18next';

/**
 * user's associated vehicles
 */
class UserVehicles extends BaseScreen {
  constructor (props) {
    super(props);

    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    this.state = {
      isAuthenticated: isAuthenticated,
      isLoading: false,
      currentUrlPath: ''
    };
  }

  /**
   * on component load, add location listener & using currentUrlPath maintaining state for breadcrumb and active link class
   */
  componentDidMount () {
    const pathName = this.props.routerData.history.location.pathname;
    this.updateCurrentUrlpath(pathName);
    this.locationListener = this.props.routerData.history.listen((location, action) => {
      this.updateCurrentUrlpath(location.pathname);
    });
  }

  /**
   * update current url path
   * @param {*} pathName 
   */
  updateCurrentUrlpath (pathName) {
    if (_.isString(pathName)) {
      this.setState({
        currentUrlPath: pathName
      });
    }
  }

  /**
   * Breadcrumbs.
   */
  getBreadCrumbInfo () {
    const breadCrumb = [
      { id: 'home', displayName: i18n.t('userVehicles.breadcrumb.home'), href: '#/home', className: '', link: true }
    ];

    const currentPath = this.state.currentUrlPath;
    if (currentPath) {
      if (currentPath.indexOf('user-vehicles/vehicles') > -1) {
        /**
         * Vehicles
         */
        breadCrumb.push({ id: 'vehicle', displayName: i18n.t('userVehicles.vehicles.breadcrumb.vehicle'), className: '', link: false });
        if (currentPath.indexOf('view-vehicle') > -1) {
          breadCrumb.push({ id: 'detail', displayName: i18n.t('userVehicles.vehicles.breadcrumb.detail'), className: '', link: false });
        } else if (currentPath.indexOf('edit-vehicle') > -1) {
          breadCrumb.push({ id: 'edit', displayName: i18n.t('userVehicles.vehicles.breadcrumb.edit'), className: '', link: false });
        }
      } else if (currentPath.indexOf('user-vehicles/implements') > -1) {
        /**
         * Implements
         */
        breadCrumb.push({ id: 'implement', displayName: i18n.t('userVehicles.implements.breadcrumb.implement'), className: '', link: false });
        if (currentPath.indexOf('create-implement') > -1) {
          breadCrumb.push({ id: 'create', displayName: i18n.t('userVehicles.implements.breadcrumb.create'), className: '', link: false });
        } else if (currentPath.indexOf('view-implement') > -1) {
          breadCrumb.push({ id: 'detail', displayName: i18n.t('userVehicles.implements.breadcrumb.detail'), className: '', link: false });
        } else if (currentPath.indexOf('edit-implement') > -1) {
          breadCrumb.push({ id: 'edit', displayName: i18n.t('userVehicles.implements.breadcrumb.edit'), className: '', link: false });
        }
      } else if (currentPath.indexOf('user-vehicles/devices') > -1) {
        /**
         * Devices
         */
        breadCrumb.push({ id: 'devices', displayName: i18n.t('userVehicles.devices.breadcrumb.devices'), className: '', link: false });
        if (currentPath.indexOf('activate-device') > -1) {
          breadCrumb.push({ id: 'activate', displayName: i18n.t('userVehicles.devices.breadcrumb.activate'), className: '', link: false });
        }
      } else if (currentPath.indexOf('user-vehicles/subscriptions-code') > -1) {
        /**
         * subscriptions-code
         */
        breadCrumb.push({ id: 'codes', displayName: i18n.t('userVehicles.tabs.subscriptionCode'), className: '', link: false });
      }
    }
    return breadCrumb;
  }

  /**
   * un-register locationListener
   */
  componentWillUnmount () {
    this.locationListener();
  }

  /**
   * check if path is active
   * @param {*} path
   */
  isActivePath (path) {
    const currentPath = this.state.currentUrlPath;
    let isActive = false;
    if (currentPath && (currentPath.indexOf(path) > -1)) {
      isActive = true;
    }
    return isActive;
  }

  /**
   * react render function
   */
  render () {
    return (
      <div className="card my-4-custom">
        <div className="container-fluid p-4">
          <BreadcrumbCustom breadcrumb={this.getBreadCrumbInfo()} />
          <div className="clearfix"></div>
          <div className="tab-section">
            <Nav tabs>
              <NavItem>
                <Link data-testid="vehicles-tab-link" 
                  to="/user-vehicles/vehicles/list-vehicles"
                  className={this.isActivePath('/user-vehicles/vehicles') ? 'nav-tab-custom-active' : 'nav-tab-custom'}>
                  {i18n.t('userVehicles.tabs.vehicles')}
                </Link>
              </NavItem>
              <NavItem>
                <Link data-testid="implements-tab-link" 
                  to="/user-vehicles/implements/list-implements"
                  className={this.isActivePath('/user-vehicles/implements') ? 'nav-tab-custom-active' : 'nav-tab-custom'}>
                  {i18n.t('userVehicles.tabs.implements')}
                </Link>
              </NavItem>
              <NavItem>
                <Link data-testid="devices-tab-link"
                  to="/user-vehicles/devices/list-devices"
                  className={this.isActivePath('/user-vehicles/devices') ? 'nav-tab-custom-active' : 'nav-tab-custom'}>
                  {i18n.t('userVehicles.tabs.devices')}
                </Link>
              </NavItem>
               <NavItem>
                <Link data-testid="devices-tab-link"
                  to="/user-vehicles/subscriptions-code/codes"
                  className={this.isActivePath('/user-vehicles/subscriptions-code/codes') ? 'nav-tab-custom-active' : 'nav-tab-custom'}>
                  {i18n.t('userVehicles.tabs.subscriptionCode')}
                </Link>
              </NavItem>
            </Nav>
            <Switch>
              <Route exact path="/user-vehicles/vehicles/:requiredPathParam/:optionalPathParam?" component={Vehicles} />
              <Route exact path="/user-vehicles/implements/:requiredPathParam/:optionalPathParam?" component={Implements} />
              <Route exact path="/user-vehicles/devices/:requiredPathParam/:optionalPathParam?" component={Devices} />
              <Route exact path="/user-vehicles/subscriptions-code/:requiredPathParam/:optionalPathParam?" component={ListSubscriptionsCodes} />
              <Redirect to="/user-vehicles/vehicles/list-vehicles" />
            </Switch>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}

export default UserVehicles;
