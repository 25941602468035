import React from 'react';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import Grid from '../../../Component/grid/Grid';
import AlertMessage from '../../../Component/alert/AlertMessage';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { CropsService } from '../../../services/service.crop';
import AppInfoHelper from '../../../shared/utils/appInfoHelper';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import { ROLE_TYPES } from '../../../constants/app.constants';
import ModalDialog from '../../../Component/modaldialog/ModalDialog';
/**
 * Informations to be shown in crops listing grid, along with options per rows in support details page.
 */
let cropsListConfig = {};

/**
 * List crops component
 */
class ListCrops extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    cropsListConfig = {
      name: {
        label: i18n.t('crops.list.cropName'),
        sort: false,
        filter: true,
        display: true,
        filterMaxLength: 50
      },
      active: {
        label: i18n.t('crops.list.active'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 50
      },
      options: {
        label: i18n.t('crops.list.options.options'),
        sort: false,
        filter: false,
        display: true,
        list: [
          {
            actionType: 'link',
            action: 'view',
            label: i18n.t('crops.list.options.view')
          },
          {
            actionType: 'link',
            action: 'edit',
            label: i18n.t('crops.list.options.edit')
          },
          {
            actionType: '',
            action: 'delete',
            label: i18n.t('crops.list.options.delete')
          }
        ]
      }
    };

    this.state = {
      isAuthenticated: isAuthenticated,
      loggedInUserInfo: undefined,
      selectedAccountHolder: undefined,
      cropsData: [],
      loader: true,
      pgnConfig: {
        currentPage: 1,
        numberOfPageLinks: 0,
        countPerPage: 25,
        totalCount: 0,
        pageList: [25, 50, 100]
      },
      alert: {
        type: null,
        message: null
      },
      modal: {
        modalShow: false,
        shouldRenderModal: false,
        modalTitle: '',
        modalMsg: '',
        modalData: '',
        modalAction: ''
      },
      sortFieldsConfig: {},
      filterFieldsConfig: {},
      reinitialize: false
    };
  }

  /**
   * check userRole first and then communicate backend to get cropss
   */
  componentDidMount () {
    const self = this;
    const userInfoInstance = AppInfoHelper.getUserInstance();
    userInfoInstance.getUserInfo().then(function (response) {
      const userInfo = response;
      const selectedAccountHolder = userInfoInstance.getSelectedAccountHolderForOperator();
      if (userInfo.roleId === ROLE_TYPES.OPERATOR.ID && !selectedAccountHolder) {
        const warningMsg = i18n.t('crops.alert.selectAccountholder');
        self.setState(
          {
            cropsData: [],
            loader: false,
            loggedInUserInfo: response,
            reinitialize: false,
            modalShow: false,
            alert: {
              type: 'danger',
              message: warningMsg
            }
          });
      } else {
        self.setState(
          {
            loader: true,
            loggedInUserInfo: response,
            selectedAccountHolder: selectedAccountHolder
          });
        const accountHolderId = response.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID ? userInfo.id : selectedAccountHolder.id;
        const arg = {
          currentPage: 0,
          pageSize: self.state.pgnConfig.countPerPage,
          accountHolderId: accountHolderId
        };
        self.getCropsData(arg, self.state.sortFieldsConfig, self.state.filterFieldsConfig, accountHolderId)
          .then(response => {
            const pgnConfig = self.setPaginationConfig(self.state.pgnConfig.currentPage, self.state.pgnConfig.countPerPage, response.data.total);
            const cropsData = self.prepareDataToShowActive(response.data.items);
            self.setState({ cropsData: cropsData, loader: false, pgnConfig: pgnConfig, modalShow: false, reinitialize: false });
          }, error => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
              errorMsg = error.data.message;
            }
            self.setState(
              {
                cropsData: [],
                loader: false,
                reinitialize: false,
                modalShow: false,
                alert: {
                  type: 'danger',
                  message: errorMsg
                }
              });
          });
      }
    }, function (error) {});
  }

  /**
   * get crops data
   * @param {*} requiredParams
   * @param {*} sortFieldsObj
   * @param {*} filterFieldsObj
   * @param {*} accountHolderId
   */
  getCropsData (requiredParams, sortFieldsObj, filterFieldsObj, accountHolderId) {
    let accountHolder = '';
    if (accountHolderId && accountHolderId !== '') {
      accountHolder = accountHolderId;
    } else if (this.state.loggedInUserInfo && this.state.loggedInUserInfo.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID) {
      accountHolder = this.state.loggedInUserInfo.id;
    } else if (this.state.selectedAccountHolder && this.state.selectedAccountHolder.id) {
      accountHolder = this.state.selectedAccountHolder.id;
    }
    let reqQueryParams = '';
    let params;
    if (requiredParams) {
      params = {
        currentPage: requiredParams.currentPage,
        pageSize: requiredParams.pageSize
      };
    } else {
      params = {
        currentPage: this.state.pgnConfig.currentPage - 1,
        pageSize: this.state.pgnConfig.countPerPage
      };
    }
    reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' + params.pageSize;
    let sortFiledsQueryParams = '';
    if (!sortFieldsObj) {
      sortFieldsObj = this.state.sortFieldsConfig;
    }
    let sortQueryTemp = '';
    let key;
    for (key in sortFieldsObj) {
      const sortValue = sortFieldsObj[key].sortOrder;
      if (sortValue) {
        sortQueryTemp = 'sortfield=' + key + '&orderType=' + ((sortValue === 1) ? 'asc' : 'desc');
        sortFiledsQueryParams += '&' + sortQueryTemp;
      }
    }
    let filterFielddQueryParams = '';
    if (!filterFieldsObj) {
      filterFieldsObj = this.state.filterFieldsConfig;
    }

    let filterQueryTemp = '';
    for (key in filterFieldsObj) {
      const filterValue = filterFieldsObj[key].value;
      if (filterValue) {
        filterQueryTemp = key + '=' + filterValue;
        filterFielddQueryParams += '&' + filterQueryTemp;
      }
    }

    reqQueryParams += sortFiledsQueryParams + filterFielddQueryParams;

    if (accountHolder) {
      reqQueryParams += '&accountId=' + accountHolder;
    }

    return CropsService.getCrops(reqQueryParams).then(response => {
      return response;
    }, errResponse => {
      return Promise.reject(errResponse);
    });
  }

  /**
   * converting boolean active to string active to render,
   * @param {*} data 
   */
  prepareDataToShowActive (data) {
    let index = 0;
    for (index = 0; index < data.length; index++) {
      if (data[index].active) {
        data[index].active = i18n.t('common.booleanTypes.true');
      } else {
        data[index].active = i18n.t('common.booleanTypes.false');
      }
    }
    return data;
  }

  /**
   * helper function for pagination configuration
   * @param {*} currentPage
   * @param {*} countPerPage
   * @param {*} totalCount
   */
  setPaginationConfig (currentPage, countPerPage, totalCount) {
    return {
      currentPage: currentPage,
      numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
      countPerPage: countPerPage,
      totalCount: totalCount,
      pageList: [...this.state.pgnConfig.pageList]
    };
  }

  /**
   * handler when page number change, like user move to next page or previous page from current page he is viewing
   * @param {*} pageNumber
   */
  onPgnChange (pageNumber) {
    if (pageNumber !== this.state.pgnConfig.currentPage) {
      window.scrollTo(0, 0);
      this.setState({ loader: true, cropsData: [] });

      const arg = {
        currentPage: (pageNumber - 1),
        pageSize: this.state.pgnConfig.countPerPage
      };

      this.getCropsData(arg).then((response) => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        const cropsData = this.prepareDataToShowActive(response.data.items);
        this.setState({
          cropsData: cropsData,
          loader: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          },
          modalShow: false
        });
      }, (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            cropsData: [],
            loader: false,
            alert: {
              type: 'danger',
              message: errorMsg
            },
            modalShow: false
          });
      });
    }
  }

  /**
   * handles the grid listing when there is any change per page row count, like if user chose default row count 25 to 50 or something else.
   * @param {*} event
   */
  onPgnRowsCountChange (event) {
    window.scrollTo(0, 0);
    const perPageItems = Number(event.target.value);
    let currentPage;
    if ((this.state.pgnConfig.currentPage - 1) * perPageItems >= this.state.pgnConfig.totalCount) {
      currentPage = 1;
    } else {
      currentPage = this.state.pgnConfig.currentPage;
    }
    this.setState({ loader: true, cropsData: [] });
    const arg = {
      currentPage: currentPage - 1,
      pageSize: perPageItems
    };
    this.getCropsData(arg).then((response) => {
      const pgnConfig = this.setPaginationConfig(currentPage, perPageItems, response.data.total);
      const cropsData = this.prepareDataToShowActive(response.data.items);
      this.setState({
        cropsData: cropsData,
        loader: false,
        pgnConfig: pgnConfig,
        alert: {
          type: null,
          message: null
        },
        modalShow: false
      });
    }, (error) => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }
      this.setState(
        {
          cropsData: [],
          loader: false,
          alert: {
            type: 'danger',
            message: errorMsg
          },
          modalShow: false
        });
    });
  }

  /**
   * lazy load function when user use filter or sort functionality from grid header
   * @param {*} sortFieldsObj
   * @param {*} filterFieldsObj
   */
  onLazyLoad (sortFieldsObj, filterFieldsObj) {
    this.setState({ loader: true, cropsData: [] });
    const pageNumber = 1;
    const arg = {
      currentPage: pageNumber - 1,
      pageSize: this.state.pgnConfig.countPerPage
    };
    this.getCropsData(arg, sortFieldsObj, filterFieldsObj)
      .then((response) => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        const cropsData = this.prepareDataToShowActive(response.data.items);
        // sortingData
        if (!sortFieldsObj) {
          sortFieldsObj = this.state.sortFieldsConfig;
        }

        // filteringData
        if (!filterFieldsObj) {
          filterFieldsObj = this.state.filterFieldsConfig;
        }

        this.setState({
          cropsData: cropsData,
          loader: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          },
          modalShow: false,
          sortFieldsConfig: sortFieldsObj,
          filterFieldsConfig: filterFieldsObj
        });
      }, (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            cropsData: [],
            loader: false,
            alert: {
              type: 'danger',
              message: errorMsg
            },
            modalShow: false
          });
      });
  }

  /**
   * routing to relevant page based on row option selection
   * @param {*} actionType
   * @param {*} row
   */
  actionPaths (actionType, row) {
    if (actionType.action === 'view') {
      return ({
        pathname: '/crops/view-crops/' + row.id
      });
    } else if (actionType.action === 'edit') {
      return ({
        pathname: '/crops/edit-crops/' + row.id
      });
    }
  }

  optionHandler (actionObject, row) {
    let modalInfo;
    if (actionObject.action === 'delete') {
      modalInfo = {
        modalMsg: i18n.t('crops.form.modal.deleteMsg', { name: row.name }),
        modalTitle: i18n.t('crops.form.modal.deleteTitle'),
        modalAction: 'delete_crop'
      };
    }
    this.modalPopup(true, row, modalInfo);
  }

  /**
   * Modal window to show each row options
   * @param {*} state
   * @param {*} rowData
   * @param {*} modalInfo
   */
  modalPopup (state, rowData, modalInfo) {
    if (state) {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalMsg: modalInfo.modalMsg,
          modalTitle: modalInfo.modalTitle,
          modalData: rowData || '',
          modalAction: modalInfo.modalAction
        }
      });
    } else {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalData: rowData || '',
          modalAction: this.state.modal.modalAction
        }
      });
    }
  }

  /**
   * modal dismissal action
   */
  handleModalDismiss () {
    this.modalPopup(false);
  }

  /**
   *  handle modal delete action.
   * @param {*} data
   */
  onModalAction (data) {
    const modalAction = this.state.modal.modalAction;
    if (modalAction === 'delete_crop') {
      this.modalPopup(false, data);
    }
  }

  /**
   * function after modal is closed, re-render listing page after delete operation.
   */
  onModalClosed () {
    if (this.state.modal.modalAction === 'delete_crop') {
      this.deleteCrop();
    }
  }

  deleteCrop () {
    if (this.state.modal.modalData) {
      const modalData = this.state.modal.modalData;
      this.setState({
        loader: true,
        modal: {
          modalShow: false,
          shouldRenderModal: false
        }
      });
      CropsService.deleteCrop(modalData.id)
        .then((successResponse) => {
          let pageNumber = this.state.pgnConfig.currentPage;
          if ((this.state.cropsData.length <= 1) && (this.state.pgnConfig.currentPage > 1)) {
            pageNumber = this.state.pgnConfig.currentPage - 1;
          }
          this.setState({
            cropsData: [],
            alert: {
              type: 'success',
              message: i18n.t('crops.alert.cropDelete')
            }
          });
          const arg = {
            currentPage: (pageNumber - 1),
            pageSize: this.state.pgnConfig.countPerPage
          };
          this.getCropsData(arg).then((response) => {
            const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
            const cropsData = this.prepareDataToShowActive(response.data.items);
            this.setState({
              cropsData: cropsData,
              loader: false,
              pgnConfig: pgnConfig,
              modal: {
                modalShow: false,
                shouldRenderModal: false
              }
            });
          }, (error) => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
              errorMsg = error.data.message;
            }
            this.setState(
              {
                cropsData: [],
                loader: false,
                alert: {
                  type: 'danger',
                  message: errorMsg
                }
              });
          });
        }, (error) => {
          let errorMsg = i18n.t('common.genericApiError');
          if (error && error.data && error.data.message) {
            errorMsg = error.data.message;
          }
          this.setState(
            {
              loader: false,
              alert: {
                type: 'danger',
                message: errorMsg
              }
            });
        });
    }
  }

  /**
   * alert message handling
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render function for redering a listing of cropss
   */
  render () {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('crops.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'crops', displayName: i18n.t('crops.breadcrumb.crops'), className: '', link: false }
    ];

    let isSelectedAccountHolder = false;
    let accountHolder = '';
    if (this.state.selectedAccountHolder) {
      isSelectedAccountHolder = true;
      accountHolder = this.state.selectedAccountHolder;
    } else if (this.state.loggedInUserInfo && this.state.loggedInUserInfo.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID) {
      isSelectedAccountHolder = true;
      accountHolder = this.state.loggedInUserInfo;
    }

    return (
      <div className="container-fluid p-4">
        <BreadcrumbCustom breadcrumb={breadcrumb} />
        <div className="clearfix"></div>
        {
          this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)}
            />
        }
        <div className="row">
          <div className="col-md-12 text-right f-btn">
            {
              (
                isSelectedAccountHolder
                  ? <Link className="btn btn-primary" to={
                    {
                      pathname: '/crops/create-crops',
                      params: { accountHolder: [accountHolder] }
                    }} data-testid="create-crop-link">
                    {i18n.t('crops.form.button.create')}
                  </Link>
                  : <button className={'btn mr-2 btn-primary'} disabled>
                    {i18n.t('crops.form.button.create')}
                  </button>
              )
            }
          </div>
        </div>
        <div>
          <Grid id="cropsListGrid"
            optionHandler={this.optionHandler.bind(this)}
            keyColumn="id"
            rowData={this.state.cropsData}
            reinitialize={this.state.reinitialize}
            columnDefs={cropsListConfig}
            loading={this.state.loader}
            pagination={true}
            pgnConfig={this.state.pgnConfig}
            onPgnChange={this.onPgnChange.bind(this)}
            onPgnRowsCountChange={this.onPgnRowsCountChange.bind(this)}
            pageList={this.state.pageList}
            lazyLoading={true}
            onLazyLoad={this.onLazyLoad.bind(this)}
            actionPaths={this.actionPaths.bind(this)} />
        </div>
        {
          this.state.modal.shouldRenderModal &&
          <ModalDialog modalTitle={this.state.modal.modalTitle}
            modalMessage={this.state.modal.modalMsg}
            modalData={this.state.modal.modalData}
            isModalOpen={this.state.modal.modalShow}
            modalAction={this.onModalAction.bind(this)}
            handleModalDismiss={this.handleModalDismiss.bind(this)}
            onModalClosed={this.onModalClosed.bind(this)}
          />
        }
      </div>
    );
  }
}

export default ListCrops;
