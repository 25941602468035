import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { UserService } from '../../../services/service.users';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import AlertMessage from '../../../Component/alert/AlertMessage';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import i18n from 'i18next';

/**
 * view profile component
 */
class ViewProfile extends BaseScreen {
  constructor (props) {
    super(props);

    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }
    this.state = {
      isAuthenticated: isAuthenticated,
      isLoading: true,
      userData: '',
      availableRoles: [],
      isErrorOnLoad: false,
      apiResponseMsg: '',
      alert: {
        type: null,
        message: null
      }
    };
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      UserService.getOemRoles().then(
        (response) => {
          this.setState({
            availableRoles: _.isArray(response) ? response : []
          });
          this.displayUserInfo();
        },
        (error) => {
          this.setState({
            availableRoles: []
          });
          this.displayUserInfo();
        });
    }
  }

  /**
   * function to fetch user information from backend
   */
  displayUserInfo () {
    const userId = this.props.match.params.userId;
    UserService.getUser(userId).then(
      (response) => {
        this.setState({
          userData: response.data,
          isLoading: false
        });
      },
      (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState({
          userData: {},
          isLoading: false,
          isErrorOnLoad: true,
          apiResponseMsg: errorMsg,
          alert: {
            type: 'danger',
            message: errorMsg
          }
        });
      });
  }

  /**
   * user role to display
   * @param {*} roleId 
   */
  getUserRoleType (roleId) {
    const availableRoles = this.state.availableRoles;
    const userRole = availableRoles.find(role => { return role.roleId === roleId; });
    return (userRole ? userRole.roleDisplayName : '');
  }

  /**
   * go to previous page
   */
  handleBack () {
    this.props.history.goBack();
  }

  /*
    alert message handling
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * render user profile display
   */
  renderUserProfile () {
    return (
      <div className="row">
        <div className="col-md-12">
          <h2 data-testid="view-profile-heading">
            {i18n.t('home.viewProfile')}
          </h2>

          <div className="view-profile-details">
            <div className="row">
              <div className="col-md-6">
                <label className="emphasized-label" data-testid="view-profile-label-fname">
                  {i18n.t('home.form.label.firstName')}
                </label>
                <p>{this.state.userData.firstName}</p>
              </div>

              <div className="col-md-6">
                <label className="emphasized-label">{i18n.t('home.form.label.role')}</label>
                <p>{this.getUserRoleType(this.state.userData.roleId)}</p>
              </div>

              <div className="col-md-6">
                <label className="emphasized-label">{i18n.t('home.form.label.lastName')}</label>
                <p>{this.state.userData.lastName}</p>
              </div>

              <div className="col-md-6">
                <label className="emphasized-label">{i18n.t('home.form.label.phone')}</label>
                <p>{this.state.userData.phone}</p>
              </div>

              <div className="col-md-6">
                <label className="emphasized-label">{i18n.t('home.form.label.email')} </label>
                <p>{this.state.userData.email}</p>
              </div>
            </div>
          </div>
          <div className="col-md-12 text-right f-btn">
            <button type="button" className="btn btn-secondary mr-2" 
              onClick={this.handleBack.bind(this)} data-testid="view-profile-back-btn">
                {i18n.t('home.form.button.back')}
            </button>
            <Link className="btn btn-primary mr-2" to={{
              pathname: '/home/edit-profile/' + this.state.userData.id,
              state: { data: this.state.userData }
            }} data-testid="view-profile-edit-link">
              {i18n.t('home.form.button.edit')}
            </Link>
          </div>

        </div>
      </div>
    );
  }

  /**
   * render error
   */
  renderError () {
    return (
      <div className="col-md-12 text-right f-btn">
        <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>
          {i18n.t('home.form.button.back')}
        </button>
      </div>
    );
  }

  /**
   * react render function to render user profile information
   */
  render () {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('home.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'profile', displayName: i18n.t('home.breadcrumb.profile'), className: '', link: false },
      { id: 'view', displayName: i18n.t('home.breadcrumb.view'), className: '', link: false }
    ];

    return (
      <div className="container-fluid p-4">
        <BreadcrumbCustom breadcrumb={breadcrumb} />
        <div className="clearfix"></div>
        {
          this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                      type={this.state.alert.type}
                      isAlertOpen={!!(this.state.alert.message)}
                      handleDismiss={this.handleAlertDismiss.bind(this)}
                    />
        }

        <LoadingOverlay active={this.state.isLoading}>
          {
            this.state.isErrorOnLoad ? this.renderError() : this.renderUserProfile()
          }
        </LoadingOverlay>
      </div>
    );
  }
}

export default ViewProfile;
