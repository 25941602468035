import React from 'react';
import { connect } from 'react-redux';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { UserService } from '../../../services/service.users';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import { FormErrors } from '../../../Component/SubmissionStatus';
import { CommonUtilities } from '../../../shared/utils/commonUtilities';
import { StyledText } from '../../../Component/StyledText';
import { updateUserInfo } from '../../../redux/actions/action.userinfo';
import AlertMessage from '../../../Component/alert/AlertMessage';
import LoadingOverlay from "../../../Component/loader/LoadingOverlay";
import ChangeEmailModal from "./ChangeEmailModal";
import i18n from 'i18next';

/**
 * Edit user profile component
 */
class EditProfile extends BaseScreen {
    constructor(props) {
        super(props);
        if (!this.isAuth()) {
            this.goToScreen(SCREENS.login);
        }
        this.state = {
            formfields: {
                firstName: '',
                lastName: '',
                roleId: '',
                phone: '',
                email: '',
            },
            availableRoles: [],
            formErrors: {
                firstName: '',
                lastName: '',
                email: '',
                phone: ''
            },
            formValid: false,
            isLoading: true,
            // to check if initial implement info available or not
            isErrorOnLoad: false,
            // for alert messages
            alert: {
                type: null,
                message: null
            },
            modal: {
                modalShow: false,
                shouldRenderModal: false,
                modalTitle: '',
            },
        };
    }

    componentDidMount() {
        UserService.getOemRoles().then(
            (response) => {
                this.setState({
                    availableRoles: response,
                });
                this.displayUserInfo();
            },
            (error) => {
                this.setState({
                    availableRoles: [],
                });
                this.displayUserInfo();
            });
    }

    /**
     * function to fetch user information from backend
     */
    displayUserInfo() {
        let userId = this.props.match.params.userId;
        UserService.getUser(userId).then(
            (response) => {
                this.setState({
                    formfields: response.data,
                    isLoading: false
                });
            },
            (error) => {
                let errorMsg = i18n.t('common.genericApiError');
                if (error && error.data && error.data.message) {
                    errorMsg = error.data.message;
                }
                this.setState({
                    formfields: {},
                    isLoading: false,
                    isErrorOnLoad: true,
                    alert: {
                        type: 'danger',
                        message: errorMsg
                    },
                });
            });
    }

    /**
     * user role to display
     * @param {*} roleId 
     */
    getUserRoleType(roleId) {
        let availableRoles = this.state.availableRoles;
        let userRole = availableRoles.find(role => { return role.roleId === roleId });
        return userRole.roleDisplayName;
    }

    /**
     * go back to previous page
     */
    handleCancel() {
        this.props.history.goBack();
    }

    /**
     * update user information in backend with updated data
     */
    updateUserSubmit = (event) => {
        event.preventDefault();

        // set laoder on
        this.setState({
            isLoading: true,
        });
        UserService.updateUserSubmission(this.state.formfields).then(
            (theResponse) => {
                // update user information in redux store
                this.props.updateUserInfo(this.state.formfields);
                this.setState({
                    isLoading: false,
                    alert: {
                        type: 'success',
                        message: i18n.t('home.alert.userDetailUpdateSuccess')
                    }
                });
            },
            (error) => {
                let message = i18n.t('common.genericApiError');
                if (error && error.data && error.data.message) {
                    message = error.data.message;
                }
                this.setState({
                    isLoading: false,
                    alert: {
                        type: 'danger',
                        message: message
                    }
                });
            }
        );
    }

    /**
     * handle user input for validation purpose.
     */
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        let formfields = this.state.formfields;
        formfields[name] = value;

        let fieldValidationErrors = this.state.formErrors;
        let msg = '';

        switch (name) {
            case 'firstName':
            case 'lastName':
                if (value.length === 0) {
                    msg = i18n.t('home.alert.required');
                } else if (value.length > 32) {
                    msg = i18n.t('home.alert.char32');
                } else {
                    let nameValid = CommonUtilities.isValidName(value);;
                    msg = nameValid ? '' : i18n.t('home.alert.alphabate');
                }

                break;

            case 'phone':
                if (value.length === 0) {
                    msg = i18n.t('home.alert.required');
                } else if (value.length < 7 || value.length > 15) {
                    msg = i18n.t('home.alert.phone');
                } else {
                    let phoneValid = value.match(/^(?:[0-9] ?){6,14}[0-9]$/i);
                    msg = phoneValid ? '' : i18n.t('home.alert.integer');
                }

                break;

            case 'email':
                if (value.length === 0) {
                    msg = i18n.t('home.alert.required');
                } else if (value.length > 320) {
                    msg = i18n.t('home.alert.email320');
                } else {
                    let emailValid = CommonUtilities.isValidEmail(value);
                    msg = emailValid ? '' : i18n.t('home.alert.emailInvalid');
                }

                break;

            default:
                break;
        }

        fieldValidationErrors[name] = msg;

        let isFormValid = (formfields.firstName && formfields.lastName && formfields.phone && formfields.email) &&
            (fieldValidationErrors.firstName === "" && fieldValidationErrors.lastName === "" &&
                fieldValidationErrors.phone === "" && fieldValidationErrors.email === "");

        this.setState({
            formfields: formfields,
            formErrors: fieldValidationErrors,
            formValid: isFormValid,
        });
    }

    /*
     alert message handling
    */
    handleAlertDismiss() {
        this.setState({
            alert: {
                type: null,
                message: null
            }
        });
    }

    /**
     * handler to handle change email functionality by openning a modal window
     */
    handleChangeEmail() {
        let modalInfo = {
            modalTitle: i18n.t('home.form.modal.changeEmailTitle'),
        };
        this.modalPopup(true, modalInfo);
    }

    /**
    * opens modal window to confirm change email
    * @param {*} state 
    * @param {*} modalInfo 
    */
    modalPopup(state, modalInfo) {
        if (state) {
            this.setState({
                modal: {
                    modalShow: state,
                    shouldRenderModal: true,
                    modalTitle: modalInfo.modalTitle,
                }
            });
        } else {
            this.setState({
                modal: {
                    modalShow: state,
                    shouldRenderModal: true,
                }
            });
        }
    }

    handleModalDismiss() {
        this.modalPopup(false);
    }

    /**
     * handles modal closing
     */
    onModalClosed() {
        this.setState({
            modal: {
                shouldRenderModal: false,
            }
        });
    }

    /**
     * user profile edit form
     */
    renderUserProfile() {
        return (
            <LoadingOverlay active={this.state.isLoading}>
                <form className="userProfileForm">
                    <h2 data-testid="edit-profile-heading">
                        {i18n.t('home.editProfile')}
                    </h2>
                    <div className="view-profile-details">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="firstName" data-testid="edit-profile-label-fname">
                                        {i18n.t('home.form.label.firstName')} <StyledText uiText="*" />
                                    </label>
                                    <input type="text" className="form-control" name="firstName"
                                        value={this.state.formfields.firstName} onChange={this.handleUserInput}
                                        data-testid="edit-profile-input-fname" />
                                    <FormErrors formErrors={this.state.formErrors.firstName} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="roleId"> {i18n.t('home.form.label.role')} :</label>
                                    <select className="form-control" id="roleId"
                                        name="roleId"
                                        disabled
                                        data-testid="edit-profile-select-role">
                                        {
                                            this.state.availableRoles.map((elem, id) => {
                                                if (elem.roleId === this.state.formfields.roleId) {
                                                    return <option key={elem.roleDisplayName + id} value={elem.roleId}>{elem.roleDisplayName}</option>
                                                } else {
                                                    return "";
                                                }
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="lastName"> {i18n.t('home.form.label.lastName')} <StyledText uiText="*" /></label>
                                    <input type="text" className="form-control" name="lastName"
                                        value={this.state.formfields.lastName} onChange={this.handleUserInput}
                                        data-testid="edit-profile-input-lname" />
                                    <FormErrors formErrors={this.state.formErrors.lastName} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="phone"> {i18n.t('home.form.label.phone')} <StyledText uiText="*" /></label>
                                    <input type="text" className="form-control" name="phone"
                                        value={this.state.formfields.phone} onChange={this.handleUserInput}
                                        data-testid="edit-profile-input-phone" />
                                    <FormErrors formErrors={this.state.formErrors.phone} />
                                </div>
                            </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="email"> {i18n.t('home.form.label.email')}  <StyledText uiText="*" /></label>
                                    <input type="email" className="form-control" name="email"
                                        value={this.state.formfields.email} onChange={this.handleUserInput}
                                        disabled
                                        data-testid="edit-profile-input-email" />
                                    <FormErrors formErrors={this.state.formErrors.email} />

                                </div>
                            </div>
                            <div className="col-md-6">
                                <button type="button" className="btn btn-primary margin-t-30"
                                    onClick={this.handleChangeEmail.bind(this)} 
                                    data-testid="edit-profile-change-email-btn" >
                                    {i18n.t('home.form.button.ChangeEmail')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 text-right f-btn">
                        <button type="button" className="btn btn-secondary mr-2" 
                            onClick={this.handleCancel.bind(this)} 
                            data-testid="edit-profile-back-btn" >
                            {i18n.t('home.form.button.back')}
                        </button>
                        <button type="button" className="btn btn-primary mr-2" 
                            disabled={!this.state.formValid} 
                            onClick={this.updateUserSubmit} 
                            data-testid="edit-profile-save-btn" >
                            {i18n.t('home.form.button.save')}
                        </button>
                    </div>
                </form>
            </LoadingOverlay>
        );
    }

    /**
     * render error.
     */
    renderError() {
        return (
            <div className="col-md-12 text-right f-btn">
                <button type="button" className="btn btn-secondary mr-2" onClick={this.handleCancel.bind(this)}>{i18n.t('home.form.button.back')}</button>
            </div>
        );
    }


    /**
     * react render function which renders user profile edit form
     */
    render() {
        let breadcrumb = [
            { id: 'home', displayName: i18n.t('home.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'profile', displayName: i18n.t('home.breadcrumb.profile'), className: '', link: false },
            { id: 'edit', displayName: i18n.t('home.breadcrumb.edit'), className: '', link: false }
        ];

        return (
            <div>
                <div className="container-fluid p-4">
                    <BreadcrumbCustom breadcrumb={breadcrumb} />
                    <div className="clearfix"></div>
                    {
                        this.state.alert.message &&
                        <AlertMessage message={this.state.alert.message}
                            type={this.state.alert.type}
                            isAlertOpen={!!(this.state.alert.message)}
                            handleDismiss={this.handleAlertDismiss.bind(this)} 
                            testId="edit-profile"
                        />
                    }
                    {
                        this.state.isErrorOnLoad ? this.renderError() : this.renderUserProfile()
                    }
                </div>
                {
                    this.state.modal.shouldRenderModal &&
                    (
                        <ChangeEmailModal
                            modalTitle={this.state.modal.modalTitle}
                            isModalOpen={this.state.modal.modalShow}
                            handleModalDismiss={this.handleModalDismiss.bind(this)}
                            onModalClosed={this.onModalClosed.bind(this)} />
                    )
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    updateUserInfo,
};

export default connect(null, mapDispatchToProps)(EditProfile);
