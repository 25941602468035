import React, { Component } from "react";
import GridRow from "./grid-row/GridRow";
import { CommonUtilities } from "../../shared/utils/commonUtilities";
import GridPagination from "./grid-pagination/GridPagination";
import "./css/grid.scss";
import LoadingOverlay from "../loader/LoadingOverlay";

/**
 * Component to display data in tabular format. Provides user interactions like 
 * sorting, filtering, pagination and row options.
 */
class Grid extends Component {
    /**
     * Component initialization
     * @param {object} props
     */
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        let filterFlags = {};
        let sortFieldData = {};
        let key;
        for (key in this.props.columnDefs) {
            // initialize filter flags
            filterFlags[key] = {
                input: false,
                filterIcon: false,
                value: ""
            }

            // initialize sort fields
            sortFieldData[key] = {
                sortOrder: "",
            }
        }

        // if any default sort field is provided
        if (this.props.defaultSortField) {
            sortFieldData[this.props.defaultSortField] = {
                sortOrder: this.props.defaultSortOrder || 1,
            }
        }

        this.state = {
            sortingData: sortFieldData,
            filterFlags: filterFlags,
        }
    }

    /**
     * React component life cycle method for component update. Compare previous and current props to 
     * determine if sort and filter configuration needs to be reset.
     * @param {object} prevProps
     * @param {object} prevState
     */
    componentDidUpdate(prevProps, prevState) {
        // if parent component needs sorting/filtering to be reset
        if ((this.props.reinitialize !== prevProps.reinitialize) &&
            (this.props.reinitialize)) {
            let filterFlags = {};
            let sortFieldData = {};
            let key;
            for (key in this.props.columnDefs) {
                // initialize filter flags
                filterFlags[key] = {
                    input: false,
                    filterIcon: false,
                    value: ""
                }

                // initialize sort fields
                sortFieldData[key] = {
                    sortOrder: "",
                }
            }

            // if any default sort field is provided
            if (this.props.defaultSortField) {
                sortFieldData[this.props.defaultSortField] = {
                    sortOrder: this.props.defaultSortOrder || 1,
                }
            }

            this.setState({
                sortingData: sortFieldData,
                filterFlags: filterFlags
            });
        }
    }

    /**
     * React component life cycle method - add click handler on window
     */
    componentDidMount() {
        window.addEventListener("click", this.handleClick);
    }

    /**
     * React component life cycle method - Remove click handler on window
     */
    componentWillUnmount() {
        window.removeEventListener("click", this.handleClick);
    }

    /**
     * Hide filter inputs when click event occurs on screen
     */
    handleClick() {
        let filterFlags = this.getUpdatedFilterFlags();
        this.setState({ filterFlags: filterFlags });
    }

    /**
     * Update filter flag of grid column to show filter icon
     * @param {string} field
     * @return {object}
     */
    getUpdatedFilterFlags(field) {
        let filterFlags = Object.assign({}, this.state.filterFlags);
        let key;
        for (key in filterFlags) {
            if (key === field) {
                filterFlags[key]["input"] = true;
            } else {
                filterFlags[key]["input"] = false;
            }
        }
        return filterFlags;
    }

    /**
     * Determine the sorting class to apply on grid column, when sorting is performed
     * @param {string} field
     * @return {string}
     */
    setSortingClass(field) {
        let sortingClass;
        if (this.state.sortingData[field] && this.state.sortingData[field]["sortOrder"] === 1) {
            sortingClass = "fa fa fa-sort-asc";
        } else if (this.state.sortingData[field] && this.state.sortingData[field]["sortOrder"] === -1) {
            sortingClass = "fa fa fa-sort-desc";
        } else {
            sortingClass = "fa fa fa-sort";
        }

        return sortingClass;
    }

    /**
     * Toggle sort order on grid column 
     * @param {string} field
     */
    updateSortingObject(field) {
        // sorting on a single field is supported
        let sortFields = Object.assign({}, this.state.sortingData);

        let key;
        for (key in sortFields) {
            if (key === field) {
                if (!sortFields[key]["sortOrder"] ||
                    (sortFields[key]["sortOrder"] && sortFields[key]["sortOrder"] === -1)) {
                    sortFields[key]["sortOrder"] = 1;
                } else {
                    sortFields[key]["sortOrder"] = -1;
                }
            } else {
                sortFields[key]["sortOrder"] = "";
            }
        }

        this.props.onLazyLoad(sortFields);
        this.setState({ sortingData: sortFields });
    }

    /**
     * Show filter input on column header click 
     * @param {string} field
     * @param {boolean} show
     * @param {string} fieldId
     */
    showFilter(field, show, fieldId) {
        if (show) {
            let fieldDomObj = document.getElementById(fieldId);
            let filterFlags = this.getUpdatedFilterFlags(field);

            this.setState({
                filterFlags: filterFlags
            }, () => {
                fieldDomObj.focus();
            });
        }
    }

    /**
     * Set css class to show/hide filter input field in grid column header 
     * @param {string} field
     * @return {string}
     */
    setFilterClass(field) {
        let filterClass;
        if (this.state.filterFlags[field]["input"]) {
            filterClass = "enable";
        } else {
            filterClass = "";
        }
        return filterClass;
    }

    /**
     * On click of close filter icon in grid column header, clear 
     * the filter configuration for the field
     * @param {string} field
     */
    clearFilter(field) {
        let filterFlags = Object.assign(this.state.filterFlags);
        filterFlags[field]["value"] = "";
        filterFlags[field]["input"] = false;
        filterFlags[field]["filterIcon"] = false;
        document.getElementById(`${this.props.id}-grid-col-filter-input-${field}`).value = "";

        // call server api to refresh data
        this.props.onLazyLoad(undefined, filterFlags);
        this.setState({ filterFlags: filterFlags });
    }

    /**
     * Handle click event on child gridRow elements of this component, to 
     * add/remove "dropup" css class on options menu.
     * @param {object} event
     */
    clickOptions = (event) => {
        try {
            var clickedRow;

            clickedRow = event.target.closest("tr");

            var clickedOffsetTop = clickedRow.offsetTop;
            var gridHeight = document.querySelector(".grid-wrapper").offsetHeight;
            var scrollPos = document.getElementById(this.props.id).scrollTop;
            var clickedPos = clickedOffsetTop - scrollPos;
            var optionItem = document.querySelector(".grid-row-dropdown-menu");
            var allOpenDown = true;
            var topCntnr = event.target.closest(".grid-top-container");

            if (!this.topContainer) {
                this.topContainer = topCntnr;
            }
            if (topCntnr.offsetHeight > (optionItem.childElementCount * 25) + 87 + gridHeight) {
                topCntnr.classList.add("defaultClass");
                allOpenDown = false;
            } else {
                topCntnr.classList.remove("defaultClass");
            }

            // a fix for properly placing table row menu-options in the window
            if (this.props.rowData.length > 5) {
                if (allOpenDown && gridHeight - clickedPos <= ((optionItem.childElementCount * 25) + 86)) {
                    clickedRow.classList.add("dropup");
                } else {
                    clickedRow.classList.remove("dropup");
                }
            }

        } catch (e) {

        }
    }

    /**
     * Onchange handler for filter input text field. Update filter 
     * flags and set in the component state.
     * @param {string} field
     * @param {object} evt
     */
    handleChange(field, evt) {
        let textBoxValue = CommonUtilities.leftTrim(evt.target.value);

        let filterFlags = Object.assign(this.state.filterFlags);
        filterFlags[field]["value"] = textBoxValue;

        if (!CommonUtilities.isEmpty(textBoxValue)) {
            filterFlags[field]["filterIcon"] = true;
        } else {
            filterFlags[field]["filterIcon"] = false;
        }

        this.setState({ filterFlags: filterFlags });
    }

    /**
     * OnKeyDown handler for filter input text field to respond to Enter key press.
     * @param {string} field
     * @param {string} fieldId
     * @param {object} evt
     */
    handleFilterInput(field, fieldId, evt) {
        let fieldDomObj = document.getElementById(fieldId);

        let textBoxValue = CommonUtilities.leftTrim(evt.target.value);

        let filterFlags = Object.assign(this.state.filterFlags);
        filterFlags[field]["value"] = textBoxValue;

        if (!CommonUtilities.isEmpty(textBoxValue)) {
            filterFlags[field]["filterIcon"] = true;
        } else {
            filterFlags[field]["filterIcon"] = false;
        }

        // call server api for getting filtered data
        let keyCode = evt.keyCode;

        if (!CommonUtilities.isEmpty(textBoxValue) && keyCode === 13) {
            // remove focus from filter field
            fieldDomObj.blur();
            this.props.onLazyLoad(undefined, filterFlags);
        }
    }

    /**
     * Render the component view.
     */
    render() {
        return (

            <div className={"grid-top-container " + (this.props.loading ? "grid-display-on" : "grid-display-on")}>
                <div className="grid-wrapper">
                    <div className="custom-scrollbar-table" id={this.props.id}>
                        <LoadingOverlay active={this.props.loading}>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        {Object.keys(this.props.columnDefs).map((elem, index) => {
                                            if (this.props.columnDefs[elem]["display"]) {
                                                return (
                                                    <th data-testid={this.props.id + "-th-" + elem}
                                                        onClick={(ev) => ev.stopPropagation()}
                                                        className={"grid-col-" + index} id={"grid-col-" + elem}
                                                        key={index}>
                                                        <div>
                                                            <span className={"grid-col-filter " + this.setFilterClass(elem)} id={"grid-col-filter" + elem} >
                                                                <input maxLength={this.props.columnDefs[elem].filterMaxLength} id={this.props.id + "-grid-col-filter-input-" + elem}
                                                                    onKeyDown={this.handleFilterInput.bind(this, elem, (this.props.id + "-grid-col-filter-input-" + elem))}
                                                                    onChange={this.handleChange.bind(this, elem)}
                                                                    value={this.state.filterFlags[elem]["value"]}
                                                                    placeholder={this.props.columnDefs[elem]["label"]}
                                                                    type="text" className="form-control" />
                                                            </span>
                                                            <span data-testid={this.props.id + "-col-head-txt-" + elem}
                                                                onClick={this.showFilter.bind(this, elem, this.props.columnDefs[elem]["filter"], (this.props.id + "-grid-col-filter-input-" + elem))}
                                                                className={"filter-option-" + elem + " " + (this.setFilterClass(elem) === "enable" ? "disable" : "enable")}>
                                                                {this.props.columnDefs[elem]["label"]}
                                                            </span>
                                                            {this.state.filterFlags[elem]["filterIcon"] ? <span onClick={this.clearFilter.bind(this, elem)} className="filtered-option"><i className="fa fa-filter"><i className="fa fa-close"></i></i></span> : ""}
                                                            {this.props.columnDefs[elem]["sort"] ? <span className="sorting-option"><i onClick={this.updateSortingObject.bind(this, elem)} className={this.setSortingClass(elem)}></i></span> : ""}
                                                        </div>
                                                    </th>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        this.props.rowData.length ? this.props.rowData.map((elem, index) => {
                                            return (
                                                <GridRow row={elem}
                                                    index={index}
                                                    totalRows={this.props.rowData.length}
                                                    length={this.props.rowData.length}
                                                    id={this.props.id}
                                                    columnDefs={this.props.columnDefs}
                                                    clickOptions={this.clickOptions}
                                                    optionHandler={this.props.optionHandler}
                                                    key={index}
                                                    keyValue={index}
                                                    actionPaths={this.props.actionPaths}
                                                    errorIndex={this.props.errorIndex}
                                                    singleOption={this.props.singleOption}
                                                    customizedOption={this.props.customizedOption} />)
                                        })
                                            :
                                            (
                                                <GridRow row={null}
                                                    index={0}
                                                    loading={this.props.loading}
                                                    length={this.props.rowData.length}
                                                    id={this.props.id}
                                                    columnDefs={this.props.columnDefs} />
                                            )
                                    }
                                </tbody>
                            </table>
                        </LoadingOverlay>
                    </div>

                </div>
                <br />

                {
                    this.props.pagination ?
                        <GridPagination currentPage={this.props.pgnConfig.currentPage}
                            numberOfPageLinks={this.props.pgnConfig.numberOfPageLinks}
                            countPerPage={this.props.pgnConfig.countPerPage}
                            totalCount={this.props.pgnConfig.totalCount}
                            onPgnChange={this.props.onPgnChange.bind(this)}
                            onPgnRowsCountChange={this.props.onPgnRowsCountChange ? this.props.onPgnRowsCountChange.bind(this) : null}
                            pageList={this.props.pgnConfig.pageList} />
                        :
                        null
                }
            </div>

        );
    }
}

export default Grid;
