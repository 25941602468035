import { API_INTERFACE } from '../constants/uri.constant';
import baseService from './services';
import { AuthUtils } from './service.auth.utils';

/**
 * get all Crops
 * @param {*} queryString
 */
function getCrops (queryString) {
  const url = API_INTERFACE.GET_CROPS + '?' + queryString;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.get(url, header);
}

/**
 * Get crop by crop id from backend.
 * @param {*} cropId
 */
function getCrop (cropId) {
  const url = API_INTERFACE.GET_CROP + '/' + cropId;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.get(url, header);
}

/**
 * update a Crop
 * @param {*} body
 */
function updateCrop (body) {
  const url = API_INTERFACE.GET_CROP;

  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.put(url, header, body);
}

/**
 * delete a Crop
 * @param {*} cropId
 */
export function deleteCrop (cropId) {
  const url = API_INTERFACE.GET_CROP + '/' + cropId;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.delete(url, header);
}

/**
 * create a Crop
 */
function createCrop (body, id) {
  const url = API_INTERFACE.GET_CROP;
  body.accountId = id;
  body.active = (body.active === '-1' ? 'true' : body.active);
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.post(url, header, body);
}

export const CropsService = {
  getCrop,
  getCrops,
  updateCrop,
  deleteCrop,
  createCrop
};
