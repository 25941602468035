/**
 * types of screens in this portal
 */
export const SCREENS = {
  home: 'home',
  login: 'login',
  firmware: 'firmware',
  user: 'user',
  createUser: 'create-user',
  vehicles: 'vehicles',
  implement: 'implement',
  forceChangePassword: 'force-change-password'
};
