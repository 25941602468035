import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import ListCalibrations from './ListCalibrations';
import ViewCalibration from './ViewCalibration';

/**
 * Home component for calibrations module. Contains route for different calibrations components.
 */
class Calibrations extends BaseScreen {
  constructor(props) {
    super(props);
    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }
  }

  /**
   * render the component view
   */
  render() {
    return (
      <div>
        <div className="card my-4-custom">
          <Switch>
            <Route exact path={'/calibrations/list-calibrations'} component={ListCalibrations} />
            <Route exact path={'/calibrations/view-calibration/:calibrationId'} component={ViewCalibration} />
            <Redirect to="/calibrations/list-calibrations" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Calibrations;
