import Color from 'color';

/**
 * Return hex representation of a color. If given color is not a 
 * valid color, then return hex value of default color provided.
 * @param {*} colStr 
 * @param {*} defaultValue 
 */
function getHex(colStr, defaultValue = '#000000') {
  let colorHex = Color(defaultValue);
  try {
    colorHex = Color(colStr);
  } catch (err) {
    console.log(colStr + ' is an invalid color');
  }
  return colorHex.hex();
}

/**
 * Return rgba color value. 
 * @param {*} colStr 
 * @param {*} alphaValue 
 */
function getRgba(colStr, alphaValue = 1) {
  let rgba = '0,0,0,' + alphaValue;
  try {
    rgba = Color(colStr).alpha(alphaValue).array().join();
  } catch (err) {
    console.log(colStr + ' is an invalid color');
  }
  return rgba;
}

/**
 * Add light variation to different parts of a rgb color.
 * @param {*} color 
 * @param {*} amount 
 */
function addLight(color, amount) {
  let cc = parseInt(color, 16) + amount;
  let c = (cc > 255) ? 255 : (cc);
  c = (c.toString(16).length > 1) ? c.toString(16) : `0${c.toString(16)}`;
  return c;
}

/**
 * Create light variant of the color.
 * @param {*} color 
 * @param {*} amount 
 */
function lighten(color, amount = 0) {
  color = getHex(color);
  color = (color.indexOf("#") >= 0) ? color.substring(1, color.length) : color;
  amount = parseInt((255 * amount) / 100);
  return color = `#${addLight(color.substring(0, 2), amount)}${addLight(color.substring(2, 4), amount)}${addLight(color.substring(4, 6), amount)}`;
}

/**
 * Add dark variation to different parts of a rgb color.
 * @param {*} color 
 * @param {*} amount 
 */
function subtractLight(color, amount) {
  let cc = parseInt(color, 16) - amount;
  let c = (cc < 0) ? 0 : (cc);
  c = (c.toString(16).length > 1) ? c.toString(16) : `0${c.toString(16)}`;
  return c;
}

/**
 * Create dark variant of the color.
 * @param {*} color 
 * @param {*} amount 
 */
function darken(color, amount = 0) {
  color = getHex(color);
  color = (color.indexOf("#") >= 0) ? color.substring(1, color.length) : color;
  amount = parseInt((255 * amount) / 100);
  return color = `#${subtractLight(color.substring(0, 2), amount)}${subtractLight(color.substring(2, 4), amount)}${subtractLight(color.substring(4, 6), amount)}`;
}

export const ColorUtilities = {
  getHex,
  getRgba,
  lighten,
  darken
};
