import { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppStorageHelper } from '../../shared/utils/appStorageHelper';
import { APP_COMMON_CONSTANTS } from '../../constants/app.constants';

/**
 * Parent base class for application components, that share the common functionality.
 */
class BaseScreen extends Component {
  /**
   * Component initialization
   * @param {object} props
   */
  constructor (props) {
    super(props);
    this.state = {};
    this.goToScreen = this.goToScreen.bind(this);
  }

  /**
   * Check if user auth info is available
   * @return {boolean}
   */
  isAuth () {
    const tokenInfo = AppStorageHelper.getTokenFromLocalStorage(APP_COMMON_CONSTANTS.AUTH_INFO);

    if (tokenInfo && _.has(tokenInfo, 'acessToken') && _.has(tokenInfo, 'refresh_token')) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Description: To handle key events
   * @param {object} event
   */
  handleKey (event) { }

  /**
   * Description: This method must be called to switch/open another screen
   * @param {string} screenId
   * @param {object} params is meant to be sent to next screen if required in future.
   */
  goToScreen (screenId, params) {
    if (this.props.routerData && this.props.routerData.history) {
      this.props.routerData.history.push({
        pathname: '/' + screenId,
        state: {
          prevPath: this.props.routerData.location.pathname
        }
      });
    } else if (this.props && this.props.history) {
      this.props.history.push({
        pathname: '/' + screenId,
        state: {
          prevPath: this.props.location.pathname
        }
      });
    }
  }
};

export default BaseScreen;

/**
 * Description:This method contains redux method connect which sync data to react component
 *              and its a mandatory method to screen if it need to use redux
 * @param {class}    componentName
 * @param {function} actionOnload this parameter is action of redux when screen get load
 * @param {string}   propsOnload  this parameter is reducer name of the redux for which we need data on load
 * @param {objects}  extraActions this parameter is optional, here use can pass extra actions on screen
 * @param {object}   extraProps this parameter is optional , here use can pass extra reducers on screen
 */
export function invokeConnect (componentName, actionOnload, propsOnload, extraActions, extraProps = {}) {
  /**
   * Provide the action creators as props to the component.
   */
  function mapDispatchToProps (dispatch) {
    return bindActionCreators({
      fetchNetworkData: actionOnload,
      ...extraActions
    }, dispatch);
  }

  /**
   * Provide the redux store data to the component via props
   */
  function mapStateToProps (state) {
    const _obj = {};
    if (Object.keys(extraProps).length > 0) {
      for (var key in extraProps) {
        _obj[key] = state[extraProps[key]];
      }
    }
    return {
      networkData: state[propsOnload],
      previousData: state.getScreenStateData,
      ..._obj
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(componentName);
}
