import _ from 'lodash';
import moment from 'moment';
import i18n from 'i18next';
import { AppStorageHelper } from '../../shared/utils/appStorageHelper';

/**
 * valid email RFC standard.
 */
const REG_EXP_EMAIL_1 = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;

/**
 * regex for any email
 */
const REG_EXP_EMAIL_2 = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

/**
 * strong password format regex
 */
// eslint-disable-next-line
const REG_EXP_STRONG_PWD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,50}$/;

/**
 * regex for username pattern
 */
// eslint-disable-next-line
const REG_EXP_USER_NAME = /[~`!@#$%^&*)(\-+=/|}{\\[\]\":;'?><,_\.]/;

/**
* isEmpty will check the empty value
* @param {string} _param
* @return {boolean}
*/
function isEmpty (_param) {
  return (_param === 'undefined' || _param === undefined || _param === '' || _param === null);
}

/**
* isEmptyObject will check the empty object
* @param {object} _obj
* @return {boolean}
*/
function isEmptyObject (_obj) {
  if (_obj == null) return true;// null and undefined are "empty"
  if (_obj.length > 0) return false;
  if (_obj.length === 0) return true;
  for (var key in _obj) {
    if (hasOwnProperty.call(_obj, key)) return false;
  }
  return true;
}

/**
 * epoch to readable date time
 * @param {number} dateAsNumber
 */
function getReadableDate (dateAsNumber) {
  let displayData = '';
  if (dateAsNumber) {
    // convert to number if it is passed as string
    dateAsNumber = parseInt(dateAsNumber, 10);
    displayData = moment(dateAsNumber).format('lll');
  }

  return displayData;
}

/**
 * email pattern validation
 * @param {string} email
 * @return {boolean}
 */
function isValidEmail (email) {
  let valid = false;
  if (!isEmpty(email) && REG_EXP_EMAIL_1.test(email) && REG_EXP_EMAIL_2.test(email)) {
    valid = true;
  }
  return valid;
}

/**
 * username pattern validation
 * @param {string} nameAsString
 * @return {boolean}
 */
function isValidName (nameAsString) {
  let valid = false;
  try {
    if (_.isString(nameAsString) && !REG_EXP_USER_NAME.test(nameAsString)) {
      valid = true;
    }
  } catch (err) {
    console.log('error parsing regular expression for name field');
  }
  return valid;
}

/**
 * strong password pattern validation
 * @param {string} pwdStr
 * @return {boolean}
 */
function isStrongPassword (pwdStr) {
  let valid = false;
  if (!isEmpty(pwdStr) && REG_EXP_STRONG_PWD.test(pwdStr)) {
    valid = true;
  }
  return valid;
}

/**
 * Determine if vehicle is assigned or default(not assigned to any account holder).
 * @param {object} vehicleObj
 * @return {boolean}
 */
function isDefaultVehicle (vehicleObj) {
  let isDefault = true;
  if (vehicleObj && vehicleObj.accountId && vehicleObj.accountId !== null && vehicleObj.accountId !== undefined) {
    isDefault = false;
  }

  return isDefault;
}

/**
 * leftTrim will trim of string
 * @param {string} _str
 * @return {string}
 */
function leftTrim (_str) {
  return _str.replace(/^\s+/, '');
}

/**
 * check if passed code falls into http valid status codes.
 * @param {number} code
 * @return {boolean}
 */
function isValidHttpCode (code) {
  if (code >= 200 && code < 400) {
    return true;
  }
  return false;
}

/**
 * get current language of i18 module
 * @return {string}
 */
function getI18SelectedLanguage () {
  return (i18n && _.has(i18n, 'language')) ? i18n.language : '';
}

/**
 * fetch preferred language from local storage
 * @return {string}
 */
function getPreferredLangCodeFromLocalStorage () {
  const lang = AppStorageHelper.getPreferredLangFromLocalStorage();
  return (lang && _.has(lang, 'prefLanguage')) ? lang.prefLanguage : '';
}

function getxyStickeFace(ecuOrientation) {
  if(ecuOrientation) {
    const myArr = ecuOrientation.split("_");
    if(myArr.length > 3)
      return myArr[3].charAt(0).toUpperCase() + myArr[3].slice(1);
    else
      return ''
  }
}

function getcableAttachDirection(ecuOrientation) {
  if(ecuOrientation) {
    const myArr = ecuOrientation.split("_");
    if(myArr.length > 3)
      return myArr[1].charAt(0).toUpperCase() + myArr[1].slice(1);
    else
      return ''
  } 
}

export const CommonUtilities = {
  isEmpty,
  isEmptyObject,
  getReadableDate,
  isValidEmail,
  isStrongPassword,
  isDefaultVehicle,
  isValidHttpCode,
  leftTrim,
  getI18SelectedLanguage,
  getPreferredLangCodeFromLocalStorage,
  isValidName,
  getxyStickeFace,
  getcableAttachDirection
};
