import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '../../../../Component/grid/Grid';
import AlertMessage from '../../../../Component/alert/AlertMessage';
import BaseScreen from '../../BaseScreen';
import { SCREENS } from '../../../../constants/screens.constant';
import { DevicesService } from '../../../../services/service.devices';
import AppInfoHelper from '../../../../shared/utils/appInfoHelper';
import { ROLE_TYPES } from '../../../../constants/app.constants';
import i18n from 'i18next';

/**
 * Informations to be shown in device listing grid.
 */
let deviceListConfig = {};

/**
 * List device component
 */
class ListDevices extends BaseScreen {
  constructor(props) {
    super(props);

    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }

    // initialize deviceListConfig with updated i18n resources
    deviceListConfig = {
      serialNumber: {
        label: i18n.t('userVehicles.devices.list.serialNumber'),
        sort: false,
        filter: true,
        display: true,
        filterMaxLength: 50
      },
      activationStatus: {
        label: i18n.t('userVehicles.devices.list.activationStatus'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 50
      }
    };

    this.state = {
      loggedInUserInfo: undefined,
      selectedAccountHolder: undefined,
      isAccountHolder: false,
      devicesData: [],
      loader: true,
      pgnConfig: {
        currentPage: 1,
        numberOfPageLinks: 0,
        countPerPage: 25,
        totalCount: 0,
        pageList: [25, 50, 100]
      },
      alert: {
        type: null,
        message: null
      },
      filterFieldsConfig: {},
      reinitialize: false
    };
  }

  /**
   * fetch user Role first on component mount, then fetch device data.
   */
  componentDidMount() {
    const self = this;
    const userInfoInstance = AppInfoHelper.getUserInstance();
    userInfoInstance.getUserInfo().then(function (response) {
      const userInfo = response;
      const selectedAccountHolder = userInfoInstance.getSelectedAccountHolderForOperator();
      if (userInfo.roleId === ROLE_TYPES.OPERATOR.ID && !selectedAccountHolder) {
        const warningMsg = i18n.t('userVehicles.devices.alert.selectAccountholder');
        self.setState(
          {
            devicesData: [],
            loader: false,
            loggedInUserInfo: response,
            reinitialize: false,
            alert: {
              type: 'danger',
              message: warningMsg
            }
          });
      } else {
        const accountHolderId = response.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID ? userInfo.id : selectedAccountHolder.id;
        self.setState(
          {
            loader: true,
            loggedInUserInfo: response,
            selectedAccountHolder: selectedAccountHolder,
            isAccountHolder: response.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID
          });
        const arg = {
          currentPage: 0,
          pageSize: self.state.pgnConfig.countPerPage
        };
        self.getDevicesData(arg, self.state.filterFieldsConfig, accountHolderId)
          .then(response => {
            const pgnConfig = self.setPaginationConfig(self.state.pgnConfig.currentPage, self.state.pgnConfig.countPerPage, response.data.total);
            self.setState({ devicesData: response.data.items, loader: false, pgnConfig: pgnConfig, reinitialize: false });
          }, error => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
              errorMsg = error.data.message;
            }
            self.setState(
              {
                devicesData: [],
                loader: false,
                reinitialize: false,
                alert: {
                  type: 'danger',
                  message: errorMsg
                }
              });
          });
      }
    }, function (error) { });
  }

  /**
   * prepare data for ui displayable
   * if activation status = 0 , displayes 'inactive'
   * if activation status = 1 , displayes 'active'
   * @param {*} response
   */
  prepareDevicesListData(response) {
    const data = response.data.items;
    let index = 0;
    for (index = 0; index < data.length; index++) {
      if (data[index].activationStatus === 1) {
        data[index].activationStatus = i18n.t('userVehicles.devices.active');
      } else {
        data[index].activationStatus = i18n.t('userVehicles.devices.inactive');
      }
    }
    response.data.items = data;
    return response;
  }

  /**
   * fetch devices from device backend service
   * @param {*} requiredParams
   * @param {*} filterFieldsObj
   * @param {*} accountHolderId
   */
  getDevicesData(requiredParams, filterFieldsObj, accountHolderId) {
    let reqQueryParams = '';
    let params;
    if (requiredParams) {
      params = {
        currentPage: requiredParams.currentPage,
        pageSize: requiredParams.pageSize
      };
    } else {
      params = {
        currentPage: this.state.pgnConfig.currentPage - 1,
        pageSize: this.state.pgnConfig.countPerPage
      };
    }

    reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' + params.pageSize;

    // add filter parameters (if available)
    let filterFielddQueryParams = '';
    if (!filterFieldsObj) {
      filterFieldsObj = this.state.filterFieldsConfig;
    }

    let filterQueryTemp = '';
    let key;
    for (key in filterFieldsObj) {
      const filterValue = filterFieldsObj[key].value;
      if (filterValue) {
        filterQueryTemp = key + '=' + filterValue;
        filterFielddQueryParams += '&' + filterQueryTemp;
      }
    }

    reqQueryParams += filterFielddQueryParams;

    if (!accountHolderId) {
      accountHolderId = this.state.loggedInUserInfo.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID
        ? this.state.loggedInUserInfo.id : this.state.selectedAccountHolder.id;
    }

    reqQueryParams += '&accountId=' + accountHolderId;

    return DevicesService.getDevices(reqQueryParams)
      .then(response => {
        response = this.prepareDevicesListData(response);
        return response;
      }, errResponse => {
        return Promise.reject(errResponse);
      });
  }

  /**
   * helper function for pagination configuration
   * @param {*} currentPage 
   * @param {*} countPerPage 
   * @param {*} totalCount 
   */
  setPaginationConfig(currentPage, countPerPage, totalCount) {
    return {
      currentPage: currentPage,
      numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
      countPerPage: countPerPage,
      totalCount: totalCount,
      pageList: [...this.state.pgnConfig.pageList]
    };
  }

  /**
   * handler when page number change, like user move to next page or previous page from current page he is viewing
   * @param {*} pageNumber 
   */
  onPgnChange(pageNumber) {
    if (pageNumber !== this.state.pgnConfig.currentPage) {
      window.scrollTo(0, 0);
      this.setState({ loader: true, devicesData: [] });

      const arg = {
        currentPage: (pageNumber - 1),
        pageSize: this.state.pgnConfig.countPerPage
      };
      this.getDevicesData(arg).then((response) => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        this.setState({
          devicesData: response.data.items,
          loader: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          }
        });
      }, (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            devicesData: [],
            loader: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /**
   * handles the grid listing when there is any change per page row count, like if user chose default row count 25 to 50 or something else.
   * @param {*} event 
   */
  onPgnRowsCountChange(event) {
    window.scrollTo(0, 0);
    const perPageItems = Number(event.target.value);
    let currentPage;
    if ((this.state.pgnConfig.currentPage - 1) * perPageItems >= this.state.pgnConfig.totalCount) {
      currentPage = 1;
    } else {
      currentPage = this.state.pgnConfig.currentPage;
    }

    /**
     * re-fetch data
     */
    this.setState({ loader: true, devicesData: [] });
    const arg = {
      currentPage: currentPage - 1,
      pageSize: perPageItems
    };

    this.getDevicesData(arg).then((response) => {
      const pgnConfig = this.setPaginationConfig(currentPage, perPageItems, response.data.total);
      this.setState({
        devicesData: response.data.items,
        loader: false,
        pgnConfig: pgnConfig,
        alert: {
          type: null,
          message: null
        }
      });
    }, (error) => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }
      this.setState(
        {
          devicesData: [],
          loader: false,
          alert: {
            type: 'danger',
            message: errorMsg
          }
        });
    });
  }

  /**
   * lazy load function when user use filter or sort functionality from grid header
   * @param {*} sortFieldsObj 
   * @param {*} filterFieldsObj 
   */
  onLazyLoad(sortFieldsObj, filterFieldsObj) {
    this.setState({ loader: true, devicesData: [] });
    const pageNumber = 1;
    const arg = {
      currentPage: pageNumber - 1,
      pageSize: this.state.pgnConfig.countPerPage
    };

    this.getDevicesData(arg, filterFieldsObj)
      .then((response) => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        if (!filterFieldsObj) {
          filterFieldsObj = this.state.filterFieldsConfig;
        }
        this.setState({
          devicesData: response.data.items,
          loader: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          },
          filterFieldsConfig: filterFieldsObj
        });
      }, (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            devicesData: [],
            loader: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
  }

  /**
   * dismiss alert
   */
  handleAlertDismiss() {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render function to display the list
   */
  render() {
    return (
      <div>
        <div className="container-fluid p-4">
          <div className="clearfix"></div>
          {
            this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)}
            />
          }
          <div className="row">
            <div className="col-md-12 text-right f-btn">
              {
                (
                  this.state.isAccountHolder
                    ? (
                      <Link className="btn btn-primary" to={
                        {
                          pathname: '/user-vehicles/devices/activate-device'
                        }} data-testid="activate-device-link">
                        {i18n.t('userVehicles.devices.activateDevice')}
                      </Link>
                    )
                    : null
                )
              }
            </div>
          </div>
          <div className="clearfix"></div>
          <div>
            <Grid id="devicesListGrid"
              keyColumn="id"
              rowData={this.state.devicesData}
              reinitialize={this.state.reinitialize}
              columnDefs={deviceListConfig}
              loading={this.state.loader}
              pagination={true}
              pgnConfig={this.state.pgnConfig}
              onPgnChange={this.onPgnChange.bind(this)}
              onPgnRowsCountChange={this.onPgnRowsCountChange.bind(this)}
              pageList={this.state.pageList}
              lazyLoading={true}
              onLazyLoad={this.onLazyLoad.bind(this)}
            />
          </div>
        </div>
      </div>);
  }
}
export default ListDevices;
