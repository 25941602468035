import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import i18n from 'i18next';
import { CommonUtilities } from '../../../shared/utils/commonUtilities';

/**
 * Component to constuct an individual row of a grid.
 */
class GridRow extends Component {
  /**
   * Component initialization
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.noDataMessage = i18n.t('common.noDataFound');

    this.handleClick = this.handleClick.bind(this);
    // parentTbodyElem is an overflow hidden wrapper. Using this reference to align the grid menu option
    // within the boundary of the table (by increasing/resetting the grid tBody height)
    this.parentTbodyElem = undefined;
  }

  /**
   * React component life cycle method - add click handler on window
   */
  componentDidMount() {
    window.addEventListener('click', this.handleClick);
  }

  /**
   * React component life cycle method - Remove click handler on window and
   * adjust parent <tbody> height
   */
  componentWillUnmount() {
    this.adjustTbodyHeight(false);
    window.removeEventListener('click', this.handleClick);
  }

  /**
   * Hide any opened options menu and adjust parent <tbody> height.
   */
  handleClick() {
    const openedItem = this.getOpenedItem();
    if (openedItem) {
      openedItem.style.display = 'none';
    }
    this.adjustTbodyHeight(false);
  }

  /**
   * Format the data to be displayed as per requirement.
   * @param {object} cell
   * @param {*} rowData
   * @param {number} cellNumber
   * @param {number} indexNumber
   */
  displayData(cell, rowData, cellNumber, indexNumber) {
    let displayData;
    if (cell.isDate) {
      displayData = CommonUtilities.getReadableDate(rowData);
    } else {
      displayData = rowData;
    }
    return (displayData ? displayData : '--');
  }

  /**
   * Adjust(increase/decrease) height of parent <tbody> element, to show/hide
   * the row menu options within the table boundary.
   * @param {boolean} shouldIncrease
   */
  adjustTbodyHeight(shouldIncrease) {
    if (this.parentTbodyElem) {
      if (shouldIncrease && (this.props.totalRows && (this.props.totalRows < 6))) {
        this.parentTbodyElem.style.height = this.parentTbodyElem.clientHeight + 130 + 'px';
      } else {
        // reset to default
        this.parentTbodyElem.style.height = '';
      }
    }
  }

  /**
   * Check if there is any menu option currently opened and return
   * a dom reference to it.
   */
  getOpenedItem() {
    const listCollection = document.getElementsByClassName('grid-row-dropdown-menu');
    let openedItem;
    for (let i = 0; i < listCollection.length; i++) {
      if (listCollection[i].style.display === 'block') {
        openedItem = listCollection[i];
        break;
      }
    }
    return openedItem;
  }

  /**
   * Click handler for menu otpion button. Based on current display
   * state, show/hide options menu.
   * @param {object} event
   */
  clickOnOptions(event) {
    this.parentTbodyElem = event.target.closest('tbody');
    const openedItem = this.getOpenedItem();
    let list;
    if (event.target.innerHTML) {
      list = event.target.nextElementSibling;
    } else {
      list = event.target.parentElement.nextElementSibling;
    }
    if (openedItem) {
      if (openedItem.id === list.id) {
        this.adjustTbodyHeight(false);
        list.style.display = 'none';
      } else {
        this.adjustTbodyHeight(true);
        openedItem.style.display = 'none';
        list.style.display = 'block';
      }
    } else {
      this.adjustTbodyHeight(true);
      list.style.display = 'block';
    }
    this.props.clickOptions(event);
    event.stopPropagation();
  }

  /**
   * Render the component view.
   */
  render() {
    return (
      this.props.length
        ? (
          <tr key={'main_' + this.props.keyValue}>
            {
              Object.keys(this.props.columnDefs).map((cell, cellIndex) => {
                if (cell !== 'options' && this.props.columnDefs[cell].display) {
                  return (
                    <td key={cellIndex}>
                      {this.displayData(this.props.columnDefs[cell], this.props.row[cell], cellIndex, this.props.index)}
                    </td>);
                } else {
                  return null;
                }
              })
            }
            {
              this.props.columnDefs.options
                ? <td className="grid-options">
                  {
                    this.props.singleOption
                      ? this.props.columnDefs.options.list.map((action, index) => {
                        if (action.actionType === 'link') {
                          return <li key={index}><Link key={index + action.action} to={this.props.actionPaths(action, this.props.row)}>{action.label}</Link></li>;
                        } else if (action.actionType === 'button') {
                          return <button key={index} type="button" className="btn mr-2 grid-single-option" onClick={() => { return this.props.optionHandler(action, this.props.row); }} > {action.label} </button>;
                        } else {
                          // eslint-disable-next-line
                          return <li key={index}><a onClick={() => { return this.props.optionHandler(action, this.props.row); }}>{action.label}</a></li>;
                        }
                      })
                      : (this.props.customizedOption
                        ? <div className="dropdown-wrapper">
                          <button data-testid={this.props.id + "-row-dd-btn-" + this.props.index}
                            className="btn btn-default dropdown-toggle"
                            onClick={this.clickOnOptions.bind(this)} type="button" id="dropdownMenu1"
                            data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="true">
                            <i className="fa"></i>
                          </button>
                          <ul className="dropdown-menu grid-row-dropdown-menu" aria-labelledby="dropdownMenu1" id={`${this.props.id}-${this.props.keyValue}-list`}>
                            {
                              this.props.row.options.map((action, index) => {
                                if (action.actionType === 'link') {
                                  return <li key={index}><Link key={index + action.action} to={this.props.actionPaths(action, this.props.row)}>{action.label}</Link></li>;
                                } else {
                                  // eslint-disable-next-line
                                  return <li key={index}><a onClick={() => { return this.props.optionHandler(action, this.props.row); }}>{action.label}</a></li>;
                                }
                              })
                            }
                          </ul>
                        </div>
                        : <div className="dropdown-wrapper">
                          <button data-testid={this.props.id + "-row-dd-btn-" + this.props.index}
                            className="btn btn-default dropdown-toggle"
                            onClick={this.clickOnOptions.bind(this)} type="button" id="dropdownMenu1"
                            data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="true">
                            <i className="fa"></i>
                          </button>
                          <ul className="dropdown-menu grid-row-dropdown-menu" aria-labelledby="dropdownMenu1" id={`${this.props.id}-${this.props.keyValue}-list`}>
                            {
                              this.props.columnDefs.options.list.map((action, index) => {
                                if (action.actionType === 'link') {
                                  return (
                                    <li key={index}>
                                      <Link data-testid={this.props.id + "-row-option-" + action.action}
                                        key={index + action.action}
                                        to={this.props.actionPaths(action, this.props.row)}>
                                        {action.label}
                                      </Link>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li key={index}>
                                      {
                                        // eslint-disable-next-line
                                        <a data-testid={this.props.id + "-row-option-" + action.action}
                                          onClick={() => { return this.props.optionHandler(action, this.props.row); }}>
                                          {action.label}
                                        </a>
                                      }
                                    </li>
                                  );
                                }
                              })
                            }
                          </ul>
                        </div>)}
                </td>
                : null
            }
          </tr>
        )
        : (
          // for "loading indicator" and "No data available" message
          <tr>
            <td colSpan={Object.keys(this.props.columnDefs).filter((col) => {
              return this.props.columnDefs[col].display === true;
            }).length}>
              {this.props.loading ? <div className=''></div> : this.noDataMessage}
            </td>
          </tr>
        )
    );
  }
}

export default GridRow;
