import React from 'react';
import { Link } from 'react-router-dom';
import BaseScreen from '../../BaseScreen';
import { SCREENS } from '../../../../constants/screens.constant';
import { VehiclesService } from '../../../../services/service.vehicles';
import AlertMessage from '../../../../Component/alert/AlertMessage';
import { CommonUtilities } from '../../../../shared/utils/commonUtilities';
import i18n from 'i18next';
import LoadingOverlay from '../../../../Component/loader/LoadingOverlay';
import { CommonConstants } from '../../../../constants/app.constants';

/**
 * view vehicle component.
 */
class ViewVehicle extends BaseScreen {
  constructor(props) {
    super(props);

    let isAuthenticated = true;

    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    this.state = {
      isAuthenticated: isAuthenticated,
      isLoading: true,
      vehicleData: '',
      isErrorOnLoad: false,
      isDefault: false,
      apiResponseMsg: '',

      alert: {
        type: null,
        message: null
      }
    };

    this.vehicleTypes = CommonConstants.getVehicleTypes();
    this.ecuOrientationTypes = CommonConstants.getEcuOrientationTypes();
  }

  /**
   * display form on mount
   */
  componentDidMount() {
    if (this.state.isAuthenticated) {
      this.displayVehicleInfo();
    }
  }

  /**
   * fetch data from backend to display on ui.
   */
  displayVehicleInfo() {
    const vehicleId = this.props.match.params.vehicleId;

    VehiclesService.getVehicle(vehicleId).then(response => {
      this.setState({
        vehicleData: response.data,
        isLoading: false,
        isDefault: CommonUtilities.isDefaultVehicle(response.data)
      });
    }, error => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }

      this.setState({
        vehicleData: {},
        isLoading: false,
        isErrorOnLoad: true,
        apiResponseMsg: errorMsg,
        alert: {
          type: 'danger',
          message: errorMsg
        }
      });
    });
  }

  /**
   * previous page
   */
  handleBack() {
    this.props.history.goBack();
  }

  /**
   * resolve type of vehicle to display
   * @param {*} vehicleType 
   */
  getVehicleTypeToDisplay(vehicleType) {
    const vehicleTypeToDisplay = this.vehicleTypes.find(type => {
      return type.value === vehicleType;
    });
    return (vehicleTypeToDisplay ? vehicleTypeToDisplay.displayName : '');
  }

  /**
   * resolve ECU orientation
   * @param {*} ecuType 
   */
  getEcuOrientationTypeToDisplay(ecuType) {
    if (ecuType) {
      const ecuTypeToDisplay = this.ecuOrientationTypes.find(type => {
        return type.value === ecuType;
      });
      return (ecuTypeToDisplay ? ecuTypeToDisplay.displayName : '');
    } else {
      return i18n.t('userVehicles.vehicles.form.notAvailable');
    }
  }

  resolveStickeFaceAndCableAttachDirection = (type) => {
    if (type === "Forward") {
      return "Front"
    } else if (type === "Aft") {
      return "Rear"
    } else {
      return type
    }
  }

  /**
   * vehicles details form
   */
  renderVehicleDetails() {
    return (
      <>
        <h2>{i18n.t('userVehicles.vehicles.viewVehicle')}</h2>
        <div className="view-profile-details width-70per">
          <div className="row">
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.make')}</label>
              <p>{this.state.vehicleData.make}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.model')}</label>
              <p>{this.state.vehicleData.model}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.year')}</label>
              <p>{this.state.vehicleData.year}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.type')}</label>
              <p>{this.getVehicleTypeToDisplay(this.state.vehicleData.type)}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.attack')}</label>
              <p>{this.state.vehicleData.attack}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.backlash')}</label>
              <p>{this.state.vehicleData.backlash}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.sensitivity')}</label>
              <p>{this.state.vehicleData.sensitivity}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.localId')}</label>
              <p>
                {
                  this.state.vehicleData.localId
                    ? this.state.vehicleData.localId
                    : i18n.t('userVehicles.vehicles.form.notAvailable')
                }
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.steeringWheelType')}</label>
              <p>
                {
                  this.state.vehicleData.steeringWheelType
                    ? this.state.vehicleData.steeringWheelType
                    : i18n.t('userVehicles.vehicles.form.notAvailable')
                }
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.wheelbase')}</label>
              <p>
                {
                  this.state.vehicleData.wheelbase
                    ? this.state.vehicleData.wheelbase
                    : i18n.t('userVehicles.vehicles.form.notAvailable')
                }
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.antennaHeight')}</label>
              <p>
                {
                  this.state.vehicleData.antennaHeight
                    ? this.state.vehicleData.antennaHeight
                    : i18n.t('userVehicles.vehicles.form.notAvailable')
                }
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.antennaPivot')}</label>
              <p>
                {
                  this.state.vehicleData.antennaPivot
                    ? this.state.vehicleData.antennaPivot
                    : i18n.t('userVehicles.vehicles.form.notAvailable')
                }
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.antennaOffset')}</label>
              <p>
                {
                  this.state.vehicleData.antennaOffset
                    ? this.state.vehicleData.antennaOffset
                    : i18n.t('userVehicles.vehicles.form.notAvailable')
                }
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.frontHitch')}</label>
              <p>
                {
                  this.state.vehicleData.frontHitch
                    ? this.state.vehicleData.frontHitch
                    : i18n.t('userVehicles.vehicles.form.notAvailable')
                }
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.rearHitch')}</label>
              <p>
                {
                  this.state.vehicleData.rearHitch
                    ? this.state.vehicleData.rearHitch
                    : i18n.t('userVehicles.vehicles.form.notAvailable')
                }
              </p>
            </div>

            <div className="col-md-12">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.ecuOrientation')}</label>
            </div>
            <div className="col-md-4">
              <label >{i18n.t('userVehicles.vehicles.form.label.CableAttachDirection')}</label>
              <input type="text" className="form-control" name="rearHitch" disabled
                placeholder={i18n.t('userVehicles.vehicles.form.placeholder.CableAttachDirection')}
                value={CommonUtilities.getcableAttachDirection(this.state.vehicleData.ecuOrientation) !== '' ? this.resolveStickeFaceAndCableAttachDirection(CommonUtilities.getcableAttachDirection(this.state.vehicleData.ecuOrientation)) : 'N/A'}
              />
            </div>
            <div className="col-md-4">
              <label >{i18n.t('userVehicles.vehicles.form.label.XYStickeFace')}</label>
              <input type="text" className="form-control" name="xyStickeFace" disabled
                placeholder={i18n.t('userVehicles.vehicles.form.placeholder.XYStickeFace')}
                value={CommonUtilities.getxyStickeFace(this.state.vehicleData.ecuOrientation) !== '' ? this.resolveStickeFaceAndCableAttachDirection(CommonUtilities.getxyStickeFace(this.state.vehicleData.ecuOrientation)) : 'N/A' }
              />
            </div>
            <div className="col-md-12"><br /></div>

            <div className="col-md-6">
              <label className="emphasized-label">{i18n.t('userVehicles.vehicles.form.label.profileVersion')}</label>
              <p>
                {
                  this.state.vehicleData.profileVersion
                    ? this.state.vehicleData.profileVersion
                    : i18n.t('userVehicles.vehicles.form.notAvailable')
                }
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-12 text-right f-btn">
          {
            this.state.isDefault
              ? null : (
                <Link className="btn btn-primary" to={{
                  pathname: '/user-vehicles/vehicles/edit-vehicle/' + this.state.vehicleData.id,
                  state: { vehicleData: this.state.vehicleData }
                }}>
                  {i18n.t('userVehicles.vehicles.form.button.edit')}
                </Link>
              )
          }

        </div>

      </>
    );
  }

  /**
   * render error
   */
  renderError() {
    return (
      <div className="col-md-12 text-right f-btn">
        <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>
          {i18n.t('userVehicles.vehicles.form.button.back')}
        </button>
      </div>
    );
  }

  /*
    alert message handling
  */
  handleAlertDismiss() {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render to render view vehicle form
   */
  render() {
    return (
      <div className="container-fluid p-4">
        {
          this.state.alert.message &&
          <AlertMessage message={this.state.alert.message}
            type={this.state.alert.type}
            isAlertOpen={!!(this.state.alert.message)}
            handleDismiss={this.handleAlertDismiss.bind(this)}
          />
        }

        <LoadingOverlay active={this.state.isLoading}>
          {
            this.state.isErrorOnLoad ? this.renderError() : this.renderVehicleDetails()
          }
        </LoadingOverlay>
      </div>
    );
  }
}

export default ViewVehicle;
