import React from 'react';

/**
 * To display alert messages(feedback) in form fields.
 */
export const FormErrors = ({ formErrors, testId }) => {
  let testIdSuffix = testId ? "-" + testId : "";
  return (
    <div className='formErrors'>
      {
        <p data-testid={"status-msg" + testIdSuffix}>
          {formErrors}
        </p>
      }
    </div>
  );
}