import React from 'react';
import _ from 'lodash';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import AlertMessage from '../../../Component/alert/AlertMessage';
import { CropsService } from '../../../services/service.crop';
import { FormErrors } from '../../../Component/SubmissionStatus';
import { StyledText } from '../../../Component/StyledText';
import { CommonConstants } from "../../../constants/app.constants";
import i18n from 'i18next';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';

/**
 * edit Crop components. holds all functionalities for editing an Crop.
 */
class EditCrops extends BaseScreen {
    constructor(props) {
        super(props);
        let isAuthenticated = true;
        if (!this.isAuth()) {
            isAuthenticated = false;
            this.goToScreen(SCREENS.login);
        }
        this.state = {
            isAuthenticated: isAuthenticated,
            formfields: {
                name: "",
                active: "",
                localId: ""
            },
            formErrors: {
                name: "",
                localId: ""
            },
            formValid: false,

            alert: {
                type: null,
                message: null
            },

            isWaitingApiResponse: false,
            isLoading: true,
            savedFormFields: {},
            isErrorOnLoad: false,
        }
        this.booleanTypes = CommonConstants.getBooleanTypes();
    }

    /**
     * utility function to remove null values, this utility function works on single object 
     * @param {*} obj 
     */
    removeNullValues(obj) {
        // this utility function works on single object 
        let newObj = _.mapValues(obj, (value, key) => {
            // do not update value for "accountId" and "operatorId"
            if (key !== "accountId" && key !== "operatorId") {
                if ((value === null) || (value === undefined)) {
                    value = "";
                }
            }
            return value;
        });
        return newObj;
    }

    componentDidMount() {
        if (this.state.isAuthenticated) {
            this.displayCropInfo();
        }
    }

    /**
     * fetch data from backend to display on ui.
     */
    displayCropInfo() {
        let cropData;
        let cropId = this.props.match.params.id;

        // fetch data
        CropsService.getCrop(cropId).then(response => {
            cropData = this.removeNullValues(response.data);

            this.setState({
                formfields: cropData,
                savedFormFields: { ...cropData },
                isLoading: false
            });
        }, error => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
                errorMsg = error.data.message;
            }

            this.setState({
                cropData: {},
                isLoading: false,
                isErrorOnLoad: true,
                apiResponseMsg: errorMsg,
                alert: {
                    type: "danger",
                    message: errorMsg
                },
            });
        });
    }

    /**
     * helper function to validate decimal values entered.
     * @param {*} num 
     */
    validateDecimalNumbers(num) {
        if ((num.length > 0) &&
            ((isNaN(num) || (num.indexOf(".") === 0) || (num.indexOf(".") === num.length - 1)) ||
                !(/^\d+(\.\d{1,6})?$/.test(num)))) {
            return false;
        }

        return true;
    }

    /**
     * handler to validate form inputs
     */
    handleUserInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        let formfields = this.state.formfields;
        formfields[name] = value;

        let fieldValidationErrors = this.state.formErrors;
        let msg = "";

        switch (name) {
            case "name":
                if (value.length === 0) {
                    msg = i18n.t('crops.alert.required');
                } else if (value.length > 64) {
                    msg = i18n.t('crops.alert.char64');
                }

                break;

            case "localId":
                if (value.length > 256) {
                    msg = i18n.t('crops.alert.char256');
                }

                break;

            default:
                break;
        }

        fieldValidationErrors[name] = msg;

        let isFormValid = (formfields.name) &&
            (fieldValidationErrors.name === "" && fieldValidationErrors.localId === "");

        this.setState({
            formfields: formfields,
            formErrors: fieldValidationErrors,
            formValid: isFormValid,
        });
    }

    /**
     * handles crops submit function. this calls api to upload the updated crop.
     * @param {*} event 
     */
    submitHandler(event) {
        event.preventDefault();

        let that = this;

        that.setState({
            isWaitingApiResponse: true
        });

        CropsService.updateCrop(this.state.formfields).then(function (res) {
            let lastSavedFormFields = that.state.formfields;
            that.setState({
                alert: {
                    type: "success",
                    message: i18n.t('crops.alert.cropUpdate')
                },
                isWaitingApiResponse: false,
                savedFormFields: { ...lastSavedFormFields }
            });
        }, function (res) {
            let alertMsg = i18n.t('common.genericApiError');

            if (res && res.data && res.data.message) {
                alertMsg = res.data.message;
            }

            that.setState({
                alert: {
                    type: "danger",
                    message: alertMsg
                },
                isWaitingApiResponse: false
            });
        });

    }

    /*
     alert message handling
     */
    handleAlertDismiss() {
        this.setState({
            alert: {
                type: null,
                message: null
            }
        });
    }

    /**
     * helper function
     * @param {*} Obj1 
     * @param {*} Obj2 
     */
    isEqual(Obj1, Obj2) {
        let result = true;
        _.forOwn(Obj1, function (value, key) {
            if (_.toString(value) !== _.toString(Obj2[key])) {
                result = false;
            }
        });

        return result;
    }

    /**
     * handler to check if form is valid
     */
    isFormValid() {
        let result = this.isEqual(this.state.formfields, this.state.savedFormFields);
        let returnVal = (!result && this.state.formValid);

        return returnVal;
    }

    /**
     * navigate to previous page
     */
    handleBack() {
        this.props.history.goBack();
    }

    /**
     * reset form fields to previous state
     */
    resetCropsFormFields() {
        let prevSavedFormFields = this.state.savedFormFields;

        this.setState({
            formfields: { ...prevSavedFormFields },
            formErrors: {
                name: "",
                localId: ""
            },
        });
    }

    /**
     * edit crop form
     */
    renderCropsForm() {
        return (
            <LoadingOverlay active={this.state.isWaitingApiResponse || this.state.isLoading}>
                <div className="container-fluid">
                    <form>
                        <h2>{i18n.t('crops.form.label.editCrop')}</h2>
                        <div className="view-profile-details width-70per">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="form-group">
                                        <label htmlFor="name">{i18n.t('crops.form.label.cropName')} <StyledText uiText="*" /></label>
                                        <input type="text" required className="form-control" name="name"
                                            placeholder={i18n.t('crops.form.placeholder.cropName')}
                                            value={this.state.formfields.name}
                                            onChange={this.handleUserInput} maxLength="32" />
                                        <FormErrors formErrors={this.state.formErrors.name} />
                                    </div>
                                </div>

                                <div className="col-md-7">
                                    <div className="form-group">
                                        <label htmlFor="localId">{i18n.t('crops.form.label.localId')}</label>
                                        <input type="text" className="form-control" name="localId"
                                            placeholder={i18n.t('crops.form.placeholder.localId')}
                                            value={this.state.formfields.localId}
                                            onChange={this.handleUserInput} maxLength="256" />
                                        <FormErrors formErrors={this.state.formErrors.localId} />
                                    </div>
                                </div>

                                <div className="col-md-7">
                                    <div className="form-group">
                                        <label htmlFor="active">{i18n.t('crops.form.label.active')}</label>
                                        <select className="form-control" id="active" name="active"
                                            onChange={this.handleUserInput} value={this.state.formfields.active}>
                                            <option key="placeHolderactive" value="">{i18n.t('crops.form.placeholder.active')}</option>
                                            {
                                                this.booleanTypes.map((elem, id) => {
                                                    return <option key={elem.value + id} value={elem.value}>{elem.displayName}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 text-right">
                            <button type="button" className="btn btn-secondary mr-2"
                                onClick={this.resetCropsFormFields.bind(this)}>
                                {i18n.t('crops.form.button.reset')}
                            </button>
                            <button type="submit" className="btn btn-primary"
                                disabled={(!this.isFormValid() || this.state.isWaitingApiResponse)}
                                onClick={this.submitHandler.bind(this)}>
                                {i18n.t('crops.form.button.save')}
                            </button>
                        </div>

                    </form>
                </div>
            </LoadingOverlay>
        );
    }

    /**
     * renders errors
     */
    renderError() {
        return (
            <div className="col-md-12 text-right f-btn">
                <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>
                    {i18n.t('crops.form.button.back')}
                </button>
            </div>
        );
    }

    /**
     * render edit crop form
     */
    render() {
        const breadcrumb = [
            { id: 'home', displayName: i18n.t('crops.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'crop', displayName: i18n.t('crops.breadcrumb.crop'), className: '', link: false },
            { id: 'edit', displayName: i18n.t('crops.breadcrumb.edit'), className: '', link: false }
        ];
        return (
            <div className="container-fluid p-4">
                <BreadcrumbCustom breadcrumb={breadcrumb} />
                <div className="clearfix"></div>
                {
                    this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                        type={this.state.alert.type}
                        isAlertOpen={!!(this.state.alert.message)}
                        handleDismiss={this.handleAlertDismiss.bind(this)}
                    />
                }
                {
                    this.state.isErrorOnLoad ? this.renderError() : this.renderCropsForm()
                }
            </div>
        );
    }
}

export default EditCrops;
