import React, { Component } from 'react';
import { FormGroup, Input } from 'reactstrap';
import Pagination from '../pager/Pagination';

/**
 * Container component for pagination ui elements of the grid.
 */
class GridPagination extends Component {

  /**
   * Render the component view.
   */
  render () {
    const isRowCountEnabled = this.props.onPgnRowsCountChange;
    return (
      <div className={'custom-paging ' + (isRowCountEnabled ? '' : 'pull-right')} id="paging">
        {
          isRowCountEnabled ? (
            <FormGroup>
              <Input type="select"
                id="rowCountCustomSelect"
                name="customSelect"
                onChange={(e) => { this.props.onPgnRowsCountChange(e); }}
              >
                {this.props.pageList.map((elem, index) => {
                  return <option value={elem} key={index}>{elem}</option>;
                })}
              </Input>
            </FormGroup>
          ) : null
        }

        <Pagination totalPageItems={this.props.totalCount}
          onChangePage={this.props.onPgnChange.bind(this)}
          initialPage={this.props.currentPage}
          pageSize={this.props.countPerPage}
        />
      </div>
    );
  }
}

export default GridPagination;
