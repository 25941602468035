import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BaseScreen from '../../BaseScreen';
import { SCREENS } from '../../../../constants/screens.constant';
import ListVehicle from './ListVehicle';
import ViewVehicle from './ViewVehicle';
import EditVehicle from './EditVehicle';

/**
 * main vehicle components. holds routings to different vehicle sub components
 */
class Vehicles extends BaseScreen {
  constructor (props) {
    super(props);

    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }
  }

  render () {
    return (
      <Switch>
        <Route exact path="/user-vehicles/vehicles/list-vehicles" component={ListVehicle} />
        <Route exact path="/user-vehicles/vehicles/view-vehicle/:vehicleId" component={ViewVehicle} />
        <Route exact path="/user-vehicles/vehicles/edit-vehicle/:vehicleId" component ={EditVehicle}/>
        <Redirect to="/user-vehicles/vehicles/list-vehicles" />
      </Switch>
    );
  }
}

export default Vehicles;
