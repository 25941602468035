import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
import { appStorageMiddleware } from './actions/appStorage.middleware';

/**
 * redux store
 */
const store = createStore(
  reducers,
  applyMiddleware(appStorageMiddleware)
);

export default store;
