import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import ListAssociatedAssignments from './ListAssociatedAssignments';
import { ROLE_TYPES } from '../../../constants/app.constants';
import i18n from 'i18next';

/**
 * component for operator associated operators
 */
class MyOperators extends BaseScreen {
  constructor (props) {
    super(props);

    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }
  }

  /**
   * render operators
   */
  renderOperators () {
    let result = null;
    const loggedInUserInfo = this.props.userInfo;
    if (_.isObject(loggedInUserInfo) && _.has(loggedInUserInfo, 'id')) {
      const isOperator = (loggedInUserInfo.roleId === ROLE_TYPES.OPERATOR.ID);
      result = (
        <div>
          <ListAssociatedAssignments userId={loggedInUserInfo.id} isOperator={isOperator} userEmail={loggedInUserInfo.email} />
        </div>
      );
    }

    return result;
  }

  /**
   * react render function
   */
  render () {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('users.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'operators', displayName: i18n.t('users.breadcrumb.operators'), className: '', link: false }
    ];

    return (
      <div className="card my-4-custom">
        <div className="container-fluid p-4">
          <BreadcrumbCustom breadcrumb={breadcrumb} />
          <div className="clearfix"></div>
          {
            this.renderOperators()
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps ({ user }) {
  return {
    userInfo: user.userInfo
  };
}

export default connect(mapStateToProps, null)(MyOperators);
