import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import ListCrops from './ListCrops';
import ViewCrops from './ViewCrops';
import EditCrops from './EditCrops';
import CreateCrops from './CreateCrops';

class Crops extends BaseScreen {
  constructor (props) {
    super(props);
    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }
  }

  render () {
    return (
      <div>
        <div className="card my-4-custom">
          <Switch>
            <Route exact path={'/crops/list-crops'} component={ListCrops} />
            <Route exact path={'/crops/create-crops'} component={CreateCrops} />
            <Route exact path={'/crops/view-crops/:id'} component={ViewCrops} />
            <Route exact path={'/crops/edit-crops/:id'} component={EditCrops} />
            <Redirect to="/crops/list-crops" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Crops;
