import React from 'react';
import { Link } from 'react-router-dom';
import BaseScreen from '../../BaseScreen';
import { SCREENS } from '../../../../constants/screens.constant';
import AlertMessage from '../../../../Component/alert/AlertMessage';
import { ImplementsService } from '../../../../services/service.implements';
import i18n from 'i18next';
import LoadingOverlay from '../../../../Component/loader/LoadingOverlay';

/**
 * view Implement components. holds all functionalities for viewing an Implement.
 */
class ViewImplement extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    this.state = {
      isAuthenticated: isAuthenticated,
      implementId: this.props.match.params.implementId,
      isLoading: true,
      implementData: '',
      isErrorOnLoad: false,
      apiResponseMsg: '',
      alert: {
        type: null,
        message: null
      }
    };
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      this.displayImplementInfo();
    }
  }

  /**
   *  fetch data from backend to display on ui.
   */
  displayImplementInfo () {
    const implementId = this.state.implementId;
    ImplementsService.getImplement(implementId).then(response => {
      this.setState({
        implementData: response.data,
        isLoading: false
      });
    }, error => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }

      this.setState({
        implementData: {},
        isLoading: false,
        isErrorOnLoad: true,
        apiResponseMsg: errorMsg,
        alert: {
          type: 'danger',
          message: errorMsg
        }
      });
    });
  }

  /**
   *  navigate to previous page
   */
  handleBack () {
    this.props.history.goBack();
  }

  /**
   * view implement form
   */
  renderImplementDetails () {
    return (
      <>
        <h2>{i18n.t('userVehicles.implements.viewImplement')}</h2>
        <div className="view-profile-details width-70per">
          <div className="row">
            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('userVehicles.implements.form.label.name')}</label>
              <p>{this.state.implementData.name}</p>
            </div>

            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('userVehicles.implements.form.label.length')}</label>
              <p>{this.state.implementData.length}</p>
            </div>

            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('userVehicles.implements.form.label.position')}</label>
              <p>{this.state.implementData.position}</p>
            </div>

            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('userVehicles.implements.form.label.localId')}</label>
              <p>
                {
                  this.state.implementData.localId
                    ? this.state.implementData.localId
                    : i18n.t('userVehicles.implements.form.notAvailable')
                }
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-12 text-right f-btn">
          <Link className="btn btn-primary" to={{
            pathname: '/user-vehicles/implements/edit-implement/' + this.state.implementId
          }}>
            {i18n.t('userVehicles.implements.form.button.edit')}
          </Link>
        </div>

      </>
    );
  }

  /**
   * renders errors
   */
  renderError () {
    return (
      <div className="col-md-12 text-right f-btn">
        <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>
          {i18n.t('userVehicles.implements.form.button.back')}
        </button>
      </div>
    );
  }

  /**
   * dissmiss alert message
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * render view implement form
   */
  render () {
    return (
      <div className="container-fluid p-4">

        {
          this.state.alert.message &&
          <AlertMessage message={this.state.alert.message}
            type={this.state.alert.type}
            isAlertOpen={!!(this.state.alert.message)}
            handleDismiss={this.handleAlertDismiss.bind(this)}
          />
        }
        <LoadingOverlay active={this.state.isLoading}>
          {
            this.state.isErrorOnLoad ? this.renderError() : this.renderImplementDetails()
          }
        </LoadingOverlay>
      </div>
    );
  }
}

export default ViewImplement;
