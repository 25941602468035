import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import HomeView from './HomeView';
import ViewProfile from './ViewProfile';
import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';

/**
 * Home component
 */
class Home extends BaseScreen {
  constructor (props) {
    super(props);

    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }
  }

  render () {
    return (
      <div>
        <div className="card my-4-custom">
          <Switch>
            <Route exact path={'/home/default'} component={HomeView} />
            <Route exact path={'/home/view-profile/:userId'} component={ViewProfile}/>
            <Route exact path={'/home/edit-profile/:userId'} component={EditProfile}/>
            <Route exact path={'/home/change-password/:userId'} component={ChangePassword}/>
            <Redirect from="/home" to="/home/default" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Home;
