import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import i18n from 'i18next';

/**
 * Bootstrap modal dialog functionality encapsulation.
 * This modal open separate modal window for specific functionality rendring.
 * @param {object} props
 */
const ModalDialog = (props) => {
  const modalPopup = () => {
    return (
      <div>
        <Modal isOpen={props.isModalOpen}
          onClosed={props.onModalClosed}
          unmountOnClose={true}
          centered={true}
          wrapClassName="modal-wrapper-custom"
        >
          <div className="modal-header custom-modal-header">
            <h5 className="modal-title">{props.modalTitle}</h5>
            <Button className="close" onClick={props.handleModalDismiss}>
              <span>×</span>
            </Button>
          </div>
          <ModalBody>{props.modalMessage}</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={props.modalAction.bind(this, props.modalData)}>
              {i18n.t('common.modal.footer.button.yes')}
            </Button>
            <Button color="secondary" onClick={props.handleModalDismiss}>
              {i18n.t('common.modal.footer.button.no')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };
  return modalPopup();
};

export default ModalDialog;
