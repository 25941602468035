import React from 'react';
import { Alert } from 'reactstrap';

/**
 * Generic alert function for any type of success or error message.
 * @param {object} props
 */
const AlertMessage = (props) => {
  let testIdSuffix = props.testId ? "-" + props.testId : "";

  const statusMessage = () => {
    return (
      <div>
        <Alert data-testid={"alert-msg" + testIdSuffix} color={props.type} 
                isOpen={props.isAlertOpen} 
                toggle={props.handleDismiss} 
                fade={false}>
          {props.message}
        </Alert>
      </div>
    );
  };

  return statusMessage();
};

export default AlertMessage;
