import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import i18n from 'i18next';
import Grid from '../../../Component/grid/Grid';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { CalibrationsService } from '../../../services/service.calibrations';
import { ROLE_TYPES } from '../../../constants/app.constants';
import ModalDialog from '../../../Component/modaldialog/ModalDialog';
import AlertMessage from '../../../Component/alert/AlertMessage';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';

/**
 * Informations to be shown in associated calibrations listing grid, along with options 
 * per rows.
 */
let calibrationsListConfig = {};

/**
 * Component to display user's associated calibrations.
 */
class ListCalibrations extends BaseScreen {
  /**
   * Component initialization
   * @param {object} props
   */
  constructor(props) {
    super(props);

    let isAuthenticated = true;

    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    // initialize calibrationsListConfig with updated i18n resources
    calibrationsListConfig = {
      make: {
        label: i18n.t('calibrations.list.make'),
        display: true,
      },
      model: {
        label: i18n.t('calibrations.list.model'),
        display: true,
      },
      calibrationType: {
        label: i18n.t('calibrations.list.type'),
        display: true,
      },
      calibrationStatus: {
        label: i18n.t('calibrations.list.status'),
        display: true,
      },
      calibrationStartTime: {
        label: i18n.t('calibrations.list.startTime'),
        display: true,
        isDate: true,
      },
      calibrationEndTime: {
        label: i18n.t('calibrations.list.endTime'),
        display: true,
        isDate: true,
      },
      options: {
        label: i18n.t('calibrations.list.options.options'),
        display: true,
        list: [
          {
            actionType: 'link',
            action: 'view',
            label: i18n.t('calibrations.list.options.view')
          },
          {
            actionType: '',
            action: 'delete',
            label: i18n.t('calibrations.list.options.delete')
          }
        ]
      }
    };

    this.state = {
      isAuthenticated: isAuthenticated,
      userCalibrationsData: [],
      loader: true,
      pgnConfig: {
        currentPage: 1,
        numberOfPageLinks: 0,
        countPerPage: 25,
        totalCount: 0,
        pageList: [25, 50, 100]
      },
      alert: {
        type: null,
        message: null
      },
      modal: {
        modalShow: false,
        shouldRenderModal: false,
        modalTitle: '',
        modalMsg: '',
        modalData: '',
        modalAction: ''
      }
    };

    // accountId to be used in getCalibration call
    this.calibrationsAccountId = '';
  }

  /**
   * React component life cycle method called after first render.
   */
  componentDidMount() {
    if (this.state.isAuthenticated) {
      if (_.isEmpty(this.props.userInfo) || !_.isObject(this.props.userInfo)) {
        this.setState({
          loader: false
        });
      } else {
        let isOperator = (this.props.userInfo.roleId === ROLE_TYPES.OPERATOR.ID);
        if (isOperator && _.isEmpty(this.props.selectedAccHolderInfo)) {
          const warningMsg = i18n.t('calibrations.alert.selectAccountholder');
          this.setState(
            {
              loader: false,
              alert: {
                type: 'danger',
                message: warningMsg
              }
            });
        } else {
          // determine the accountId to be used in getCalibrations call
          this.calibrationsAccountId = isOperator ? this.props.selectedAccHolderInfo.id :
            this.props.userInfo.id;

          const arg = {
            currentPage: 0,
            pageSize: this.state.pgnConfig.countPerPage
          };

          this.getCalibrationsByAccountId(arg).then(response => {
            const pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage, this.state.pgnConfig.countPerPage, response.data.total);
            this.setState({
              userCalibrationsData: response.data.items,
              pgnConfig: pgnConfig,
              loader: false
            });
          }, error => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
              errorMsg = error.data.message;
            }
            this.setState(
              {
                userCalibrationsData: [],
                loader: false,
                alert: {
                  type: 'danger',
                  message: errorMsg
                }
              });
          });
        }
      }
    }
  }

  /**
   * function to communicate with backend to fetch all associated 
   * calibrations of an account holder.
   */
  getCalibrationsByAccountId(requiredParams) {
    let reqQueryParams = '';

    // build required params
    let params;
    if (requiredParams) {
      params = {
        currentPage: requiredParams.currentPage,
        pageSize: requiredParams.pageSize
      };
    } else {
      params = {
        currentPage: this.state.pgnConfig.currentPage - 1,
        pageSize: this.state.pgnConfig.countPerPage
      };
    }

    reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' +
      params.pageSize + '&accountId=' + this.calibrationsAccountId;

    return CalibrationsService.getCalibrations(reqQueryParams)
      .then(response => {
        return response;
      }, errResponse => {
        return Promise.reject(errResponse);
      });
  }

  /**
   * helper function for pagination configuration
   * @param {*} currentPage 
   * @param {*} countPerPage 
   * @param {*} totalCount 
   */
  setPaginationConfig(currentPage, countPerPage, totalCount) {
    return {
      currentPage: currentPage,
      numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
      countPerPage: countPerPage,
      totalCount: totalCount,
      pageList: [...this.state.pgnConfig.pageList]
    };
  }

  /**
   * handler when page number change, like user move to next page or previous page from current page he is viewing
   * @param {*} pageNumber 
   */
  onPgnChange = (pageNumber) => {
    if (pageNumber !== this.state.pgnConfig.currentPage) {
      this.setState({ loader: true, userCalibrationsData: [] });

      const arg = {
        currentPage: (pageNumber - 1),
        pageSize: this.state.pgnConfig.countPerPage
      };

      this.getCalibrationsByAccountId(arg).then(response => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        this.setState({
          userCalibrationsData: response.data.items,
          pgnConfig: pgnConfig,
          loader: false
        });
      }, error => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            userCalibrationsData: [],
            loader: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /**
   * routing to relevant page based on row option selection
   * @param {*} actionType 
   * @param {*} row 
   */
  actionPaths = (actionType, row) => {
    if (actionType.action === 'view') {
      return ({
        pathname: '/calibrations/view-calibration/' + row.id
      });
    }
  }


 /**
   * delete a vehicle from backend
   */
   
  deleteCalibration () {
    if (this.state.modal.modalData) {
      const modalData = this.state.modal.modalData;
      // set loader on
      this.setState({
        loader: true,
        modal: {
          modalShow: false,
          shouldRenderModal: false
        }
      });

      CalibrationsService.deleteCalibration(modalData.id)
        .then((successResponse) => {
          let pageNumber = this.state.pgnConfig.currentPage;
          if ((this.state.userCalibrationsData.length <= 1) && (this.state.pgnConfig.currentPage > 1)) {
            pageNumber = this.state.pgnConfig.currentPage - 1;
          }

          this.setState({
            userCalibrationsData: [],
            alert: {
              type: 'success',
              message: i18n.t('calibrations.alert.calibrationDelete')
            }
          });

          const arg = {
            currentPage: (pageNumber - 1),
            pageSize: this.state.pgnConfig.countPerPage
          };

          this.getCalibrationsByAccountId(arg).then((response) => {
            const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
            this.setState({
              userCalibrationsData: response.data.items,
              loader: false,
              pgnConfig: pgnConfig,
              modal: {
                modalShow: false,
                shouldRenderModal: false
              }
            });
          }, (error) => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
              errorMsg = error.data.message;
            }
            this.setState(
              {
                userCalibrationsData: [],
                loader: false,
                alert: {
                  type: 'danger',
                  message: errorMsg
                }
              });
          });
        }, (error) => {
          let errorMsg = i18n.t('common.genericApiError');
          if (error && error.data && error.data.message) {
            errorMsg = error.data.message;
          }
          this.setState(
            {
              loader: false,
              alert: {
                type: 'danger',
                message: errorMsg
              }
            });
        });
    }
  }

 /**
   * for handling options having confirmation modals
   * @param {*} actionObject 
   * @param {*} row 
   */
   
  optionHandler (actionObject, row) {
    let modalInfo;
    if (actionObject.action === 'delete') {
      modalInfo = {
        modalMsg: i18n.t('calibrations.form.modal.deleteMsg', { name: row.make }),
        modalTitle: i18n.t('calibrations.form.modal.deleteTitle'),
        modalAction: 'delete_calibration'
      };
    }

    this.modalPopup(true, row, modalInfo);
    
  }
  
  /**
   * modal dismissal action
   */
  handleModalDismiss () {
    this.modalPopup(false);
  }

/**
   *  handle modal delete action.
   * @param {*} data 
   */
  onModalAction (data) {
    const modalAction = this.state.modal.modalAction;
    if (modalAction === 'delete_calibration') {
      this.modalPopup(false, data);
    }
  }

  /**
   * function after modal is closed, re-render listing page after delete operation.
   */
  onModalClosed () {
    if (this.state.modal.modalAction === 'delete_calibration') {
      this.deleteCalibration();
    }
  }
  
  /**
   * Modal window to show each row options
   * @param {*} state 
   * @param {*} rowData 
   * @param {*} modalInfo 
   */
  modalPopup (state, rowData, modalInfo) {
    if (state) {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalMsg: modalInfo.modalMsg,
          modalTitle: modalInfo.modalTitle,
          modalData: rowData || '',
          modalAction: modalInfo.modalAction
        }
      });
    } else {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalData: rowData || '',
          modalAction: this.state.modal.modalAction
        }
      });
    }
  }
  
  /**
   * alert message handling
   */
  handleAlertDismiss = () => {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render function to display associated calibrations list.
   */
  render() {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('calibrations.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'calibrations', displayName: i18n.t('calibrations.breadcrumb.calibrations'), className: '', link: false }
    ];

    return (
      <div className="container-fluid p-4">
        <BreadcrumbCustom breadcrumb={breadcrumb} />
        <div className="clearfix"></div>
        {
          this.state.alert.message &&
          <AlertMessage message={this.state.alert.message}
            type={this.state.alert.type}
            isAlertOpen={!!(this.state.alert.message)}
            handleDismiss={this.handleAlertDismiss}
          />
        }
        <div>
          <Grid id="calibrationsListGrid"
          	optionHandler={this.optionHandler.bind(this)}
            keyColumn="id"
            rowData={this.state.userCalibrationsData}
            columnDefs={calibrationsListConfig}
            loading={this.state.loader}
            actionPaths={this.actionPaths.bind(this)}
            pagination={true}
            pgnConfig={this.state.pgnConfig}
            onPgnChange={this.onPgnChange} />
        </div>
        {
          this.state.modal.shouldRenderModal &&
          <ModalDialog modalTitle={this.state.modal.modalTitle}
            modalMessage={this.state.modal.modalMsg}
            modalData={this.state.modal.modalData}
            isModalOpen={this.state.modal.modalShow}
            modalAction={this.onModalAction.bind(this)}
            handleModalDismiss={this.handleModalDismiss.bind(this)}
            onModalClosed={this.onModalClosed.bind(this)}
          />
        }
      </div>
    );
  }
}

/**
 * From the redux store, map "user and selectedAccHolder" to the props of the component.
 * @param {object} 
 * @return {object}
 */
function mapStateToProps({ user, selectedAccHolder }) {
  return {
    userInfo: user.userInfo,
    selectedAccHolderInfo: selectedAccHolder.selectedAccHolderInfo
  };
}

export default connect(mapStateToProps, null)(ListCalibrations);
