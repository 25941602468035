import { UPDATE_USER_INFO } from '../actions/action.userinfo';
import { CLEAR_APP_STORE_DATA } from '../actions/action.app';

/**
 * redux reducer
 */

const initialState = {
  userInfo: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER_INFO:
      return { ...state, userInfo: { ...state.userInfo, ...action.payload } };
    case CLEAR_APP_STORE_DATA:
      return initialState;
    default:
      return state;
  }
}
