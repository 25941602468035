import _ from 'lodash';
import { APP_COMMON_CONSTANTS } from '../constants/app.constants';
import { AppStorageHelper } from '../shared/utils/appStorageHelper';

/**
 * Return api access Token app Storage
 */
function getAccessToken () {
  var accesstoken = getTokenFromStorage().acessToken;
  return accesstoken;
}

/**
 * Return api refresh Token app Storage
 */
function getRefreshToken () {
  var refresh_token = getTokenFromStorage().refresh_token;
  return refresh_token;
}

/**
 * Return Auth Object from session Storage
 * @return {object}
 */
function getTokenFromStorage () {
  let authData = AppStorageHelper.getTokenFromLocalStorage(APP_COMMON_CONSTANTS.AUTH_INFO);
  return _.isObject(authData) ? authData : {};
}

export const AuthUtils = {
  getAccessToken,
  getRefreshToken
};
