import {GET_SELECTED_ACC_HOLDER, UPDATE_SELECTED_ACC_HOLDER} from '../actions/action.accholderinfo';
import {CLEAR_APP_STORE_DATA} from '../actions/action.app';

/**
 * redux reducer
 */
const initialState = {
  selectedAccHolderInfo: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SELECTED_ACC_HOLDER:
      if (action.payload) {
        return { ...state, selectedAccHolderInfo: { ...state.selectedAccHolderInfo, ...action.payload } };
      } else {
        return initialState;
      }

    case UPDATE_SELECTED_ACC_HOLDER:
      if (action.payload) {
        return { ...state, selectedAccHolderInfo: { ...state.selectedAccHolderInfo, ...action.payload } };
      } else {
        return initialState;
      }

    case CLEAR_APP_STORE_DATA:
      return initialState;
    default:
      return state;
  }
}
