import i18n from 'i18next';
import moment from 'moment'
import 'moment/locale/zh-cn.js';
import 'moment/locale/ja.js';
import { initReactI18next } from 'react-i18next';
import { CommonUtilities } from "./utils/commonUtilities";

/**
 * Get preferred language from localStorage, and set the locales for i18n and moment
 */
let prefLang = CommonUtilities.getPreferredLangCodeFromLocalStorage();

/**
 * initialize i18n
 */
i18n
  .use(initReactI18next)
  .init({
    lng: prefLang,
    fallbackLng: 'en',
    keySeparator: '.',
    interpolation: {
      escapeValue: false
    }
  });

/**
 * Due to possibly a bug with moment.js, if the locale to be applied is not from the known locales, then 
 * moment sets the locale to be the one which was imported at last. In our case japanese locale is set as 
 * default. To fix this, appying the 'en' locale first (before setting the locale from preferred locale value)
 */
moment.locale('en');

/**
 * set locale for moment.js. For "Chinese language", language code has been used 
 * as "zh". But moment.js accepts the locale as 'zh-cn' for chinese language
 */
let prefLangLocale = prefLang;
if(prefLangLocale === 'zh') {
  prefLangLocale = 'zh-cn';
}

/**
 * set locale for moment
 */
moment.locale(prefLangLocale);

export default i18n;
