import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import i18n from 'i18next';
import { Collapse, Navbar, NavbarToggler, NavbarBrand} from 'reactstrap';
import { LoggedInUserActions } from './LoggedInUserActions';
import BaseScreen from '../Containers/Screens/BaseScreen';
import { AppStorageHelper } from '../shared/utils/appStorageHelper';
import { SCREENS } from '../constants/screens.constant';
import { LeftMenu } from './LeftMenu';
import { ROLE_TYPES, APP_BUILD_VERSION } from '../constants/app.constants';
import { AuthenticationServices } from '../services/service.authentication';

/**
 * Header container of the application.
 */
class Header extends BaseScreen {
  /**
   * Component initialization
   * @param {object} props
   */
  constructor(props) {
    super(props);

    let isAuthenticated = true;

    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    this.toggleNavbar = this.toggleNavbar.bind(this);

    this.state = {
      collapsed: true,
      isAuthenticated: isAuthenticated,
    };
  }

  /**
   * Handle navigation and profile menu toggling.
   */
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  /**
   * Render loggedin user info in application header.
   */
  renderUserInfo() {
    let userInfoMsg = '';
    let selAccHolderMsg = '';

    if(this.props.userInfo && _.has(this.props.userInfo, 'firstName')) {
      userInfoMsg = i18n.t('home.userProfile.msgs.userInfoMsg', 
                      { firstName: this.props.userInfo.firstName, lastName: this.props.userInfo.lastName});
      if (this.props.userInfo.roleId === ROLE_TYPES.OPERATOR.ID) {
        if (this.props.selectedAccHolderInfo && _.has(this.props.selectedAccHolderInfo, 'email')) {
          selAccHolderMsg = i18n.t('home.userProfile.msgs.selAccHolderMsg', 
                              { email: this.props.selectedAccHolderInfo.email});
        } else {
          selAccHolderMsg = i18n.t('home.userProfile.msgs.noAccHolderMsg');
        }
      }
    }

    return (
      <>
        <span>{userInfoMsg}</span>
        <br />
        <span>{selAccHolderMsg}</span>
      </>
    );
  }

  /**
   * Click handler for navigation menu.
   * @param {string} arg 
   * @param {object} event 
   */
  onNavLinkClick(arg, event) {
    this.toggleNavbar();
  }

  /**
   * Fetch user subId from userInfo props and return to the caller.
   * @return {string} 
   */
  getUserSubId() {
    let userId = '';
    if(this.props.userInfo && _.has(this.props.userInfo, 'id')) {
      userId = this.props.userInfo.id;
    }
    return userId;
  }

  /**
   * Logout event handler
   * @param {object} event
   */
  handleLogout = (event) => {
    AuthenticationServices.logout().then(
      (response) => {
        console.log("logout api returned SUCCESS response");
      }, (error) => {
        console.log("logout api returned ERROR response");
      });
    this.gotoLogin();
  }

  /**
   * Move to the login page
   */
  gotoLogin = () => {
    AppStorageHelper.clearApplicationData();
    this.goToScreen(SCREENS.login);
  }

  /**
   * render the component view
   */
  render() {
    let logoHeaderSrc = process.env.PUBLIC_URL + '/assets/images/oem_logo_header.png?preventCaching=' + APP_BUILD_VERSION;

    return (
      <header>
        <div className="logo-inner">
          <a href="#/home" data-testid="header-logo-link">
            <img src={logoHeaderSrc} alt="" />
          </a>
        </div>
        <div className="header-right">
          <span className="user-info pull-right"> {this.renderUserInfo()} </span>
          <LoggedInUserActions logoutHandler={this.handleLogout} userSubId={this.getUserSubId()} />
        </div>
        <Navbar color="dark" className="navbar navbar-expand-lg navbar-dark fixed-top bg-dark">
          <NavbarBrand href="#/home">
            <img src={logoHeaderSrc} height="32" alt="" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse isOpen={!this.state.collapsed} navbar>
            <LeftMenu leftMenuItems={this.props.leftMenuItems}
              routerData={this.props.routerData}
              isMobile={true}
              onSidebarNavLinkClick={this.onNavLinkClick.bind(this)}
              addUserInfo={true}
              userSubId={this.getUserSubId()} 
              logoutHandler={this.handleLogout}/>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}

/**
 * From the redux store, map "user and selectedAccHolder" to the props of the component.
 * @param {object} 
 * @return {object}
 */
function mapStateToProps({ user, selectedAccHolder }) {
  return { 
    userInfo: user.userInfo,
    selectedAccHolderInfo: selectedAccHolder.selectedAccHolderInfo
  };
}

export default connect(mapStateToProps, null)(Header);
