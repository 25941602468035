export const GET_SELECTED_ACC_HOLDER = 'GET_SELECTED_ACC_HOLDER';
export const UPDATE_SELECTED_ACC_HOLDER = 'UPDATE_SELECTED_ACC_HOLDER';

/**
 * action for account holder selection
 * @param {*} payload 
 */
export function getSelectedAccHolder (payload) {
  return { type: GET_SELECTED_ACC_HOLDER, payload: payload };
}

/**
 * action for updating selected accoun holder
 * @param {*} payload 
 */
export function updateSelectedAccHolder (payload) {
  return { type: UPDATE_SELECTED_ACC_HOLDER, payload: payload };
}
