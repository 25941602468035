import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BaseScreen from '../../BaseScreen';
import { SCREENS } from '../../../../constants/screens.constant';
import ListImplements from './ListImplements';
import CreateImplement from './CreateImplement';
import ViewImplement from './ViewImplement';
import EditImplement from './EditImplement';

/**
 * main implement components. holds routings to different implement sub components
 */
class Implements extends BaseScreen {
  constructor (props) {
    super(props);

    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }
  }

  render () {
    return (
      <Switch>
        <Route exact path="/user-vehicles/implements/list-implements" component={ListImplements} />
        <Route exact path="/user-vehicles/implements/create-implement" component={CreateImplement} />
        <Route exact path="/user-vehicles/implements/view-implement/:implementId" component={ViewImplement} />
        <Route exact path="/user-vehicles/implements/edit-implement/:implementId" component={EditImplement} />
        <Redirect to="/user-vehicles/implements/list-implements" />
      </Switch>
    );
  }
}

export default Implements;
