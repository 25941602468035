import React from 'react';
import Loadable from 'react-loading-overlay';
import i18n from 'i18next';

/**
 * 'react-loading-overlay' functionality encapsulation for UI pages.
 * @param {object} props
 */
const LoadingOverlay = (props) => {
  return (
    <Loadable
      active={props.active}
      spinner
      background="rgba(256, 256, 256, 0.92)"
      color="#000"
      spinnerSize="140px"
      text={props.text ? props.text : i18n.t('common.loaderMsg')}
      className={props.customClass ? props.customClass : ''}
    >
      {props.children}
    </Loadable>
  );
};
export default LoadingOverlay;
