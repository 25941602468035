import { GET_SELECTED_ACC_HOLDER, UPDATE_SELECTED_ACC_HOLDER } from './action.accholderinfo';
import { AppStorageHelper } from '../../shared/utils/appStorageHelper';

/**
 * redux middleware to get and set account holder on selection for operator.
 * @param {*} param0 
 */
export function appStorageMiddleware ({ dispatch }) {
  return function (next) {
    return function (action) {
      if (action.type === GET_SELECTED_ACC_HOLDER) {
        const selAccHolder = AppStorageHelper.getSelectedAccHolderFromLocalStorage();
        action.payload = selAccHolder;
      } else if (action.type === UPDATE_SELECTED_ACC_HOLDER) {
        AppStorageHelper.setSelectedAccHolderToLocalStorage(action.payload);
      }
      return next(action);
    };
  };
}
