import _ from 'lodash';
export const REFRESH_TOKEN_PATH = 'auth/token';

/**
 * api interface for the application. It is updated with api configurations on app startup.
 */
let API_INTERFACE = {};

/**
 * update API_INTERFACE with all API endpoints used.
 */
export function updateApiInterface(apiConfig) {
  apiConfig = !_.isObject(apiConfig) ? {} : apiConfig;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const X_API_KEY = process.env.REACT_APP_X_API_KEY;
  const OEM_ID = process.env.REACT_APP_OEM_ID;
  
  API_INTERFACE = {
    OEM_ID: OEM_ID,
    X_API_KEY: X_API_KEY,
  
    /**
     * auth service endpoints
     */
    AUTH_LOGIN: BASE_URL + 'auth/login/',
    AUTH_LOGOUT: BASE_URL + 'auth/logout/',
    RESET_PWD_TOKEN_REQUEST: BASE_URL + 'auth/reset-password-request',
    RESET_PWD: BASE_URL + 'auth/reset-password',
    REFRESH_TOKEN: BASE_URL + REFRESH_TOKEN_PATH,
  
    /**
     * user service endpoints
     */
    USER_SIGN_UP: BASE_URL + 'api/v1/user/signup',
    USER_CNFIRM_SIGN_UP: BASE_URL + 'api/v1/user/confirmsignup',
    CONFIRM_SIGN_UP_EMAIL_REQUEST: BASE_URL + 'api/v1/user/sendverifylink',
    GET_USER: BASE_URL + 'api/v1/user',
    GET_USERS: BASE_URL + 'api/v1/users',
    CHANGE_PASSWORD: BASE_URL + 'api/v1/user/changepassword',
    FORCE_CHANGE_PASSWORD: BASE_URL + 'api/v1/user/forcechangepassword',
    GET_ROLES: BASE_URL + 'api/v1/roles',
    CREATE_USER: BASE_URL + 'api/v1/user',
    UPDATE_USER: BASE_URL + 'api/v1/user',
    ASSIGN_USER: BASE_URL + 'api/v1/user/adminassign',
    GET_ASSOCIATED_ACCOUNT_HOLDERS: BASE_URL + 'api/v1/user/businesses',
    CHANGE_EMAIL: BASE_URL + 'api/v1/user/changeemail',
    CONFIRM_CHANGE_EMAIL: BASE_URL + 'api/v1/user/confirmchangeemail',
    CREATE_ASSIGNMENT: BASE_URL + 'api/v1/user/',
    UPDATE_ASSIGNMENT: BASE_URL + 'api/v1/user/',
    DELETE_ASSIGNMENT: BASE_URL + 'api/v1/user/',
    LIST_ASSIGNMENT: BASE_URL + 'api/v1/user/',
  
    /**
     * vehicle endpoints
     */
    VEHICLE_POST_FILE: BASE_URL + 'api/v1/vehicles/file',
    GET_VEHICLES: BASE_URL + 'api/v1/vehicles',
    GET_VEHICLES_BY_ID: BASE_URL + 'api/v1/vehicles',
    CREATE_VEHICLE: BASE_URL + 'api/v2/vehicles',
    GET_VEHICLE: BASE_URL + 'api/v1/vehicles',
    UPDATE_VEHICLE: BASE_URL + 'api/v1/vehicles',

    /**

      subscriptions code endpoint
    */

     GET_SUBCRIPTIONCODES: BASE_URL + 'api/v1/devices/subscriptions',
  
    /**
     * firmware endpoints
     */
    FIRMWARE: BASE_URL + 'api/v1/firmware',
    FIRMWARES: BASE_URL + 'api/v1/firmwares',
  
    /**
     * regdevice service endpoints
     */
    GET_DEVICES: BASE_URL + 'api/v1/regdevices',
    ACTIVATE_DEVICE: BASE_URL + 'api/v1/regdevices/activate',
  
    /**
     * implement service endpoints
     */
    GET_IMPLEMENTS: BASE_URL + 'api/v1/implements',
    GET_IMPLEMENT: BASE_URL + 'api/v1/implement',
    CREATE_IMPLEMENT: BASE_URL + 'api/v1/implement',
  
    /**
     * tasks service endpoints
     */
    GET_TASKS: BASE_URL + 'api/v1/tasks',
    GET_TASK: BASE_URL + 'api/v1/task',
  
    /**
     * operation service endpoints
     */
    GET_OPERATION: BASE_URL + 'api/v1/operation',
    GET_OPERATIONS: BASE_URL + 'api/v1/operations',
  
    /**
     * crop service endpoints
     */
    GET_CROP: BASE_URL + 'api/v1/crop',
    GET_CROPS: BASE_URL + 'api/v1/crops',
  
    /**
     * calibrations endpoint
     */
    GET_CALIBRATIONS: BASE_URL + 'api/v1/calibrations',
    GET_CALIBRATION: BASE_URL + 'api/v1/calibration',
    DELETE_CALIBRATIONS: BASE_URL + 'api/v1/calibrations'
  };
}

export { API_INTERFACE };
