import { API_INTERFACE } from '../constants/uri.constant';
import baseService from './services';
import { AuthUtils } from './service.auth.utils';

/**
 * service to create a vihicle in backend.
 * @param {*} reqBody
 */
function createVehicle (reqBody) {
  const url = API_INTERFACE.CREATE_VEHICLE;

  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.post(url, header, reqBody);
}

/**
 * service to fetch a vehicle based on vehicle id
 * @param {*} vehicleId
 */
function getVehicle (vehicleId) {
  const url = API_INTERFACE.GET_VEHICLE + '/' + vehicleId;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

/**
 * service to get all default subscriptions codes from backend
 * @param {*} queryString 
 */
function getSubscriptionsCodes (queryString) {
  const url = API_INTERFACE.GET_SUBCRIPTIONCODES + '?' + queryString;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.get(url, header);
}

/**
 * service to fetch all vehicles assigned to an account holder.
 * @param {*} accountId
 */
function getVehiclesByUserId (accountId) {
  const url = API_INTERFACE.GET_VEHICLES_BY_ID + '?accountId=' + accountId;

  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

/**
 * service to delete a vehicle with id as vehicleId
 * @param {*} vehicleId
 */
function deleteVehicle (vehicleId) {
  const url = API_INTERFACE.GET_VEHICLE + '/' + vehicleId;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.delete(url, header);
}

/**
 * Uploads a new vehicles file and replaces existing default vehicles in the database.
 */
function vehicle_post_file () {
  const url = API_INTERFACE.VEHICLE_POST_FILE;

  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.post(url, header, {});
}

function upload_vehicle_file (uploadUrl, fileObj) {
  const header = {
    'Content-Type': 'binary/octet-stream'
  };

  return baseService.put(uploadUrl, header, fileObj);
}

/**
 * service to update a default vehicle
 * @param {*} body 
 */
function updateVehicle (body) {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.put(API_INTERFACE.UPDATE_VEHICLE, header, body);
}

/**
 * updates vehicle assignment
 * @param {*} reqBody 
 */
function updateVehicleAssignment (reqBody) {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.post(API_INTERFACE.UPDATE_VEHICLE, header, [reqBody]);
}

export const SubscriptionService = {
  createVehicle,
  getVehicle,
  getSubscriptionsCodes,
  getVehiclesByUserId,
  deleteVehicle,
  vehicle_post_file,
  upload_vehicle_file,
  updateVehicle,
  updateVehicleAssignment
};
