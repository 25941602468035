import React from 'react';
import _ from 'lodash';
import i18n from 'i18next';
import BaseScreen from '../BaseScreen';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import { SCREENS } from '../../../constants/screens.constant';
import { FormErrors } from '../../../Component/SubmissionStatus';
import { UserService } from '../../../services/service.users';
import { CommonUtilities } from '../../../shared/utils/commonUtilities';
import { AppStorageHelper } from '../../../shared/utils/appStorageHelper';
import AlertMessage from '../../../Component/alert/AlertMessage';
import { StyledText } from '../../../Component/StyledText';
import { CommonConstants } from '../../../constants/app.constants';

const DELAY_AFTER_PROFILE_UPDATE = 10000; // 10 seconds

/**
 * user signup component
 */
class SignUp extends BaseScreen {
  constructor (props) {
    super(props);
    this.state = {
      isWaitingApiResponse: false,
      formFields: {
        email: '',
        firstName: '',
        lastName: '',
        newPassword: '',
        confirmPassword: '',
        roleId: '',
        phone: ''
      },
      formErrors: {
        email: '',
        firstName: '',
        lastName: '',
        newPassword: '',
        confirmPassword: '',
        roleId: '',
        phone: ''
      },
      formValid: false,
      alertMessage: {
        message: '',
        type: ''
      },

      newPasswordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      disableAllApiHandlers: false
    };

    this.profileUpdatedTimer = null;
    this.signUpRoleTypes = CommonConstants.getSignUpRoleTypes();
  }

  /**
   * cleanup resources
   */
  componentWillUnmount () {
    clearTimeout(this.profileUpdatedTimer);
  }

  /**
   * redirect to login page
   */
  goToLogin () {
    AppStorageHelper.clearApplicationData();
    this.goToScreen(SCREENS.login);
  }

  /**
   * validate form for valid data
   * @param {*} event 
   */
  handleUserInput (event) {
    event.preventDefault();

    const name = event.target.name;
    const value = _.trim(event.target.value);

    const formFields = this.state.formFields;
    formFields[name] = value;

    const fieldValidationErrors = { ...this.state.formErrors };
    let msg = '';

    const pwd = formFields.newPassword;
    const cnfPwd = formFields.confirmPassword;

    switch (name) {
      case 'email':
        if (value.length === 0) {
          msg = i18n.t('noauth.alert.required');
        } else {
          msg = CommonUtilities.isValidEmail(value) ? '' : i18n.t('noauth.alert.emailInvalid');
        }

        fieldValidationErrors.email = msg;
        break;

      case 'firstName':
      case 'lastName':
        if (value.length === 0) {
          msg = i18n.t('noauth.alert.required');
        } else if (value.length > 32) {
          msg = i18n.t('noauth.alert.char32');
        } else if (!CommonUtilities.isValidName(value)) {
          msg = i18n.t('noauth.alert.alphabate');
        }
        fieldValidationErrors[name] = msg;
        break;

      case 'phone':
        if (value.length === 0) {
          msg = i18n.t('noauth.alert.required');
        } else if (value.length < 7 || value.length > 15) {
          msg = i18n.t('noauth.alert.phone');
        } else if (!(value.match(/^(?:[0-9] ?){6,14}[0-9]$/i))) {
          msg = i18n.t('noauth.alert.integer');
        }

        fieldValidationErrors.phone = msg;
        break;

      case 'roleId':
        if (value.length === 0) {
          msg = i18n.t('noauth.alert.required');
        }

        fieldValidationErrors.roleId = msg;
        break;

      case 'newPassword':
      case 'confirmPassword':
        if (value.length === 0) {
          msg = i18n.t('noauth.alert.required');
        } else if (value.length < 8) {
          msg = i18n.t('noauth.alert.passwordMin');
        } else if (name === 'newPassword' && cnfPwd) {
          // handle password mismatch
          if (cnfPwd !== value) {
            msg = i18n.t('noauth.alert.passConfirmPassNoMatch');
          } else {
            fieldValidationErrors.confirmPassword = '';
          }
        } else if (name === 'confirmPassword' && pwd) {
          // handle password mismatch
          if (pwd !== value) {
            msg = i18n.t('noauth.alert.passConfirmPassNoMatch');
          } else {
            fieldValidationErrors.newPassword = '';
          }
        }

        fieldValidationErrors[name] = msg;
        break;

      default:
        break;
    }

    const isFormValid = (formFields.email && formFields.firstName && formFields.lastName &&
      formFields.newPassword && formFields.confirmPassword && formFields.roleId && formFields.phone) &&
      (fieldValidationErrors.email === '' && fieldValidationErrors.firstName === '' && fieldValidationErrors.lastName === '' &&
        fieldValidationErrors.newPassword === '' && fieldValidationErrors.confirmPassword === '' && fieldValidationErrors.roleId === '' &&
        fieldValidationErrors.phone === '');

    this.setState({
      formFields: formFields,
      formErrors: fieldValidationErrors,
      formValid: isFormValid
    });
  }

  /**
   * check if form is valid
   */
  getFormValidity () {
    let valid = false;
    if (this.state.formValid) {
      valid = true;
    }

    return valid && !this.state.disableAllApiHandlers;
  }

  /**
   * handles form submit functionality.
   */
  submitHandler () {
    this.setState({ isWaitingApiResponse: true });

    UserService.signUp(_.cloneDeep(this.state.formFields)).then(
      response => {
        this.setState({
          isWaitingApiResponse: false,
          disableAllApiHandlers: true,
          alertMessage: {
            message: i18n.t('noauth.alert.confirmationCodeSent'),
            type: 'success'
          }
        });

        /**
         * go back to login screen
         */
        this.profileUpdatedTimer = setTimeout(() => this.goToLogin(), DELAY_AFTER_PROFILE_UPDATE);
      },
      error => {
        let alertMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          alertMsg = error.data.message;
        }
        this.setState({
          isWaitingApiResponse: false,
          alertMessage: {
            message: alertMsg,
            type: 'danger'
          }
        });
      }
    );
  }

  /*
   alert message handling
   */
  handleDismiss () {
    this.setState({
      alertMessage: {
        type: null,
        message: null
      }
    });
  }

  /**
   * toggle view from plain text to encrypted
   * @param {*} fieldName 
   * @param {*} event 
   */
  toggleFieldType (fieldName, event) {
    event.preventDefault();
    if (fieldName === 'newPassword') {
      this.setState({ newPasswordFieldType: this.state.newPasswordFieldType === 'password' ? 'text' : 'password' });
    } else if (fieldName === 'confirmPassword') {
      this.setState({ confirmPasswordFieldType: this.state.confirmPasswordFieldType === 'password' ? 'text' : 'password' });
    }
  }

  /**
   * react render function to show signup form
   */
  render () {
    return (
      <LoadingOverlay active={this.state.isWaitingApiResponse} customClass="full_page_loading_overlay">
        <div className="container">
          <br />
          <h3>{i18n.t('noauth.form.label.signUpLabel')}</h3>
          <br />
          {
            this.state.alertMessage.message &&
            <AlertMessage message={this.state.alertMessage.message}
              type={this.state.alertMessage.type}
              isAlertOpen={!!(this.state.alertMessage.message)}
              handleDismiss={this.handleDismiss.bind(this)} 
              testId="signup"
            />
          }
          <form autoComplete="off">
            <div className="row">

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email">{i18n.t('noauth.form.label.emailId')} <StyledText uiText="*"/></label>
                  <input type="text" required className="form-control" name="email"
                    placeholder={i18n.t('noauth.form.placeholder.emailId')}
                    value={this.state.formFields.email}
                    onChange={this.handleUserInput.bind(this)} maxLength="320" 
                    data-testid="signup-input-email" />
                  <FormErrors formErrors={this.state.formErrors.email} testId="signup-email"/>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="newPassword">{i18n.t('noauth.form.label.password')} <StyledText uiText="*"/></label>
                  <div className="input-group">
                    <input type={this.state.newPasswordFieldType} required className="form-control"
                      name="newPassword"
                      placeholder={i18n.t('noauth.form.placeholder.password')}
                      autoComplete="new-password"
                      value={this.state.formFields.newPassword}
                      onChange={this.handleUserInput.bind(this)} minLength="8" 
                      data-testid="signup-input-newpwd"/>
                    <button onClick={this.toggleFieldType.bind(this, 'newPassword')}>
                      <i className={'fa ' + (this.state.newPasswordFieldType === 'password' ? 'fa-eye-slash' : 'fa-eye')}></i>
                    </button>
                  </div>
                  <FormErrors formErrors={this.state.formErrors.newPassword} />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName">{i18n.t('noauth.form.label.firstName')} <StyledText uiText="*"/></label>
                  <input type="text" required className="form-control" name="firstName"
                    placeholder={i18n.t('noauth.form.placeholder.firstName')}
                    value={this.state.formFields.firstName}
                    onChange={this.handleUserInput.bind(this)} maxLength="32" 
                    data-testid="signup-input-fname"/>
                  <FormErrors formErrors={this.state.formErrors.firstName} />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="confirmPassword">{i18n.t('noauth.form.label.confirmPassword')} <StyledText uiText="*"/></label>
                  <div className="input-group">
                    <input type={this.state.confirmPasswordFieldType} required className="form-control"
                      name="confirmPassword"
                      placeholder={i18n.t('noauth.form.placeholder.confirmPassword')}
                      value={this.state.formFields.confirmPassword}
                      onChange={this.handleUserInput.bind(this)} minLength="8" 
                      data-testid="signup-input-confirmpwd" />
                    <button onClick={this.toggleFieldType.bind(this, 'confirmPassword')}>
                      <i className={'fa ' + (this.state.confirmPasswordFieldType === 'password' ? 'fa-eye-slash' : 'fa-eye')}></i>
                    </button>
                  </div>
                  <FormErrors formErrors={this.state.formErrors.confirmPassword} testId="signup-confirmpwd"/>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="lastName">{i18n.t('noauth.form.label.lastName')} <StyledText uiText="*"/></label>
                  <input type="text" required className="form-control" name="lastName"
                    placeholder={i18n.t('noauth.form.placeholder.lastName')}
                    value={this.state.formFields.lastName}
                    onChange={this.handleUserInput.bind(this)} maxLength="32" 
                    data-testid="signup-input-lname"/>
                  <FormErrors formErrors={this.state.formErrors.lastName} />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="roleId">{i18n.t('noauth.form.label.selectUserRole')} <StyledText uiText="*"/></label>
                  <select className="form-control" id="roleId" name="roleId"
                    onChange={this.handleUserInput.bind(this)} value={this.state.formFields.roleId}>
                    <option key="placeHolderRoleSelection" value="">{i18n.t('noauth.form.placeholder.selectUserRole')}</option>
                    {
                      this.signUpRoleTypes.map((elem, id) => {
                        return <option key={elem.value + id} value={elem.value}>{elem.displayName}</option>;
                      })
                    }
                  </select>
                  <FormErrors formErrors={this.state.formErrors.roleId} />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="phone">{i18n.t('noauth.form.label.phone')} <StyledText uiText="*"/></label>
                  <input type="text" required className="form-control" name="phone"
                    placeholder={i18n.t('noauth.form.placeholder.phone')}
                    value={this.state.formFields.phone}
                    onChange={this.handleUserInput.bind(this)} maxLength="15" 
                    data-testid="signup-input-phone"/>
                  <FormErrors formErrors={this.state.formErrors.phone} testId="signup-phone"/>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="phone">{i18n.t('noauth.alert.accountHolderDesc')}</label>
                  <label htmlFor="phone">{i18n.t('noauth.alert.operatorDesc')}</label>
                </div>
              </div>

              <div className="col-md-12 text-right">
                <button type="button" className="btn btn-primary"
                  disabled={!this.getFormValidity()}
                  onClick={this.submitHandler.bind(this)} 
                  data-testid="signup-btn">
                  {i18n.t('noauth.form.button.signUp')}
                </button>
              </div>

            </div>
          </form>

          <div className="mt-3 fgt-pass anchor-override">
            <a href="#/login" data-testid="login-link">
              {i18n.t('noauth.form.button.login')}
            </a>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default SignUp;
