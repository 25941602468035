import React from "react";
import i18n from 'i18next';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import AlertMessage from '../../../Component/alert/AlertMessage';
import { CropsService } from '../../../services/service.crop';
import { FormErrors } from '../../../Component/SubmissionStatus';
import { StyledText } from '../../../Component/StyledText';
import { CommonConstants } from "../../../constants/app.constants";
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';

/**
 * create Crops components. holds all functionalities for creating a Crops.
 */
class CreateCrops extends BaseScreen {
    constructor(props) {
        super(props);
        let isAuthenticated = true;
        if (!this.isAuth()) {
            isAuthenticated = false;
            this.goToScreen(SCREENS.login);
        }
        this.state = {
            isAuthenticated: isAuthenticated,
            formfields: {
                name: "",
                active: "-1",
                localId: ""
            },
            formErrors: {
                name: "",
                localId: ""
            },
            formValid: false,
            isLoading: false,
            alert: {
                type: null,
                message: null
            }
        }
        this.booleanTypes = CommonConstants.getBooleanTypes();
    }

    /**
   * handles crop submit function. this calls api to create a crop.
   * @param {*} event 
   */
    handleSubmit = (event) => {
        event.preventDefault();

        // set loader on
        this.setState({
            isLoading: true,
        });

        CropsService.createCrop({...this.state.formfields}, ((this.props.location.params && this.props.location.params.accountHolder)
            ?
            this.props.location.params.accountHolder[0].id
            :
            null)).then(
                (theResponse) => {
                    let message = i18n.t('crops.alert.cropCreate');
                    this.setState({
                        isLoading: false,
                        alert: {
                            type: "success",
                            message: message
                        }
                    });
                },
                (error) => {
                    let errorMsg = i18n.t('common.genericApiError');
                    if (error && error.data && error.data.message) {
                        errorMsg = error.data.message;
                    }
                    this.setState(
                        {
                            isLoading: false,
                            alert: {
                                type: "danger",
                                message: errorMsg,
                            }
                        });
                }
            );
    }

    /**
     * helper function to validate decimal values entered.
     * @param {*} num 
     */
    validateDecimalNumbers(num) {
        if ((num.length > 0) &&
            ((isNaN(num) || (num.indexOf(".") === 0) || (num.indexOf(".") === num.length - 1)) ||
                !(/^\d+(\.\d{1,6})?$/.test(num)))) {
            return false;
        }
        return true;
    }

    /**
   * handler to validate form inputes
   */
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formfields = this.state.formfields;
        formfields[name] = value;

        let fieldValidationErrors = this.state.formErrors;
        let msg = '';

        switch (name) {
            case 'name':
                if (value.length === 0) {
                    msg = i18n.t('crops.alert.required');
                } else if (value.length > 64) {
                    msg = i18n.t('crops.alert.char64');
                }
                break;

            case 'localId':
                if (value.length > 256) {
                    msg = i18n.t('crops.alert.char256');
                }
                break;
            default:
                break;
        }

        fieldValidationErrors[name] = msg;

        let isFormValid = (formfields.name) &&
            (fieldValidationErrors.name === '' &&
                fieldValidationErrors.localId === '');

        this.setState({
            formfields: formfields,
            formErrors: fieldValidationErrors,
            formValid: isFormValid,
        });
    }

    /**
     * reset form handler
     */
    onResetForm = () => {
        this.setState({
            formfields: {
                name: '',
                localId: '',
                active: '-1'
            },
            formErrors: {
                name: '',
                localId: ''
            },
            formValid: false
        });
    }

    /*
   alert message handling
   */
    handleAlertDismiss() {
        this.setState({
            alert: {
                type: null,
                message: null
            }
        });
    }

    /**
     * create crop form
     */
    renderCreateCropForm() {
        return (
            <LoadingOverlay active={this.state.isLoading}>
                <form className="createCropForm" id="createCropForm">
                    <h2 data-testid="create-crop-heading">
                        {i18n.t('crops.form.label.createCrop')}
                    </h2>
                    <div className="view-profile-details width-70per">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label htmlFor="name" data-testid="create-crop-label-name">
                                        {i18n.t('crops.form.label.cropName')} <StyledText uiText="*" />
                                    </label>
                                    <input type="text" required className="form-control" name="name"
                                        placeholder={i18n.t('crops.form.placeholder.cropName')}
                                        value={this.state.formfields.name}
                                        onChange={this.handleUserInput} maxLength="64"
                                        data-testid="create-crop-input-name" />
                                    <FormErrors formErrors={this.state.formErrors.name} />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label htmlFor="localId">{i18n.t('crops.form.label.localId')}</label>
                                    <input type="text" className="form-control" name="localId"
                                        value={this.state.formfields.localId}
                                        placeholder={i18n.t('crops.form.placeholder.localId')}
                                        onChange={this.handleUserInput} maxLength="256"
                                        data-testid="create-crop-input-local-id" />
                                    <FormErrors formErrors={this.state.formErrors.localId} />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label htmlFor="active">{i18n.t('crops.form.label.active')}</label>
                                    <select className="form-control" id="active" name="active"
                                        onChange={this.handleUserInput} data-testid="create-crop-input-active" value={this.state.formfields.active}>
                                        <option key="placeHolderactive" value='-1'>{i18n.t('crops.form.placeholder.active')}</option>
                                        {
                                            this.booleanTypes.map((elem, id) => {
                                                return <option key={elem.value + id} value={elem.value}>{elem.displayName}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 text-right">
                        <div>
                            <button type="button" className="btn btn-secondary mr-2"
                                onClick={this.onResetForm}
                                data-testid="create-crop-btn-reset">
                                {i18n.t('crops.form.button.reset')}
                            </button>
                            <button type="submit" className="btn btn-primary"
                                disabled={!this.state.formValid}
                                onClick={this.handleSubmit}
                                data-testid="create-crop-btn-save">
                                {i18n.t('crops.form.button.save')}
                            </button>
                        </div>
                    </div>
                </form>
            </LoadingOverlay>
        )
    }
    /**
     * react render to render create form
     */
    render() {
        const breadcrumb = [
            { id: 'home', displayName: i18n.t('crops.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'crop', displayName: i18n.t('crops.breadcrumb.crop'), className: '', link: false },
            { id: 'create', displayName: i18n.t('crops.breadcrumb.create'), className: '', link: false }
        ];
        return (
            <div className="container-fluid p-4">
                <BreadcrumbCustom breadcrumb={breadcrumb} />
                <div className="clearfix"></div>
                {
                    this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                        type={this.state.alert.type}
                        isAlertOpen={!!(this.state.alert.message)}
                        handleDismiss={this.handleAlertDismiss.bind(this)}
                        testId="create-crop"
                    />
                }
                {
                    this.renderCreateCropForm()
                }
            </div>
        );
    }
}

export default CreateCrops;
