import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import AlertMessage from '../../../Component/alert/AlertMessage';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { UserService } from '../../../services/service.users';
import { FormGroup, Input } from 'reactstrap';
import ListAssociatedAssignments from './ListAssociatedAssignments';
import { ROLE_TYPES } from '../../../constants/app.constants';
import { updateSelectedAccHolder } from '../../../redux/actions/action.accholderinfo';
import i18n from 'i18next';

/**
 * account holder association component
 */
class MyAccountHolders extends BaseScreen {
  constructor (props) {
    super(props);

    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    this.state = {
      isAuthenticated: isAuthenticated,
      selectedAccHolderID: '',
      accountHolders: [],
      selectedButton: 'default',
      alert: {
        type: null,
        message: null
      }
    };
  }

  /**
   * handles error
   * @param {*} error 
   */
  handleApiError (error) {
    let errorMsg = i18n.t('common.genericApiError');
    if (error && error.data && error.data.message) {
      errorMsg = error.data.message;
    }
    this.setState({
      alert: {
        type: 'danger',
        message: errorMsg
      }
    });
  }

  /**
   * handler for selection of account holder
   * update accounHolderInfo in redux store
   * @param {*} e 
   */
  onAccountHolderSelection (e) {
    const selectedUserId = e.target.value;
    this.setState({ selectedAccHolderID: selectedUserId });

    const accUserInfo = this.state.accountHolders.find(function (arg) {
      return arg.email === selectedUserId;
    });

    this.props.updateSelectedAccHolder(accUserInfo);
  }

  componentDidMount () {
    const self = this;
    if (this.state.isAuthenticated) {
      UserService.getAssociatedAccountHolders().then(function (success) {
        self.setState({ accountHolders: success.data });
      }, function (error) {
        self.handleApiError(error);
      });
    }
  }

  /**
   * handler for changing account holder type from default to associated account holder and vice versa.
   * @param {*} accountTypeSelection 
   */
  onAccountTypeChange (accountTypeSelection) {
    const currentButtonType = this.state.selectedButton;
    const self = this;
    if (currentButtonType !== accountTypeSelection) {
      this.setState({
        selectedButton: accountTypeSelection
      });
      if (accountTypeSelection === 'default') {
        UserService.getAssociatedAccountHolders().then(function (success) {
          self.setState({ accountHolders: success.data });
        }, function (error) {
          this.handleApiError(error);
        });
      }
    }
  }

  /**
   * to render default non-associated account holders inside OEM
   */
  renderDefaultAccountHolders () {
    return (
      <div>
        <h2> {i18n.t('users.selectAccountholder')} </h2>
        <FormGroup className="account-holder-form" >
          <Input type="select"
            id="accHolderSelect"
            name="accHolderSelect"
            onChange={(e) => { this.onAccountHolderSelection(e); }}
            value={this.state.selectedAccHolderID}>
            <option key="placeHolderSelection" value="">{i18n.t('users.form.label.selectAccountholder')}</option>
            {
              this.state.accountHolders.map((elem, id) => {
                return (
                  <option key={elem.roleId + id} value={elem.email} className="custom-select-dropdown-option">
                    {elem.email}
                  </option>
                );
              })
            }
          </Input>
        </FormGroup>
      </div>
    );
  }

  /**
   * render list of associated account holders
   * @param {*} loggedInUserInfo 
   * @param {*} isOperator 
   */
  renderMyAccountHolders (loggedInUserInfo, isOperator) {
    return (
      <div>
        <br/>
        <ListAssociatedAssignments userId={loggedInUserInfo.id} isOperator={isOperator} userEmail={loggedInUserInfo.email} />
      </div>
    );
  }

  /**
   * handler to dismiss alert messages
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * to render associated account holders.
   */
  renderAccountHolders () {
    let result = null;
    const loggedInUserInfo = this.props.userInfo;

    if (_.isObject(loggedInUserInfo) && _.has(loggedInUserInfo, 'id')) {
      const isOperator = (loggedInUserInfo.roleId === ROLE_TYPES.OPERATOR.ID);
      if (isOperator) {
        const isDefaultSelected = this.state.selectedButton === 'default';
        const isMyAccountSelected = this.state.selectedButton === 'myAccount';
        result = (
          <React.Fragment>
            <div className="account-holder-btn-mob">
              <button className={'btn mr-2 ' + (isDefaultSelected ? 'btn-primary-outline' : 'btn-secondary-outline')}
                onClick={this.onAccountTypeChange.bind(this, 'default')}>
                {i18n.t('users.form.button.selectDefault')}
              </button>
              <button className={'btn mr-2 ' + (isMyAccountSelected ? 'btn-primary-outline' : 'btn-secondary-outline')}
                onClick={this.onAccountTypeChange.bind(this, 'myAccount')}>
                {i18n.t('users.form.button.accountHolders')}
              </button>
            </div>

            {isDefaultSelected ? this.renderDefaultAccountHolders() : null}
            {isMyAccountSelected ? this.renderMyAccountHolders(loggedInUserInfo, isOperator) : null}

          </React.Fragment>
        );
      }
    }

    return result;
  }

  /**
   * react render function
   */
  render () {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('users.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'accountHolders', displayName: i18n.t('users.breadcrumb.accountHolders'), className: '', link: false }
    ];

    return (
      <div className="card my-4-custom">
        <div className="container-fluid p-4">
          <BreadcrumbCustom breadcrumb={breadcrumb} />
          <div className="clearfix"></div>
          {
            this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)}
            />
          }

          {
            this.renderAccountHolders()
          }

        </div>
      </div>
    );
  }
}

function mapStateToProps ({ user }) {
  return {
    userInfo: user.userInfo
  };
}

const mapDispatchToProps = {
  updateSelectedAccHolder
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountHolders);
