import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { APP_BUILD_VERSION } from "../constants/app.constants";

/**
 * component for actions of logged in user.
 */
export class LoggedInUserActions extends React.Component {
  /**
   * Component initialization
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Logout event handler
   * @param {object} event
   */
  handleLogout = (event) => {
    if (this.props.logoutHandler) {
      this.props.logoutHandler(event);
    }
  }

  /**
   * render the component view
   */
  render() {
    let userIconSrc = process.env.PUBLIC_URL + '/assets/images/user_icon.png?preventCaching=' + APP_BUILD_VERSION;

    return (
      <UncontrolledDropdown direction="left">
        <DropdownToggle color="primary" data-testid="user-icon-btn">
          <img src={userIconSrc} className="user_logo" alt="" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag="span" className="btn" >
            <Link to={{
              pathname: '/home/view-profile/' + this.props.userSubId
            }} data-testid="view-profile-link">
              {i18n.t('home.userProfile.button.viewProfile')}
            </Link>
          </DropdownItem>
          <DropdownItem tag="span" className="btn">
            <Link to={{
              pathname: '/home/change-password/' + this.props.userSubId
            }} data-testid="change-password-link">
              {i18n.t('home.userProfile.button.changePass')}
            </Link>
          </DropdownItem>
          <DropdownItem tag="button" className="btn" 
            onClick={this.handleLogout} 
            data-testid="logout-btn">
            {i18n.t('home.userProfile.button.logOut')}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}
