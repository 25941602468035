import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BaseScreen from '../../BaseScreen';
import { SCREENS } from '../../../../constants/screens.constant';
import ListDevices from './ListDevices';
import ActivateDevice from './ActivateDevice';

/**
 * Device home component, contains route for other device components.
 */
class Devices extends BaseScreen {
  constructor (props) {
    super(props);

    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }
  }

  render () {
    return (
      <Switch>
        <Route exact path="/user-vehicles/devices/list-devices" component={ListDevices} />
        <Route exact path="/user-vehicles/devices/activate-device" component={ActivateDevice} />
        <Redirect to="/user-vehicles/devices/list-devices" />
      </Switch>
    );
  }
}

export default Devices;
