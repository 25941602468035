import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import ListTasks from './ListTasks';
import ViewTask from './ViewTask';

/**
 * Home component for task module. contains route for different task components.
 */
class Tasks extends BaseScreen {
  constructor (props) {
    super(props);
    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }
  }

  render () {
    return (
      <div>
        <div className="card my-4-custom">
          <Switch>
            <Route exact path={'/tasks/list-tasks'} component={ListTasks} />
            <Route exact path={'/tasks/view-tasks/:taskId'} component={ViewTask} />
            <Redirect to="/tasks/list-tasks" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Tasks;
