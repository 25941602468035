import React from 'react';
import _ from 'lodash';
import i18n from 'i18next';
import { Link } from "react-router-dom";
import BaseScreen from "../Containers/Screens/BaseScreen";

/**
 * Constructs left and profile menu component.
 */
export class LeftMenu extends BaseScreen {
  /**
   * Component initialization
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * handle menu collapse/expand event.
   * @param {string} arg
   * @param {object} event
   */
  handleCollapseOrExpand(arg, event) {
    event.preventDefault();

    try {
      // dom reference for collapsible "tab-content" wrapper (which was clicked)
      let collapsibleWrapper = event.target.nextElementSibling;

      // dom reference for "tab-content" container
      let collapsibleContainer = event.target.closest(".tabs"); // "tabs"

      let childTabContents = collapsibleContainer.querySelectorAll(".tab-content");

      for (let i = 0; i < childTabContents.length; i++) {
        let elem = childTabContents[i];
        if (collapsibleWrapper !== elem) {
          elem.classList.remove("tab-content-expanded");
        }
      }

      collapsibleWrapper.classList.toggle("tab-content-expanded");
    } catch (e) {
      //
    }
  }

  /**
   * Render navigation menu.
   * @param {object} item
   * @return {object}
   */
  renderMenus = (item) => {
    let urlPath = this.props.routerData.location.pathname;
    let isActive = _.isString(urlPath) ? urlPath.startsWith(item.url) : false;
    let dataTestIdSmallScreenSuffix = this.props.isMobile ? "-small-screen" : ""; 

    if (item.subMenu) {
      // render submenu links
      return (
        <div className="tab">
          <button className={"btn tab-label left-main-menu-item " + item.icon} onClick={this.handleCollapseOrExpand.bind(this, item.key)}>
            {this.getLocalizedString(item.key, item.name)}
          </button>
          <div className="tab-content">
            {
              item.subMenu.map((menu, idx) => {
                return (
                  <span key={item.name + idx} className="sub-menu">
                    <Link to={menu.screenPath} onClick={this.handleNavLinkClick.bind(this, menu.name)} 
                      className="left-menu-override">
                      {this.getLocalizedString(menu.key, menu.name)}
                    </Link>
                  </span>
                );
              })
            }
          </div>
        </div>
      );
    } else {
      // if menuItem is an external url
      if (item.isExternalUrl) {
        return (
          <div className="tab">
            <a data-testid={"menu-" + item.key + dataTestIdSmallScreenSuffix} href={item.url} 
                target="_blank" rel="noopener noreferrer"
                className={"left-menu-override left-main-menu-item " + item.icon}
                onClick={this.handleNavLinkClick.bind(this, item.name)}>
              {this.getLocalizedString(item.key, item.name)}
            </a>
          </div>
        );
      } else {
        // render simple menu items
        return (
          <div className="tab">
            <Link data-testid={"menu-" + item.key + dataTestIdSmallScreenSuffix} 
              to={item.screenPath} 
              className={"left-menu-override left-main-menu-item " + item.icon + (isActive ? " activeLeftMenu" : "")}
              onClick={this.handleNavLinkClick.bind(this, item.name)}>
              {this.getLocalizedString(item.key, item.name)}
            </Link>
          </div>
        );
      }
    }
  }

  /**
   * call back for parent component, when any link is clicked
   * @param {string} arg
   * @param {object} event
   */
  handleNavLinkClick(arg, event) {
    if (this.props.onSidebarNavLinkClick) {
      this.props.onSidebarNavLinkClick(arg, event);
    }
  }

  /**
   * Render user profile menu in small screens
   */
  renderProfileMenuItems() {
    return (
      <div className="tab">
        <button className={"btn tab-label left-main-menu-item "} onClick={this.handleCollapseOrExpand.bind(this, "user_info")}>
          {i18n.t('home.userProfile.button.userInfo')}
        </button>
        <div className="tab-content">
          <span className="sub-menu">
            <Link to={"/home/view-profile/" + this.props.userSubId} 
              onClick={this.handleNavLinkClick.bind(this, "view_profile")} 
              className="left-menu-override">
              {i18n.t('home.userProfile.button.viewProfile')}
            </Link>
          </span>
          <span className="sub-menu">
            <Link to={"/home/change-password/" + this.props.userSubId} 
              onClick={this.handleNavLinkClick.bind(this, "change_password")} 
              className="left-menu-override">
              {i18n.t('home.userProfile.button.changePass')}
            </Link>
          </span>
          <span className="sub-menu">
            <button className="btn left-menu-override" onClick={this.handleLogout}>
              {i18n.t('home.userProfile.button.logOut')}
            </button>
          </span>
        </div>
      </div>
    );
  }

  /**
   * handle logout event
   * @param {object} event
   */
  handleLogout = (event) => {
    if (this.props.logoutHandler) {
      this.props.logoutHandler(event);
    }
  }

  /**
   * MenuItems are loaded from the json file. 
   * Get Localized string for menu items based on their key.
   * @param {string} menuItemKey
   * @param {string} defaultValue
   * @return {string}
   */
  getLocalizedString = (menuItemKey, defaultValue) => {
    let localizedStr;

    switch (menuItemKey) {
      case 'myOperators':
        localizedStr = i18n.t('menuItems.myOperators.label');
        break;

      case 'myAccountHolders':
        localizedStr = i18n.t('menuItems.myAccountHolders.label');
        break;

      case 'myVehicles':
        localizedStr = i18n.t('menuItems.myVehicles.label');
        break;

      case 'myTasks':
        localizedStr = i18n.t('menuItems.myTasks.label');
        break;

      case 'calibrations':
        localizedStr = i18n.t('menuItems.calibrations.label');
        break;
      
      case 'myCrops':
        localizedStr = i18n.t('menuItems.crops.label');
        break;
      
      case 'myOperations':
        localizedStr = i18n.t('menuItems.operations.label');
        break;

      case 'support':
        localizedStr = i18n.t('menuItems.support.label');
        break;

      default:
        break;
    }

    return localizedStr ? localizedStr : defaultValue;
  }

  /**
   * render the component view
   */
  render() {
    return (
      <div className={"left-column " + (this.props.isMobile ? "mobile" : "")}>
        <div className="row">
          <div className="col icon-menu">
            <div className="tabs">
              {
                this.props.leftMenuItems.map((item, idx) => {
                  return (
                    <React.Fragment key={item.name + idx}>
                      {this.renderMenus(item)}
                    </React.Fragment>
                  );
                })
              }

              {
                this.props.addUserInfo ? (
                  this.renderProfileMenuItems()
                )
                  :
                  null
              }

            </div>
          </div>
        </div>
      </div>
    );
  }
}
