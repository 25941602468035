import { API_INTERFACE } from '../constants/uri.constant';
import baseService from './services';
import { AuthUtils } from './service.auth.utils';

/**
 * get tasks list from backend
 * @param {*} queryString
 */
function getTasks (queryString) {
  const url = API_INTERFACE.GET_TASKS + '?' + queryString;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.get(url, header);
}

/**
 * get activities
 * @param {*} taskId 
 * @param {*} queryString 
 */
function getTaskActivities (taskId, queryString) {
  const url = API_INTERFACE.GET_TASKS + '/' + taskId + '/activities?' + queryString;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.get(url, header);
}

/**
 * get task by tskId
 * @param {*} taskId 
 */
function getTask (taskId) {
  const url = API_INTERFACE.GET_TASK + '/' + taskId;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.get(url, header);
}

export const TaskService = {
  getTasks,
  getTaskActivities,
  getTask
};
