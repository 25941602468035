import React from 'react';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { APP_BUILD_VERSION } from "../../../constants/app.constants";

/**
 * Home view component
 */
class HomeView extends BaseScreen {
  constructor (props) {
    super(props);
    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }
  }

  render () {
    let homeBgImgSrc = process.env.PUBLIC_URL + '/assets/images/banner-home.jpg?preventCaching=' + APP_BUILD_VERSION;
    return (
      <div className="my-4-custom home-container">
        <div className="home-banner">
          <img src={homeBgImgSrc} alt=""/>
        </div>
      </div>
    );
  }
}

export default HomeView;
