import React from 'react';
import { Link } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import AlertMessage from '../../../Component/alert/AlertMessage';
import { CropsService } from '../../../services/service.crop';
import i18n from 'i18next';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';

/**
 * view Crop components. holds all functionalities for viewing an Crop.
 */
class ViewCrop extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    this.state = {
      isAuthenticated: isAuthenticated,
      cropId: this.props.match.params.id,
      isLoading: true,
      cropData: '',
      isErrorOnLoad: false,
      apiResponseMsg: '',
      alert: {
        type: null,
        message: null
      }
    };
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      this.displayCropInfo();
    }
  }

  /**
   *  fetch data from backend to display on ui.
   */
  displayCropInfo () {
    const cropId = this.state.cropId;
    CropsService.getCrop(cropId).then(response => {
      this.setState({
        cropData: response.data,
        isLoading: false
      });
    }, error => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }

      this.setState({
        cropData: {},
        isLoading: false,
        isErrorOnLoad: true,
        apiResponseMsg: errorMsg,
        alert: {
          type: 'danger',
          message: errorMsg
        }
      });
    });
  }

  /**
   *  navigate to previous page
   */
  handleBack () {
    this.props.history.goBack();
  }

  /**
   * view Crop form
   */
  renderCropDetails () {
    return (
      <>
        <h2>{i18n.t('crops.form.label.viewCrop')}</h2>
        <div className="view-profile-details width-70per">
          <div className="row">
            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('crops.form.label.cropName')}</label>
              <p>{this.state.cropData.name}</p>
            </div>
            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('crops.form.label.active')}</label>
              <p>{this.state.cropData.active ? 'True' : 'False' }</p>
            </div>
            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('crops.form.label.localId')}</label>
              <p>
                {
                  this.state.cropData.localId
                    ? this.state.cropData.localId
                    : i18n.t('crops.form.label.notAvailable')
                }
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-12 text-right f-btn">
          <Link className="btn btn-primary" to={{
            pathname: '/crops/edit-crops/' + this.state.cropId
          }}>
            {i18n.t('crops.form.button.edit')}
          </Link>
        </div>
      </>
    );
  }

  /**
   * renders errors
   */
  renderError () {
    return (
      <div className="col-md-12 text-right f-btn">
        <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>
          {i18n.t('crops.form.button.back')}
        </button>
      </div>
    );
  }

  /**
   * dissmiss alert message
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * render view crop form
   */
  render () {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('crops.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'crop', displayName: i18n.t('crops.breadcrumb.crop'), className: '', link: false },
      { id: 'detail', displayName: i18n.t('crops.breadcrumb.detail'), className: '', link: false }
    ];
    return (
      <div className="container-fluid p-4">
        <BreadcrumbCustom breadcrumb={breadcrumb} />
        <div className="clearfix"></div>
        {
          this.state.alert.message &&
          <AlertMessage message={this.state.alert.message}
            type={this.state.alert.type}
            isAlertOpen={!!(this.state.alert.message)}
            handleDismiss={this.handleAlertDismiss.bind(this)}
          />
        }
        <LoadingOverlay active={this.state.isLoading}>
          {
            this.state.isErrorOnLoad ? this.renderError() : this.renderCropDetails()
          }
        </LoadingOverlay>
      </div>
    );
  }
}

export default ViewCrop;
