import React from 'react';
import Grid from '../../../../Component/grid/Grid';
import AlertMessage from '../../../../Component/alert/AlertMessage';
import BaseScreen from '../../BaseScreen';
import { SCREENS } from '../../../../constants/screens.constant';
import { SubscriptionService} from '../../../../services/service.subscriptionscodes';
import AppInfoHelper from '../../../../shared/utils/appInfoHelper';
import { ROLE_TYPES } from '../../../../constants/app.constants';
import i18n from 'i18next';

/**
 * Informations to be shown in subscriptions code listing grid, along with options per rows.
 */
/**
 * subscode table data configuration - for default vehicles
 */
let codesListConfigDefault = {};

/**
 * initialize codesListConfig with updated i18n resources
 */
function setCodesListConfig() {

  codesListConfigDefault = {
    code: {
      label: i18n.t('userVehicles.codes.list.code'),
      sort: true,
      filter: false,
      display: true,
      filterMaxLength: 40
    },
    partId: {
      label: i18n.t('userVehicles.codes.list.partid'),
      sort: true,
      filter: true,
      display: true,
      filterMaxLength: 40
    },
    deviceSerialId: {
      label: i18n.t('userVehicles.codes.list.deviceserialid'),
      sort: true,
      filter: true,
      display: true,
      filterMaxLength:50
    },
    accountEmailId: {
      label: i18n.t('userVehicles.codes.list.accountholderemail'),
      sort: true,
      filter: false,
      display: true,
      filterMaxLength: 50
    },
    userEmailId: {
      label: i18n.t('userVehicles.codes.list.operatoremail'),
      sort: true,
      filter: false,
      display: true,
      filterMaxLength: 0
    },
    expiration: {
      label: i18n.t('userVehicles.codes.list.expiration'),
      sort: true,
      filter: false,
      display: true,
      filterMaxLength: 0
    },
    createdAt: {
      label: i18n.t('userVehicles.codes.list.cratedat'),
      sort: true,
      filter: false,
      display: true,
      filterMaxLength: 0
    },
    modifiedAt: {
      label: i18n.t('userVehicles.codes.list.lastmodifiedat'),
      sort: true,
      filter: false,
      display: true,
      filterMaxLength: 0
    }

   
  };
}

/**
 * List vehicle component
 */
class ListSubscriptionsCodes extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    // update vehicleListConfig
    setCodesListConfig();

    this.state = {
      isAuthenticated: isAuthenticated,
      gridListConfig: codesListConfigDefault,
      // for managing accountHolder/operator userType
      loggedInUserInfo: undefined,
      selectedAccountHolder: undefined,
      accountId: '',

      selectedVehicleType: 'assigned', // default or own vehicles
      subsCodesData: [],
      loader: true,
      pgnConfig: {
        currentPage: 1,
        numberOfPageLinks: 0,
        countPerPage: 25, // default page size
        totalCount: 0,
        pageList: [25, 50, 100]
      },
      alert: {
        type: null,
        message: null
      },
      sortFieldsConfig: {},
      filterFieldsConfig: {},
      reinitialize: false,
      userEmailValid : true,
      // default sorting
      defaultSortField: 'modifiedAt',
      defaultSortOrder: -1
    };
  }

  /**
   * on mount user role resolve and subscriptions codes fetching from backend service
   */
  componentDidMount () {
    const self = this;
    const userInfoInstance = AppInfoHelper.getUserInstance();

    userInfoInstance.getUserInfo().then(function (response) {
      const userInfo = response;
      const selectedAccountHolder = userInfoInstance.getSelectedAccountHolderForOperator();
      if (userInfo.roleId === ROLE_TYPES.OPERATOR.ID && !selectedAccountHolder) {
        const warningMsg = i18n.t('userVehicles.vehicles.alert.selectAccountholder');
        self.setState(
          {
            subsCodesData: [],
            loader: false,
            loggedInUserInfo: userInfo,
            reinitialize: false,
            alert: {
              type: 'danger',
              message: warningMsg
            }
          });
      } else {
       
        const accountHolderId = userInfo.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID
          ? userInfo.id : selectedAccountHolder.id;

        self.setState(
          {
            loader: true,
            loggedInUserInfo: userInfo,
            selectedAccountHolder: selectedAccountHolder,
            accountId: accountHolderId
          });

        const arg = {
          currentPage: 0,
          pageSize: self.state.pgnConfig.countPerPage,
          accountId:  accountHolderId
        };

        self.getSubsCodesData(arg, self.state.sortFieldsConfig, self.state.filterFieldsConfig)
          .then(response => {
            const pgnConfig = self.setPaginationConfig(self.state.pgnConfig.currentPage, self.state.pgnConfig.countPerPage, response.data.total);
            let rows = response.data.items.map((row)=>{
               row.createdAt = new Date(row.createdAt).toDateString()
               row.expiration = new Date(row.expiration).toDateString()
               row.modifiedAt = new Date(row.modifiedAt).toDateString()
               return row
            });
            
            self.setState({ subsCodesData: rows, loader: false, pgnConfig: pgnConfig,  reinitialize: false });
          }, error => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
              errorMsg = error.data.message;
            }
            self.setState(
              {
                subsCodesData: [],
                loader: false,
                reinitialize: false,
                alert: {
                  type: 'danger',
                  message: errorMsg
                }
              });
          });
      }
    }, function (error) {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }
      self.setState(
        {
          subsCodesData: [],
          loader: false,
          reinitialize: false,
          alert: {
            type: 'danger',
            message: errorMsg
          }
        });
    });
  }

  /**
   * service to get vehicles from backend service
   * @param {*} requiredParams 
   * @param {*} sortFieldsObj 
   * @param {*} filterFieldsObj 
   */
  getSubsCodesData (requiredParams, sortFieldsObj, filterFieldsObj) {
    let reqQueryParams = '';

    // build required params
    let params;
    if (requiredParams) {
      params = {
        currentPage: requiredParams.currentPage,
        pageSize: requiredParams.pageSize
      };
    } else {
      params = {
        currentPage: this.state.pgnConfig.currentPage - 1,
        pageSize: this.state.pgnConfig.countPerPage
      };
    }

    reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' + params.pageSize;

    // add sort parameters (if available)
    let sortFieldsQueryParams = '';
    if (!sortFieldsObj) {
      sortFieldsObj = this.state.sortFieldsConfig;
    }

    let sortQueryTemp = '';
    let key;
    for (key in sortFieldsObj) {
      const sortValue = sortFieldsObj[key].sortOrder;
      key =  key=="accountEmailId" ? "accountId" :(key=="userEmailId" ? "userId" : key);
      
      if (sortValue) {
        sortQueryTemp = 'sortfield=' + key + '&orderType=' + ((sortValue === 1) ? 'asc' : 'desc');
        sortFieldsQueryParams += '&' + sortQueryTemp;
      }
    }

    // add filter parameters (if available)
    let filterFielddQueryParams = '';
    if (!filterFieldsObj) {
      filterFieldsObj = this.state.filterFieldsConfig;
    }

    let filterQueryTemp = '';
    for (key in filterFieldsObj) {
      const filterValue = filterFieldsObj[key].value;
      if (filterValue) {
        filterQueryTemp = key + '=' + filterValue;
        filterFielddQueryParams += '&' + filterQueryTemp;
      }
    }

    reqQueryParams += sortFieldsQueryParams + filterFielddQueryParams;

    if (this.state.selectedVehicleType === 'assigned') {
      reqQueryParams += '&accountId=' + this.state.accountId;
    }

    return SubscriptionService.getSubscriptionsCodes(reqQueryParams)
      .then(response => {
        return response;
      }, errResponse => {
        return Promise.reject(errResponse);
      });
  }

  getDateFormate = (date)=>{

    return new Date(date).toDateString();
  }

  /**
   * helper function for pagination configuration
   * @param {*} currentPage 
   * @param {*} countPerPage 
   * @param {*} totalCount 
   */
  setPaginationConfig (currentPage, countPerPage, totalCount) {
    return {
      currentPage: currentPage,
      numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
      countPerPage: countPerPage,
      totalCount: totalCount,
      pageList: [...this.state.pgnConfig.pageList]
    };
  }

  /**
   * handler when page number change, like user move to next page or previous page from current page he is viewing
   * @param {*} pageNumber 
   */
  onPgnChange (pageNumber) {
    if (pageNumber !== this.state.pgnConfig.currentPage) {
      window.scrollTo(0, 0);
      this.setState({ loader: true, subsCodesData: [] });

      const arg = {
        currentPage: (pageNumber - 1),
        pageSize: this.state.pgnConfig.countPerPage
      };

      this.getSubsCodesData(arg).then((response) => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        let rows = response.data.items.map((row)=>{
          row.createdAt = new Date(row.createdAt).toDateString()
          row.expiration = new Date(row.expiration).toDateString()
          row.modifiedAt = new Date(row.modifiedAt).toDateString()
          return row
       });
       console.log("row---" ,rows);
        this.setState({
          subsCodesData: rows,
          loader: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          },
        });
      }, (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            subsCodesData: [],
            loader: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /**
   * handles the grid listing when there is any change per page row count, like if user chose default row count 25 to 50 or something else.
   * @param {*} event 
   */
  onPgnRowsCountChange (event) {
    window.scrollTo(0, 0);

    const perPageItems = Number(event.target.value);
    let currentPage;
    if ((this.state.pgnConfig.currentPage - 1) * perPageItems >= this.state.pgnConfig.totalCount) {
      currentPage = 1;
    } else {
      currentPage = this.state.pgnConfig.currentPage;
    }

    // re-fetch data
    this.setState({ loader: true, subsCodesData: [] });
    const arg = {
      currentPage: currentPage - 1,
      pageSize: perPageItems
    };

    this.getSubsCodesData(arg).then((response) => {
      const pgnConfig = this.setPaginationConfig(currentPage, perPageItems, response.data.total);
      let rows = response.data.items.map((row)=>{
        row.createdAt = new Date(row.createdAt).toDateString()
        row.expiration = new Date(row.expiration).toDateString()
        row.modifiedAt = new Date(row.modifiedAt).toDateString()
        return row
     });
      this.setState({
        subsCodesData: rows,
        loader: false,
        pgnConfig: pgnConfig,
        alert: {
          type: null,
          message: null
        },
      });
    }, (error) => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }
      this.setState(
        {
          subsCodesData: [],
          loader: false,
          alert: {
            type: 'danger',
            message: errorMsg
          }
        });
    });
  }

  /**
   * lazy load function when user use filter or sort functionality from grid header
   * @param {*} sortFieldsObj 
   * @param {*} filterFieldsObj 
   */
  async onLazyLoad (sortFieldsObj, filterFieldsObj) {
    this.setState({ loader: true, subsCodesData: [] });
 
    const pageNumber = 1;
    const arg = {
      currentPage: pageNumber - 1,
      pageSize: this.state.pgnConfig.countPerPage
    };

    this.getSubsCodesData(arg, sortFieldsObj, filterFieldsObj)
      .then((response) => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        let rows = response.data.items.map((row)=>{
          row.createdAt = new Date(row.createdAt).toDateString()
          row.expiration = new Date(row.expiration).toDateString()
          row.modifiedAt = new Date(row.modifiedAt).toDateString()
          return row
       });
        // sortingData
        if (!sortFieldsObj) {
          sortFieldsObj = this.state.sortFieldsConfig;
        }

        // filteringData
        if (!filterFieldsObj) {
          filterFieldsObj = this.state.filterFieldsConfig;
        }

        this.setState({
          subsCodesData: rows,
          loader: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          },
          sortFieldsConfig: sortFieldsObj,
          filterFieldsConfig: filterFieldsObj
        });
      }, (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            subsCodesData: [],
            loader: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
  }


 
  /*
   alert message handling
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

 

  /**
   * react render
   */
  render () {
    return (
      <div>
        <div className="container-fluid p-4 ">
          <div className="codes-content">
            

            {
              this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)}
            />
            }
          </div>
          <div>
            <Grid id="subscriptionsCodesListGrid"
              keyColumn="id"
              rowData={this.state.subsCodesData}
              reinitialize={this.state.reinitialize}
              columnDefs={this.state.gridListConfig}
              loading={this.state.loader}
              pagination={true}
              pgnConfig={this.state.pgnConfig}
              onPgnChange={this.onPgnChange.bind(this)}
              onPgnRowsCountChange={this.onPgnRowsCountChange.bind(this)}
              pageList={this.state.pageList}
              lazyLoading={true}
              onLazyLoad={this.onLazyLoad.bind(this)}
            />
          </div>
        </div>
      </div>);
  }
}

export default ListSubscriptionsCodes;
