import React from 'react';
import { Spinner } from 'reactstrap';
import { UserService } from "../../../services/service.users";
import Grid from '../../../Component/grid/Grid';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import ModalDialog from "../../../Component/modaldialog/ModalDialog";
import AlertMessage from "../../../Component/alert/AlertMessage";
import { FormErrors } from '../../../Component/SubmissionStatus';
import { CommonUtilities } from "../../../shared/utils/commonUtilities";
import { StyledText } from "../../../Component/StyledText";
import i18n from 'i18next';

/**
 * Informations to be shown in association listing grid, along with options per rows in support details page.
 */
let userListConfig = {};

/**
 * component for account holder - operator assignment
 */
class ListAssociatedAssignments extends BaseScreen {
  constructor(props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    // initialize userListConfig with updated i18n resources
    userListConfig = {
      'firstName': {
        'label': i18n.t('users.list.firstName'),
        'sort': false,
        'filter': false,
        'display': true,
        'filterMaxLength': 50
      },
      'lastName': {
        'label': i18n.t('users.list.lastName'),
        'sort': false,
        'filter': false,
        'display': true,
        'filterMaxLength': 50
      },
      'email': {
        'label': i18n.t('users.list.email'),
        'sort': false,
        'filter': false,
        'display': true,
        'filterMaxLength': 50
      },
      'status': {
        'label': i18n.t('users.list.status'),
        'sort': true,
        'filter': false,
        'display': true,
        'filterMaxLength': 50
      },
      'options': {
        'label': i18n.t('users.list.options.options'),
        'display': true,
        'usertype': 'assignment'
      }
    };

    this.state = {
      isAuthenticated: isAuthenticated,
      assignmentsData: [],
      isWaitingForReqAssignment: false,
      // following is to control loading on grid
      loader: true,
      pgnConfig: {
        currentPage: 1,
        numberOfPageLinks: 0,
        countPerPage: 25,
        totalCount: 0,
        pageList: [25, 50, 100]
      },
      modal: {
        modalShow: false,
        shouldRenderModal: false,
        modalTitle: "",
        modalMsg: "",
        modalData: "",
        modalAction: ""
      },
      alert: {
        type: null,
        message: null
      },
      sortFieldsConfig: {},
      forUser: this.props.userId,
      forUserEmail: this.props.userEmail,
      isOperator: this.props.isOperator,
      formfields: {
        email: ''
      },
      formErrors: { email: '' },
      formValid: false
    };
  }

  /**
   * on component mount calls associations 
   */
  componentDidMount() {
    if (this.state.isAuthenticated) {
      let arg = {
        currentPage: 0,
        pageSize: this.state.pgnConfig.countPerPage,
      };
      this.getAssignmentsByUserId(arg, this.state.sortFieldsConfig).then(
        response => {
          let pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage, this.state.pgnConfig.countPerPage, response.data.total);
          this.setState({
            assignmentsData: this.prepareAssignmentsData(response.data.items),
            pgnConfig: pgnConfig,
            loader: false,
            modalShow: false,
            alert: {
              type: null,
              message: null
            }
          });
        },
        error => {
          this.handleApiError(error, []);
        });
    }
  }

  /**
   * prepare data for ui diplayable status.
   * @param {*} data 
   */
  prepareAssignmentsData(data) {
    let index = 0;
    for (index = 0; index < data.length; index++) {
      if (data[index]['status'] === 0) {
        if (data[index]['createdBy'] === this.props.userId) {
          data[index]['status'] = i18n.t('users.requested');
          data[index].options = [{
            actionType: '',
            action: 'cancel',
            label: i18n.t('users.cancelRequest')
          }];
        } else if (data[index]['createdBy'] !== this.props.userId) {
          data[index]['status'] = i18n.t('users.requestReceived');
          data[index].options = [{
            actionType: '',
            action: 'approve',
            label: i18n.t('users.approve')
          },
          {
            actionType: '',
            action: 'reject',
            label: i18n.t('users.reject')
          }];
        }
      } else if (data[index]['status'] === 1) {
        data[index]['status'] = i18n.t('users.assigned');
        data[index].options = [{
          actionType: '',
          action: 'unassign',
          label: i18n.t('users.unassign')
        }];
      }
    }
    return data;
  }

  /**
   *  helper function for pagination configuration
   * @param {*} currentPage 
   * @param {*} countPerPage 
   * @param {*} totalCount 
   */
  setPaginationConfig(currentPage, countPerPage, totalCount) {
    return {
      currentPage: currentPage,
      numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
      countPerPage: countPerPage,
      totalCount: totalCount,
      pageList: [...this.state.pgnConfig.pageList]
    }
  }

  /**
   * handler when page number change, like user move to next page or previous page from current page he is viewing
   * @param {*} pageNumber 
   */
  onPgnChange(pageNumber) {
    if (pageNumber !== this.state.pgnConfig.currentPage) {
      this.setState({ loader: true, assignmentsData: [] });
      let arg = {
        currentPage: (pageNumber - 1),
        pageSize: this.state.pgnConfig.countPerPage,
      };
      this.getAssignmentsByUserId(arg).then(response => {
        let pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        this.setState({
          assignmentsData: this.prepareAssignmentsData(response.data.items),
          pgnConfig: pgnConfig,
          loader: false,
          modalShow: false,
          alert: {
            type: null,
            message: null
          }
        });
      }, error => {
        this.handleApiError(error, []);
      });
    }
  }

  /**
   * service to fetch association by user id
   * @param {*} requiredParams 
   * @param {*} sortFieldsObj 
   */
  getAssignmentsByUserId(requiredParams, sortFieldsObj) {
    let reqQueryParams = "";
    let params;
    if (requiredParams) {
      params = {
        currentPage: requiredParams.currentPage,
        pageSize: requiredParams.pageSize,
      };
    } else {
      params = {
        currentPage: this.state.pgnConfig.currentPage - 1,
        pageSize: this.state.pgnConfig.countPerPage,
      };
    }

    // add sort parameters (if available)
    let sortFiledsQueryParams = "";
    if (!sortFieldsObj) {
      sortFieldsObj = this.state.sortFieldsConfig;
    }

    let sortQueryTemp = "";
    let key;
    for (key in sortFieldsObj) {
      let sortValue = sortFieldsObj[key]["sortOrder"];
      if (sortValue) {
        sortQueryTemp = "sortfield=" + key + "&orderType=" + ((sortValue === 1) ? "asc" : "desc");
        sortFiledsQueryParams += "&" + sortQueryTemp;
      }
    }
    let forUserType = "&accountId=";
    if (this.state.isOperator) {
      forUserType = "&operatorId="
    }
    reqQueryParams = "currentPage=" + params.currentPage + "&pageSize=" + params.pageSize + forUserType + this.props.userId + sortFiledsQueryParams;
    return UserService.getAssignementsForUser(this.props.userId, reqQueryParams).then(
      response => {
        return response;
      },
      errResponse => {
        return Promise.reject(errResponse);
      });
  }

  /**
   * handles the grid listing when there is any change per page row count, like if user chose default row count 25 to 50 or something else.
   * @param {*} event 
   */
  onPgnRowsCountChange(event) {
    window.scrollTo(0, 0);

    let perPageItems = Number(event.target.value);
    let currentPage;
    if ((this.state.pgnConfig.currentPage - 1) * perPageItems >= this.state.pgnConfig.totalCount) {
      currentPage = 1;
    } else {
      currentPage = this.state.pgnConfig.currentPage;
    }

    // re-fetch data
    this.setState({ loader: true, assignmentsData: [] });
    let arg = {
      currentPage: currentPage - 1,
      pageSize: perPageItems,
    };

    this.getAssignmentsByUserId(arg).then((response) => {
      let pgnConfig = this.setPaginationConfig(currentPage, perPageItems, response.data.total);
      this.setState({
        assignmentsData: this.prepareAssignmentsData(response.data.items),
        loader: false,
        pgnConfig: pgnConfig,
        alert: {
          type: null,
          message: null
        },
        modalShow: false
      });
    }, (error) => {
      this.handleApiError(error, []);
    });
  }

  /**
   * lazy load function when user use filter or sort functionality from grid header
   * @param {*} sortFieldsObj 
   * @param {*} filterFieldsObj 
   */
  onLazyLoad(sortFieldsObj, filterFieldsObj) {
    this.setState({ loader: true, assignmentsData: [] });

    // force to first page
    let pageNumber = 1;
    let arg = {
      currentPage: pageNumber - 1,
      pageSize: this.state.pgnConfig.countPerPage,
    };

    this.getAssignmentsByUserId(arg, sortFieldsObj)
      .then((response) => {
        let pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);

        //sortingData
        if (!sortFieldsObj) {
          sortFieldsObj = this.state.sortFieldsConfig;
        }

        this.setState({
          assignmentsData: this.prepareAssignmentsData(response.data.items),
          loader: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          },
          modalShow: false,
          sortFieldsConfig: sortFieldsObj,
          filterFieldsConfig: filterFieldsObj,
        });
      }, (error) => {
        this.handleApiError(error, []);
      });
  }

  /*
   modal dialogues handling
   */
  optionHandler(actionObject, row) {
    let modalInfo;
    if (actionObject.action === "unassign") {
      modalInfo = {
        modalMsg:  i18n.t('users.form.modal.unassignMsg', row.email), //"Do you want to unassign the user with emailId " + row.email + "?",
        modalTitle: i18n.t('users.form.modal.unassignTitle'),
        modalAction: "unassign_user"
      };
    } else if (actionObject.action === "cancel") {
      modalInfo = {
        modalMsg: i18n.t('users.form.modal.cancelMsg', row.email), //"Do you want to cancel assignment request for the user with emailId " + row.email + "?",
        modalTitle: i18n.t('users.form.modal.cancelTitle'),
        modalAction: "cancel_user"
      };
    }
    else if (actionObject.action === "approve") {
      modalInfo = {
        modalMsg: i18n.t('users.form.modal.approveMsg', row.email), //"Do you want to approve the assignment request of the user with emailId " + row.email + "?",
        modalTitle: i18n.t('users.form.modal.approveTitle'),
        modalAction: "approve_user"
      };
    }
    else if (actionObject.action === "reject") {
      modalInfo = {
        modalMsg: i18n.t('users.form.modal.rejectMsg', row.email), // "Do you want to reject the assignment request for the user with emailId " + row.email + "?",
        modalTitle: i18n.t('users.form.modal.rejectTitle'),
        modalAction: "reject_user"
      };
    }
    this.modalPopup(true, row, modalInfo);
  }

  /**
   * Modal window to show each row options.
   * @param {*} state 
   * @param {*} rowData 
   * @param {*} modalInfo 
   */
  modalPopup(state, rowData, modalInfo) {
    if (state) {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalMsg: modalInfo.modalMsg,
          modalTitle: modalInfo.modalTitle,
          modalData: rowData ? rowData : "",
          modalAction: modalInfo.modalAction
        },
      });
    } else {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalData: rowData ? rowData : "",
          modalAction: this.state.modal.modalAction
        },
      });
    }
  }

  /**
   * modal dismissal action
   */
  handleModalDismiss() {
    this.modalPopup(false);
  }

  /**
   * handle modal delete action.
   * @param {*} data 
   */
  onModalAction(data) {
    let modalAction = this.state.modal.modalAction;
    if (modalAction === "unassign_user" || modalAction === "cancel_user" || modalAction === "approve_user" || modalAction === "reject_user") {
      this.modalPopup(false, data);
    }
  }

  /**
   * function after modal is closed, re-render listing page after delete operation.
   */
  onModalClosed() {
    if (this.state.modal.modalAction === "unassign_user") {
      this.unassignUser();
    } else if (this.state.modal.modalAction === "approve_user") {
      this.updateAssignment(1);
    } else if (this.state.modal.modalAction === "reject_user") {
      this.updateAssignment(2);
    } else if (this.state.modal.modalAction === "cancel_user") {
      this.updateAssignment(3);
    }
  }

  /**
   * update association type
   * @param {*} assignmentStatus 
   */
  updateAssignment(assignmentStatus) {
    if (this.state.modal.modalData) {
      let modalData = this.state.modal.modalData;
      this.setState({
        loader: true,
        modal: {
          modalShow: false,
          shouldRenderModal: false,
        },
      });
      UserService.updateAssignment(modalData.id, this.state.forUser, assignmentStatus).then(
        (successResponse) => {
          let pageNumber = this.state.pgnConfig.currentPage;
          if ((this.state.assignmentsData.length <= 1) && (this.state.pgnConfig.currentPage > 1)) {
            pageNumber = this.state.pgnConfig.currentPage - 1;
          }
          let updateMessage;
          if (assignmentStatus === 1) {
            updateMessage = i18n.t('users.alert.updateMessage1');
          }
          else if (assignmentStatus === 2) {
            updateMessage = i18n.t('users.alert.updateMessage2');
          }
          else if (assignmentStatus === 3) {
            updateMessage = i18n.t('users.alert.updateMessage3');
          }
          this.setState({
            assignmentsData: [],
            alert: {
              type: "success",
              message: updateMessage,
            }
          });
          let arg = {
            currentPage: (pageNumber - 1),
            pageSize: this.state.pgnConfig.countPerPage
          };
          this.getAssignmentsByUserId(arg).then(
            (response) => {
              let pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
              this.setState({
                assignmentsData: this.prepareAssignmentsData(response.data.items),
                loader: false,
                pgnConfig: pgnConfig,
                modal: {
                  modalShow: false,
                  shouldRenderModal: false
                }
              });
            }, (error) => {
              this.handleApiError(error, []);
            });
        }, (error) => {
          this.handleApiError(error);
        });
    }
  }

  /**
   * handles API error
   * @param {*} error 
   * @param {*} assignmentsData 
   */
  handleApiError(error, assignmentsData) {
    let errorMsg = i18n.t('common.genericApiError');
    if (error && error.data && error.data.message) {
      errorMsg = error.data.message;
    }
    if (assignmentsData) {
      this.setState({
        assignmentsData: assignmentsData,
        loader: false,
        alert: {
          type: "danger",
          message: errorMsg,
        }
      });
    }
    else {
      this.setState({
        loader: false,
        alert: {
          type: "danger",
          message: errorMsg,
        }
      });
    }
  }

  /**
   * unassign/disassociation between users
   */
  unassignUser() {
    if (this.state.modal.modalData) {
      let modalData = this.state.modal.modalData;
      this.setState({
        loader: true,
        modal: {
          modalShow: false,
          shouldRenderModal: false,
        },
      });

      UserService.deleteAssignment(modalData.id, this.state.forUser).then(
        (successResponse) => {
          let pageNumber = this.state.pgnConfig.currentPage;
          if ((this.state.assignmentsData.length <= 1) && (this.state.pgnConfig.currentPage > 1)) {
            pageNumber = this.state.pgnConfig.currentPage - 1;
          }
          this.setState({
            assignmentsData: [],
            alert: {
              type: "success",
              message: i18n.t('users.alert.userAssignment')
            }
          });
          let arg = {
            currentPage: (pageNumber - 1),
            pageSize: this.state.pgnConfig.countPerPage
          };
          this.getAssignmentsByUserId(arg).then(
            (response) => {
              let pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
              this.setState({
                assignmentsData: this.prepareAssignmentsData(response.data.items),
                loader: false,
                pgnConfig: pgnConfig,
                modal: {
                  modalShow: false,
                  shouldRenderModal: false
                }
              });
            }, (error) => {
              this.handleApiError(error, []);
            });
        }, (error) => {
          this.handleApiError(error);
        });
    }
  }

  /**
   * alert message handling
   */
  handleAlertDismiss() {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * request assignment, both for account holder and operator
   */
  requestAssignment = (event) => {
    event.preventDefault();
    let operatorEmailId;
    let accountEmailId;
    if (this.state.isOperator) {
      operatorEmailId = this.state.forUserEmail;
      accountEmailId = this.state.formfields.email;
    }
    else {
      operatorEmailId = this.state.formfields.email;
      accountEmailId = this.state.forUserEmail;
    }

    this.setState({ isWaitingForReqAssignment: true });

    UserService.createAssignment(this.state.forUser, accountEmailId, operatorEmailId).then(
      (successResponse) => {
        let pageNumber = this.state.pgnConfig.currentPage;
        if ((this.state.assignmentsData.length <= 1) && (this.state.pgnConfig.currentPage > 1)) {
          pageNumber = this.state.pgnConfig.currentPage - 1;
        }

        this.setState({
          assignmentsData: [],
          isWaitingForReqAssignment: false,
          loader: true,
          alert: {
            type: "success",
            message: i18n.t('users.alert.userAssignmentRequest')
          }
        });
        let arg = {
          currentPage: (pageNumber - 1),
          pageSize: this.state.pgnConfig.countPerPage
        };
        this.getAssignmentsByUserId(arg).then(
          (response) => {
            let pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
            this.setState({
              assignmentsData: this.prepareAssignmentsData(response.data.items),
              loader: false,
              pgnConfig: pgnConfig,
              modal: {
                modalShow: false,
                shouldRenderModal: false
              }
            });
          }, (error) => {
            this.handleApiError(error, []);
          });
      }, (error) => {
        this.setState({ isWaitingForReqAssignment: false });
        this.handleApiError(error);
      });
  }

  /**
   * validate form inputs.
   */
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let formfields = this.state.formfields;
    formfields[name] = value;

    let fieldValidationErrors = this.state.formErrors;
    let msg = "";

    switch (name) {
      case 'email':
        if (value.length === 0) {
          fieldValidationErrors.email = i18n.t('users.alert.required');
        } else {
          let emailValid = CommonUtilities.isValidEmail(value);
          fieldValidationErrors.email = emailValid ? '' : i18n.t('users.alert.emailInvalid');
        }
        fieldValidationErrors.name = msg;
        break;

      default:
        break;
    }

    let isFormValid = (formfields.email && fieldValidationErrors.email === "");

    this.setState({
      formfields: formfields,
      formErrors: fieldValidationErrors,
      formValid: isFormValid,
    });
  }

  /**
   * react render function
   */
  render() {
    return (
      <div>
        <div>
          {
            this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)} 
              testId="usersAssignment"
            />
          }

          <form>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="email">{i18n.t('users.form.label.email')} <StyledText uiText="*" /></label>
                <input type="email" className="form-control"
                  name="email"
                  placeholder={i18n.t('users.form.placeholder.email')}
                  value={this.state.formfields.email}
                  onChange={this.handleUserInput}
                  maxLength="118" />
                <FormErrors formErrors={this.state.formErrors.email} />
              </div>
              <div className="col-md-6">
                <button type="submit" disabled={!this.state.formValid}
                  className="btn btn-primary margin-t-30"
                  onClick={this.requestAssignment}>
                  {i18n.t('users.form.button.requestAssignment')}
                </button>
              </div>
            </div>
          </form>

          {
            this.state.isWaitingForReqAssignment
              ?
              (
                <div>
                  <span>{i18n.t('users.alert.requestAssignmentInProgress')}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <Spinner size="sm" color="primary" className="color-override"/>
                </div>
              )
              : null
          }

          <div>
            <Grid id="usersAssignmentsGrid"
              optionHandler={this.optionHandler.bind(this)}
              keyColumn='id'
              rowData={this.state.assignmentsData}
              columnDefs={userListConfig}
              loading={this.state.loader}
              lazyLoading={true}
              onLazyLoad={this.onLazyLoad.bind(this)}
              pagination={true}
              pgnConfig={this.state.pgnConfig}
              onPgnChange={this.onPgnChange.bind(this)}
              onPgnRowsCountChange={this.onPgnRowsCountChange.bind(this)}
              pageList={this.state.pageList}
              customizedOption={true} />
          </div>
        </div>
        {
          this.state.modal.shouldRenderModal &&
          <ModalDialog modalTitle={this.state.modal.modalTitle}
            modalMessage={this.state.modal.modalMsg}
            modalData={this.state.modal.modalData}
            isModalOpen={this.state.modal.modalShow}
            modalAction={this.onModalAction.bind(this)}
            handleModalDismiss={this.handleModalDismiss.bind(this)}
            onModalClosed={this.onModalClosed.bind(this)}
          />
        }
      </div>);
  }
}

export default ListAssociatedAssignments;
