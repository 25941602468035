import { API_INTERFACE } from '../constants/uri.constant';
import baseService from './services';
import { APP_COMMON_CONSTANTS } from '../constants/app.constants';
import { AppStorageHelper } from '../shared/utils/appStorageHelper';
import { AuthUtils } from './service.auth.utils';

/**
 * API calling functionality for login.
 * @param {*} reqParams
 */
function login (reqParams) {
  var reqBody = new URLSearchParams();
  reqBody.append('email', reqParams.username);
  reqBody.append('password', reqParams.password);
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    oemid: API_INTERFACE.OEM_ID
  };
  return baseService.post(API_INTERFACE.AUTH_LOGIN, header, reqBody).then(
    function (response) {
      if (response.data.access_token) {
        AppStorageHelper.setTokenToLocalStorage(
          APP_COMMON_CONSTANTS.AUTH_INFO,
          {
            acessToken: response.data.access_token,
            refresh_token: response.data.refresh_token,
            expTime: response.data.expires_in
          }
        );
      }
      return response;
    },
    function (error) {
      return Promise.reject(error);
    });
}

/**
 * API calling functionality for logout.
 */
function logout () {
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.get(API_INTERFACE.AUTH_LOGOUT, header);
}

/**
 * API calling functionality for getting new refresh token
 */
function refreshToken () {
  var reqBody = new URLSearchParams();
  reqBody.append('token', AuthUtils.getRefreshToken());
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.post(API_INTERFACE.REFRESH_TOKEN, header, reqBody).then(
    function (response) {
      if (response.data.access_token) {
        AppStorageHelper.setTokenToLocalStorage(
          APP_COMMON_CONSTANTS.AUTH_INFO,
          {
            acessToken: response.data.access_token,
            refresh_token: response.data.refresh_token,
            expTime: response.data.expires_in
          }
        );
      }
      return response;
    },
    function (error) {
      return Promise.reject(error);
    });
}

/**
 * Reset password
 */
function resetPasswordTokenRequest (emailId) {
  var reqBody = new URLSearchParams();
  reqBody.append('email', emailId);
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    oemid: API_INTERFACE.OEM_ID
  };
  return baseService.post(API_INTERFACE.RESET_PWD_TOKEN_REQUEST, header, reqBody);
}

/**
 * API calling functionality for reset password.
 * @param {*} emailId
 * @param {*} resetToken
 * @param {*} pwd
 */
function resetPassword (emailId, resetToken, pwd) {
  var reqBody = new URLSearchParams();
  reqBody.append('email', emailId);
  reqBody.append('token', resetToken);
  reqBody.append('password', pwd);
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    oemid: API_INTERFACE.OEM_ID
  };
  return baseService.post(API_INTERFACE.RESET_PWD, header, reqBody);
}

export const AuthenticationServices = {
  login,
  logout,
  refreshToken,
  resetPasswordTokenRequest,
  resetPassword
};
