import i18n from 'i18next';
import _ from 'lodash';

/**
 * build version of application.
 */
let APP_BUILD_VERSION = 'v1';

/**
 * update APP_BUILD_VERSION.
 */
export function updateAppBuildVersion(buildVersion) {
  if(!_.isEmpty(buildVersion)) {
    APP_BUILD_VERSION = buildVersion;
  }
}

export { APP_BUILD_VERSION };

/**
 * Default (fallback) theme for the application
 */
export const DEFAULT_THEME = {
  PRIMARY_COLOR: '#bf5e30',
  SECONDARY_COLOR: '#6c757d',
  FOREGROUND_COLOR: '#ffffff',
  GENERIC_COLOR: '#000000'
};

/**
 * role types or users types identified for the application.
 */
export const ROLE_TYPES = {
  OEM_ADMIN: {
    ID: 1,
    NAME: 'OEM Admin'
  },
  ACCOUNT_HOLDER: {
    ID: 2,
    NAME: 'Account Holder'
  },
  OPERATOR: {
    ID: 3,
    NAME: 'Operator'
  }
};

/**
 * api error code for force change password: USR_24
 * api error code for valid access_token expired: API_19
 */
export const API_ERROR_CODES = {
  USR_24: 'USR_24',
  API_19: 'API_19'
};

/**
 * common constantants of application
 */
export const APP_COMMON_CONSTANTS = {
  AUTH_INFO: 'AUTH_INFO',
  SELECTED_ACC_HOLDER: 'SELECTED_ACC_HOLDER',
  USER_ROLES: 'USER_ROLES',
  PREFERRED_LANGUAGE: 'PREFERRED_LANGUAGE'
};

/**
 * implemented supported language.
 */
let SUPPORTED_LANGUAGES = [
  {
    __comment__: 'English Language',
    lng: 'en',
    name: 'English'
  },
  {
    __comment__: 'Chinese Language',
    lng: 'zh',
    name: 'Chinese (中文)'
  },
  {
    __comment__: 'Japanese Language',
    lng: 'ja',
    name: 'Japanese (日本語)'
  }
];

/**
 * update SUPPORTED_LANGUAGES list.
 */
export function updateSupportedLanguages(languages) {
  if(_.isObject(languages) && !_.isEmpty(languages)) {
    SUPPORTED_LANGUAGES = languages;
  }
}

export { SUPPORTED_LANGUAGES };

export const DEFAULT_LANG = 'en';

/*
 export resuable common values using function, to make use of
 localization
*/
function getVehicleTypes () {
  return [
    { value: 'STANDARD', displayName: i18n.t('common.vehicleTypes.standard') },
    { value: 'COMBINE', displayName: i18n.t('common.vehicleTypes.combine') },
    { value: 'MFWD', displayName: i18n.t('common.vehicleTypes.mfwd') },
    { value: 'ARTICULATED', displayName: i18n.t('common.vehicleTypes.articulated') },
    { value: 'RICE_TRANSPLANTER', displayName: i18n.t('common.vehicleTypes.riceTransplanter') },
    { value: 'ONION_PLANTER', displayName: i18n.t('common.vehicleTypes.onionPlanter') },
    { value: 'SPRAYER', displayName: i18n.t('common.vehicleTypes.sprayer') }
  ];
}

/**
 * resolve display name of user roles by role id.
 */
function getSignUpRoleTypes () {
  return [
    { value: '2', displayName: i18n.t('common.userRoleTypes.label.accHolder') },
    { value: '3', displayName: i18n.t('common.userRoleTypes.label.operator') }
  ];
}

function getBooleanTypes () {
  return [
    { value: 'true', displayName: i18n.t('common.booleanTypes.true') },
    { value: 'false', displayName: i18n.t('common.booleanTypes.false') }
  ];
}

/**
 * ECU orientations
 */
 function getEcuOrientationTypes () {
  return [
    { value: 'Up', displayName: i18n.t('common.ecuOrientationTypes.up') },
    { value: 'Rear', displayName: i18n.t('common.ecuOrientationTypes.rear') },
    { value: 'Right', displayName: i18n.t('common.ecuOrientationTypes.right') },
    { value: 'Left', displayName: i18n.t('common.ecuOrientationTypes.left') },
    { value: 'Front', displayName: i18n.t('common.ecuOrientationTypes.front') }
  ];
}

function getXYStickeFace () {
  return [
    { value: 'Right', displayName: i18n.t('common.ecuOrientationTypes.right') },
    { value: 'Left', displayName: i18n.t('common.ecuOrientationTypes.left') },
    { value: 'Up', displayName: i18n.t('common.ecuOrientationTypes.up') },
    { value: 'Down', displayName: i18n.t('common.ecuOrientationTypes.down') },
    { value: 'Aft', displayName: i18n.t('common.ecuOrientationTypes.aft') },
    { value: 'Forward', displayName: i18n.t('common.ecuOrientationTypes.front') }
  ];
}

function getCableAttachDirection () {
  return [
    { value: 'Right', displayName: i18n.t('common.ecuOrientationTypes.right') },
    { value: 'Left', displayName: i18n.t('common.ecuOrientationTypes.left') },
    { value: 'Aft', displayName: i18n.t('common.ecuOrientationTypes.aft') },
    { value: 'Forward', displayName: i18n.t('common.ecuOrientationTypes.front') }
  ];
}



/**
 * common constants
 */
export const CommonConstants = {
  getVehicleTypes,
  getEcuOrientationTypes,
  getSignUpRoleTypes,
  getBooleanTypes,
  getXYStickeFace,
  getCableAttachDirection
};
