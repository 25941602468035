import React from 'react';
import i18n from 'i18next';
import Grid from '../../../Component/grid/Grid';
import AlertMessage from '../../../Component/alert/AlertMessage';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { TaskService } from '../../../services/service.tasks';
import AppInfoHelper from '../../../shared/utils/appInfoHelper';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import { ROLE_TYPES } from '../../../constants/app.constants';

/**
 * Informations to be shown in task listing grid, along with options per rows in support details page.
 */
let taskListConfig = {};

/**
 * List task component
 */
class ListTasks extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    // initialize taskListConfig with updated i18n resources
    taskListConfig = {
      name: {
        label: i18n.t('tasks.list.taskName'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 50
      },
      farmName: {
        label: i18n.t('tasks.list.farmName'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 50
      },
      options: {
        label: i18n.t('tasks.list.options.options'),
        sort: false,
        filter: false,
        display: true,
        list: [
          {
            actionType: 'link',
            action: 'view',
            label: i18n.t('tasks.list.options.view')
          }
        ]
      }
    };

    this.state = {
      isAuthenticated: isAuthenticated,
      loggedInUserInfo: undefined,
      selectedAccountHolder: undefined,
      tasksData: [],
      loader: true,
      pgnConfig: {
        currentPage: 1,
        numberOfPageLinks: 0,
        countPerPage: 25,
        totalCount: 0,
        pageList: [25, 50, 100]
      },
      alert: {
        type: null,
        message: null
      },
      sortFieldsConfig: {},
      filterFieldsConfig: {},
      reinitialize: false
    };
  }

  /**
   * check userRole first and then communicate backend to get tasks
   */
  componentDidMount () {
    const self = this;
    const userInfoInstance = AppInfoHelper.getUserInstance();
    userInfoInstance.getUserInfo().then(function (response) {
      const userInfo = response;
      const selectedAccountHolder = userInfoInstance.getSelectedAccountHolderForOperator();
      if (userInfo.roleId === ROLE_TYPES.OPERATOR.ID && !selectedAccountHolder) {
        const warningMsg = i18n.t('tasks.alert.selectAccountholder');
        self.setState(
          {
            tasksData: [],
            loader: false,
            loggedInUserInfo: response,
            reinitialize: false,
            alert: {
              type: 'danger',
              message: warningMsg
            }
          });
      } else {
        self.setState(
          {
            loader: true,
            loggedInUserInfo: response,
            selectedAccountHolder: selectedAccountHolder
          });
        const accountHolderId = response.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID ? userInfo.id : selectedAccountHolder.id;
        const arg = {
          currentPage: 0,
          pageSize: self.state.pgnConfig.countPerPage,
          accountHolderId: accountHolderId
        };
        self.getTasksData(arg, self.state.sortFieldsConfig, self.state.filterFieldsConfig, accountHolderId)
          .then(response => {
            const pgnConfig = self.setPaginationConfig(self.state.pgnConfig.currentPage, self.state.pgnConfig.countPerPage, response.data.total);
            self.setState({ tasksData: response.data.items, loader: false, pgnConfig: pgnConfig, reinitialize: false });
          }, error => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
              errorMsg = error.data.message;
            }
            self.setState(
              {
                tasksData: [],
                loader: false,
                reinitialize: false,
                alert: {
                  type: 'danger',
                  message: errorMsg
                }
              });
          });
      }
    }, function (error) {});
  }

  /**
   * get task data
   * @param {*} requiredParams 
   * @param {*} sortFieldsObj 
   * @param {*} filterFieldsObj 
   * @param {*} accountHolderId 
   */
  getTasksData (requiredParams, sortFieldsObj, filterFieldsObj, accountHolderId) {
    let accountHolder = '';
    if (accountHolderId && accountHolderId !== '') {
      accountHolder = accountHolderId;
    } else if (this.state.loggedInUserInfo && this.state.loggedInUserInfo.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID) {
      accountHolder = this.state.loggedInUserInfo.id;
    } else if (this.state.selectedAccountHolder && this.state.selectedAccountHolder.id) {
      accountHolder = this.state.selectedAccountHolder.id;
    }
    let reqQueryParams = '';
    let params;
    if (requiredParams) {
      params = {
        currentPage: requiredParams.currentPage,
        pageSize: requiredParams.pageSize
      };
    } else {
      params = {
        currentPage: this.state.pgnConfig.currentPage - 1,
        pageSize: this.state.pgnConfig.countPerPage
      };
    }
    reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' + params.pageSize;
    let sortFiledsQueryParams = '';
    if (!sortFieldsObj) {
      sortFieldsObj = this.state.sortFieldsConfig;
    }
    let sortQueryTemp = '';
    let key;
    for (key in sortFieldsObj) {
      const sortValue = sortFieldsObj[key].sortOrder;
      if (sortValue) {
        sortQueryTemp = 'sortfield=' + key + '&orderType=' + ((sortValue === 1) ? 'asc' : 'desc');
        sortFiledsQueryParams += '&' + sortQueryTemp;
      }
    }
    let filterFielddQueryParams = '';
    if (!filterFieldsObj) {
      filterFieldsObj = this.state.filterFieldsConfig;
    }

    let filterQueryTemp = '';
    for (key in filterFieldsObj) {
      const filterValue = filterFieldsObj[key].value;
      if (filterValue) {
        filterQueryTemp = key + '=' + filterValue;
        filterFielddQueryParams += '&' + filterQueryTemp;
      }
    }

    reqQueryParams += sortFiledsQueryParams + filterFielddQueryParams;

    if (accountHolder) {
      reqQueryParams += '&accountId=' + accountHolder;
    }

    return TaskService.getTasks(reqQueryParams).then(response => {
      return response;
    }, errResponse => {
      return Promise.reject(errResponse);
    });
  }

  /**
   * helper function for pagination configuration
   * @param {*} currentPage 
   * @param {*} countPerPage 
   * @param {*} totalCount 
   */
  setPaginationConfig (currentPage, countPerPage, totalCount) {
    return {
      currentPage: currentPage,
      numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
      countPerPage: countPerPage,
      totalCount: totalCount,
      pageList: [...this.state.pgnConfig.pageList]
    };
  }

  /**
   * handler when page number change, like user move to next page or previous page from current page he is viewing
   * @param {*} pageNumber 
   */
  onPgnChange (pageNumber) {
    if (pageNumber !== this.state.pgnConfig.currentPage) {
      window.scrollTo(0, 0);
      this.setState({ loader: true, tasksData: [] });

      const arg = {
        currentPage: (pageNumber - 1),
        pageSize: this.state.pgnConfig.countPerPage
      };

      this.getTasksData(arg).then((response) => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        this.setState({
          tasksData: response.data.items,
          loader: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          }
        });
      }, (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            tasksData: [],
            loader: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /**
   * handles the grid listing when there is any change per page row count, like if user chose default row count 25 to 50 or something else.
   * @param {*} event 
   */
  onPgnRowsCountChange (event) {
    window.scrollTo(0, 0);
    const perPageItems = Number(event.target.value);
    let currentPage;
    if ((this.state.pgnConfig.currentPage - 1) * perPageItems >= this.state.pgnConfig.totalCount) {
      currentPage = 1;
    } else {
      currentPage = this.state.pgnConfig.currentPage;
    }
    this.setState({ loader: true, tasksData: [] });
    const arg = {
      currentPage: currentPage - 1,
      pageSize: perPageItems
    };
    this.getTasksData(arg).then((response) => {
      const pgnConfig = this.setPaginationConfig(currentPage, perPageItems, response.data.total);
      this.setState({
        tasksData: response.data.items,
        loader: false,
        pgnConfig: pgnConfig,
        alert: {
          type: null,
          message: null
        }
      });
    }, (error) => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }
      this.setState(
        {
          tasksData: [],
          loader: false,
          alert: {
            type: 'danger',
            message: errorMsg
          }
        });
    });
  }

  /**
   * lazy load function when user use filter or sort functionality from grid header
   * @param {*} sortFieldsObj 
   * @param {*} filterFieldsObj 
   */
  onLazyLoad (sortFieldsObj, filterFieldsObj) {
    this.setState({ loader: true, tasksData: [] });
    const pageNumber = 1;
    const arg = {
      currentPage: pageNumber - 1,
      pageSize: this.state.pgnConfig.countPerPage
    };
    this.getTasksData(arg, sortFieldsObj, filterFieldsObj)
      .then((response) => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);

        // sortingData
        if (!sortFieldsObj) {
          sortFieldsObj = this.state.sortFieldsConfig;
        }

        // filteringData
        if (!filterFieldsObj) {
          filterFieldsObj = this.state.filterFieldsConfig;
        }

        this.setState({
          tasksData: response.data.items,
          loader: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          },
          sortFieldsConfig: sortFieldsObj,
          filterFieldsConfig: filterFieldsObj
        });
      }, (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            tasksData: [],
            loader: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
  }

  /**
   * routing to relevant page based on row option selection
   * @param {*} actionType 
   * @param {*} row 
   */
  actionPaths (actionType, row) {
    if (actionType.action === 'view') {
      return ({
        pathname: '/tasks/view-tasks/' + row.id
      });
    }
  }

  /**
   * alert message handling
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render function for redering a listing of tasks
   */
  render () {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('tasks.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'tasks', displayName: i18n.t('tasks.breadcrumb.tasks'), className: '', link: false }
    ];

    return (
      <div className="container-fluid p-4">
        <BreadcrumbCustom breadcrumb={breadcrumb} />
        <div className="clearfix"></div>
        {
          this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)}
            />
        }
        <div>
          <Grid id="tasksListGrid"
            keyColumn="id"
            rowData={this.state.tasksData}
            reinitialize={this.state.reinitialize}
            columnDefs={taskListConfig}
            loading={this.state.loader}
            pagination={true}
            pgnConfig={this.state.pgnConfig}
            onPgnChange={this.onPgnChange.bind(this)}
            onPgnRowsCountChange={this.onPgnRowsCountChange.bind(this)}
            pageList={this.state.pageList}
            lazyLoading={true}
            onLazyLoad={this.onLazyLoad.bind(this)}
            actionPaths={this.actionPaths.bind(this)} />
        </div>
      </div>
    );
  }
}

export default ListTasks;
