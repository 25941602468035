import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import './services/api_interceptor';
import DefaultLayout from './Containers/Screens/DefaultLayout';
import ResetPassword from './Containers/Screens/no-auth/ResetPassword';
import ForceChangePassword from './Containers/Screens/no-auth/ForceChangePassword';
import Login from './Containers/Screens/no-auth/Login';
import SignUp from './Containers/Screens/no-auth/SignUp';
import ConfirmSignup from './Containers/Screens/no-auth/ConfirmSignup';
import ResendConfirmSignupEmail from './Containers/Screens/no-auth/ResendConfirmSignupEmail';
import './Containers/css/app/app.scss';
import 'font-awesome/css/font-awesome.min.css';

/**
 * React App component.
 */
class App extends Component {
  /**
   * Component initialization
   * @param {object} props
   */
  constructor (props) {
    super(props);
    this.state = {};
  }

  /**
   * render the component view
   */
  render () {
    return (
      <Router>
        <Switch>
          <Route exact path="/login" render={props => <Login routerData={props} />} />
          <Route exact path="/sign-up" render={props => <SignUp routerData={props} />} />
          <Route exact path="/reset-password" render={props => <ResetPassword routerData={props} />} />
          <Route exact path="/force-change-password" render={props => <ForceChangePassword routerData={props} />} />
          <Route exact path="/confirm-signup" render={props => <ConfirmSignup routerData={props} />} />
          <Route exact path="/resend-confirm-signup-email" render={props => <ResendConfirmSignupEmail routerData={props} />} />
          <Route path="/" render={props => <DefaultLayout routerData={props} />} />
        </Switch>
      </Router>
    );
  }
}

export default App;
