import React from 'react';
import i18n from 'i18next';
import queryString from 'query-string';
import _ from 'lodash';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { FormErrors } from '../../../Component/SubmissionStatus';
import { AuthenticationServices } from '../../../services/service.authentication';
import { CommonUtilities } from '../../../shared/utils/commonUtilities';
import { AppStorageHelper } from '../../../shared/utils/appStorageHelper';
import AlertMessage from '../../../Component/alert/AlertMessage';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import { StyledText } from '../../../Component/StyledText';

const TOKEN_RESEND_DELAY = 20000; // 20 seconds
const DELAY_AFTER_PROFILE_UPDATE = 1000; // 10 seconds

/**
 * Reset Password component. consistes steps to complete the functionalities.
 */
class ResetPassword extends BaseScreen {
  constructor (props) {
    super(props);
    this.state = {
      isWaitingApiResponse: false,
      /**
       * form fields for "STEP 1" process (get password reset code)
       */
      step1FormFields: {
        email: ''
      },
      step1FormErrors: {
        email: ''
      },
      step1FormValid: false,
      step1FormAlertMessage: {
        message: '',
        type: ''
      },
      isStep1Done: false,
      enableResendCode: false,

      /**
       * form fields for "STEP 2" process (send password reset request)
       */
      step2FormFields: {
        email: '',
        token: '',
        newPassword: '',
        confirmPassword: ''
      },
      step2FormErrors: {
        token: '',
        newPassword: '',
        confirmPassword: ''
      },
      step2FormValid: false,
      step2FormAlertMessage: {
        message: '',
        type: ''
      },
      waitMsgOverlay: 'Please wait',
      newPasswordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      isStep2Done: false,
      disableAllApiHandlers: false
    };

    this.tokenResendTimer = null;
    this.profileUpdatedTimer = null;
  }

  /**
   * check url query params (if emailId and resetCode is provided via email link) turn on/off STEP 2 process based on validity of queryparams
   */
  componentDidMount () {
    const urlParams = this.props.routerData.location.search;
    const parsedValues = queryString.parse(urlParams);

    const isResetCodePresent = _.has(parsedValues, 'resetCode');
    const isEmailPresent = _.has(parsedValues, 'email');

    if (isResetCodePresent && isEmailPresent) {
      const emailIdInQueryString = _.trim(parsedValues.email);
      const resetCodeInQueryString = _.trim(parsedValues.resetCode);

      const isResetCodeValid = !CommonUtilities.isEmpty(resetCodeInQueryString) && (resetCodeInQueryString.length === 6);
      const isEmailValid = CommonUtilities.isValidEmail(emailIdInQueryString);

      /**
       * validate
       */
      if (isResetCodeValid && isEmailValid) {
        this.setState({
          step1FormFields: {
            email: emailIdInQueryString
          },
          step1FormValid: true,
          isStep1Done: true,
          enableResendCode: true,
          step2FormFields: {
            email: emailIdInQueryString,
            token: resetCodeInQueryString,
            newPassword: '',
            confirmPassword: ''
          },
          step2FormValid: false
        });
      } else {
        this.setState({
          step1FormFields: {
            email: emailIdInQueryString
          }
        });
      }
    }
  }

  /**
   * cleanup resources
   */
  componentWillUnmount () {
    clearTimeout(this.tokenResendTimer);
    clearTimeout(this.profileUpdatedTimer);
  }

  /**
   * go to login page
   */
  goToLogin () {
    AppStorageHelper.clearApplicationData();
    this.goToScreen(SCREENS.login);
  }

  /**
   * resend code.
   */
  enableResendCodeBtn () {
    this.setState({ enableResendCode: true });
  }

  /**
   * function to get reset password code
   * @param {*} e 
   */
  getPasswordResetCode (e) {
    e.preventDefault();
    this.setState({ enableResendCode: false, isWaitingApiResponse: true });
    AuthenticationServices.resetPasswordTokenRequest(this.state.step1FormFields.email).then(
      response => {
        this.setState({
          isStep1Done: true,
          isWaitingApiResponse: false,
          step1FormAlertMessage: {
            message: i18n.t('noauth.alert.resetCodeSent'),
            type: 'success'
          }
        });
        this.tokenResendTimer = setTimeout(() => this.enableResendCodeBtn(), TOKEN_RESEND_DELAY);
      },
      error => {
        let alertMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          alertMsg = error.data.message;
        }
        this.setState({
          isWaitingApiResponse: false,
          step1FormAlertMessage: {
            message: alertMsg,
            type: 'danger'
          }
        });
      });
  }

  /**
   * Step 1 form validation
   * @param {*} event 
   */
  handleUserInputStep1Form (event) {
    event.preventDefault();

    const name = event.target.name;
    const value = _.trim(event.target.value);

    const formfields = this.state.step1FormFields;
    formfields[name] = value;

    const fieldValidationErrors = { ...this.state.step1FormErrors };
    let msg = '';

    switch (name) {
      case 'email':
        if (value.length === 0) {
          msg = i18n.t('noauth.alert.required');
        } else if (value.length > 320) {
          msg = i18n.t('noauth.alert.email320');
        } else {
          msg = CommonUtilities.isValidEmail(value) ? '' : i18n.t('noauth.alert.emailInvalid');
        }
        fieldValidationErrors.email = msg;
        break;

      default:
        break;
    }

    const isFormValid = formfields.email && (fieldValidationErrors.email === '');

    this.setState({
      step1FormFields: formfields,
      step1FormErrors: fieldValidationErrors,
      step1FormValid: isFormValid
    });
  }

  /**
   * validate if step 1 is successfull
   */
  getStep1Validity () {
    let valid = false;
    if (this.state.step1FormValid) {
      valid = true;
      if (this.state.isStep1Done && !this.state.enableResendCode) {
        valid = false;
      }
    }

    return valid && !this.state.disableAllApiHandlers;
  }

  /**
   * validate Step 2 form data
   * @param {*} event
   */
  handleUserInputStep2Form (event) {
    event.preventDefault();

    const name = event.target.name;
    const value = _.trim(event.target.value);

    const formFields = this.state.step2FormFields;
    formFields[name] = value;

    const fieldValidationErrors = { ...this.state.step2FormErrors };
    let msg = '';

    const newPwd = formFields.newPassword;
    const cnfPwd = formFields.confirmPassword;

    switch (name) {
      case 'token':
        if (value.length === 0) {
          msg = i18n.t('noauth.alert.required');
        } else if (value.length !== 6) {
          msg = i18n.t('noauth.alert.token');
        }
        fieldValidationErrors[name] = msg;
        break;

      case 'newPassword':
      case 'confirmPassword':
        if (value.length === 0) {
          msg = i18n.t('noauth.alert.required');
        } else if (value.length < 8) {
          msg = i18n.t('noauth.alert.passwordMin');
        } else if (name === 'newPassword' && cnfPwd) {
          /**
           * handle password mismatch
           */
          if (cnfPwd !== value) {
            msg = i18n.t('noauth.alert.passConfirmPassNoMatch');
          } else {
            fieldValidationErrors.confirmPassword = '';
          }
        } else if (name === 'confirmPassword' && newPwd) {
          /**
           * handle password mismatch
           */
          if (newPwd !== value) {
            msg = i18n.t('noauth.alert.passConfirmPassNoMatch');
          } else {
            fieldValidationErrors.newPassword = '';
          }
        }

        fieldValidationErrors[name] = msg;
        break;

      default:
        break;
    }

    const isFormValid = (formFields.token && formFields.newPassword && formFields.confirmPassword) &&
      (fieldValidationErrors.token === '' && fieldValidationErrors.newPassword === '' &&
        fieldValidationErrors.confirmPassword === '');

    this.setState({
      step2FormFields: formFields,
      step2FormErrors: fieldValidationErrors,
      step2FormValid: isFormValid
    });
  }

  /**
   * validate if step 2 is successfull
   */
  getStep2Validity () {
    let valid = false;
    if (this.state.step2FormValid) {
      valid = true;
    }

    return valid && !this.state.disableAllApiHandlers;
  }

  /**
   * function to communicate with backend for reseting the password.
   */
  resetPassword () {
    this.setState({ isWaitingApiResponse: true, waitMsgOverlay: 'Your new password is being processed.  This may take a moment' });
    AuthenticationServices.resetPassword(this.state.step1FormFields.email,
      this.state.step2FormFields.token, this.state.step2FormFields.newPassword).then(
      response => {
        // handle success message
        this.setState({
          isWaitingApiResponse: false,
          isStep2Done: true,
          waitMsgOverlay: 'Re-directing to login page',
          disableAllApiHandlers: true,
          step2FormAlertMessage: {
            message: i18n.t('noauth.alert.profileUpdateSuccess'),
            type: 'success'
          }
        });
        this.profileUpdatedTimer = setTimeout(() => this.goToLogin(), DELAY_AFTER_PROFILE_UPDATE);
      },
      error => {
        // handle error message
        let alertMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          alertMsg = error.data.message;
        }
        this.setState({
          isWaitingApiResponse: false,
          step2FormAlertMessage: {
            message: alertMsg,
            type: 'danger'
          }
        });
      });
  }

  /*
   alert message handling
   */
  handleStep1AlertDismiss () {
    this.setState({
      step1FormAlertMessage: {
        type: null,
        message: null
      }
    });
  }

  /**
   * close step 2 alert message.
   */
  handleStep2AlertDismiss () {
    this.setState({
      step2FormAlertMessage: {
        type: null,
        message: null
      }
    });
  }

  /**
   * toggle view from text to encrypted
   * @param {*} fieldName 
   * @param {*} event 
   */
  toggleFieldType (fieldName, event) {
    event.preventDefault();

    if (fieldName === 'newPassword') {
      this.setState({ newPasswordFieldType: this.state.newPasswordFieldType === 'password' ? 'text' : 'password' });
    } else if (fieldName === 'confirmPassword') {
      this.setState({ confirmPasswordFieldType: this.state.confirmPasswordFieldType === 'password' ? 'text' : 'password' });
    }
  }

  /**
   * react render functionality to display forms for step 1 and step 2
   */
  render () {
    return (
      <LoadingOverlay active={this.state.isWaitingApiResponse} customClass="full_page_loading_overlay" 
      text = { this.state.waitMsgOverlay }>
        <div className="container-fluid p-4 reset-section">
          <div className="row ">
            <div className="col-md-12 reset-password-wrap">
              {
                /*
                <!-------------- form elements for STEP 1 -------------------->
                */
              }
              <div className="ibox-title" data-testid="reset-pwd-heading">
                {i18n.t('noauth.form.label.resetPassword')}
                <span className="pull-right" data-testid="reset-pwd-heading-step1">
                  {i18n.t('noauth.form.label.step')}&nbsp;
                  <span className="badge">{i18n.t('noauth.form.label.step1')}</span>
                </span>
              </div>
              <div className="ibox-content">
                <form autoComplete="off">
                  <div className="form-group">
                    <label htmlFor="email" data-testid="reset-pwd-label-email">
                        {i18n.t('noauth.form.label.emailId')} <StyledText uiText="*" />
                    </label>
                    <input type="text" required className="form-control" name="email"
                      placeholder={i18n.t('noauth.form.placeholder.emailId')}
                      value={this.state.step1FormFields.email}
                      onChange={this.handleUserInputStep1Form.bind(this)} maxLength="320"
                      disabled={this.state.isStep1Done} 
                      data-testid="reset-pwd-input-email" />
                    <FormErrors formErrors={this.state.step1FormErrors.email} testId="reset-pwd-email"/>
                  </div>

                  {
                    this.state.step1FormAlertMessage.message &&
                    <AlertMessage message={this.state.step1FormAlertMessage.message}
                      type={this.state.step1FormAlertMessage.type}
                      isAlertOpen={!!(this.state.step1FormAlertMessage.message)}
                      handleDismiss={this.handleStep1AlertDismiss.bind(this)} 
                      testId="reset-pwd-step1"
                    />
                  }

                  <div className="col-md-12 text-right">
                    <button type="button" className="btn btn-secondary mr-2"
                      onClick={this.goToLogin.bind(this)} 
                      data-testid="reset-pwd-back-btn">
                      {i18n.t('noauth.form.button.back')}
                    </button>
                    <button type="submit" className="btn btn-primary"
                      onClick={this.getPasswordResetCode.bind(this)}
                      disabled={!(this.getStep1Validity())} 
                      data-testid="get-reset-code-btn">
                      {(this.state.isStep1Done) ? i18n.t('noauth.form.button.resendCode') : i18n.t('noauth.form.button.getResetCode')}
                    </button>
                  </div>
                </form>
              </div></div>

            <div className={this.state.isStep1Done ? 'col-md-12 reset-password-wrap  opacity-on' : 'col-md-12 opacity-off'}
              style={{ transition: 'opacity 0.8s ease-in-out' }}>
              {
                /*
                <!-------------- form elements for STEP 2 -------------------->
                */
              }
              <div className="ibox-title">
                {i18n.t('noauth.form.label.completeResetPwdLabel')}
                <span className="pull-right">
                  {i18n.t('noauth.form.label.step')}&nbsp;
                  <span className="badge">{i18n.t('noauth.form.label.step2')}</span>
                </span>
              </div>
              {
                this.state.step2FormAlertMessage.message &&
                <AlertMessage message={this.state.step2FormAlertMessage.message}
                  type={this.state.step2FormAlertMessage.type}
                  isAlertOpen={!!(this.state.step2FormAlertMessage.message)}
                  handleDismiss={this.handleStep2AlertDismiss.bind(this)} 
                  testId="reset-pwd-step2"
                />
              }
              <div className="ibox-content">
                <form autoComplete="off">
                  <div className="form-group">
                    <label htmlFor="token">{i18n.t('noauth.form.label.resetCode')} <StyledText uiText="*" /></label>
                    <input type="text" required className="form-control" name="token"
                      placeholder={i18n.t('noauth.form.placeholder.resetCode')}
                      value={this.state.step2FormFields.token}
                      onChange={this.handleUserInputStep2Form.bind(this)} maxLength="6" 
                      data-testid="reset-pwd-input-token" />
                    <FormErrors formErrors={this.state.step2FormErrors.token} />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">{i18n.t('noauth.form.label.emailId')} <StyledText uiText="*" /></label>
                    <input type="text" required className="form-control" name="email"
                      placeholder={i18n.t('noauth.form.placeholder.emailId')}
                      value={this.state.step1FormFields.email}
                      maxLength="320" disabled />
                  </div>

                  <div className="form-group">
                    <div className="input-group">
                      <label htmlFor="newPassword">{i18n.t('noauth.form.label.newPassword')} <StyledText uiText="*" /></label>
                      <input type={this.state.newPasswordFieldType} required className="form-control" name="newPassword"
                        placeholder={i18n.t('noauth.form.placeholder.newPassword')}
                        autoComplete="new-password"
                        value={this.state.step2FormFields.newPassword}
                        onChange={this.handleUserInputStep2Form.bind(this)} minLength="8" 
                        data-testid="reset-pwd-input-new-pwd" />
                      <button className="btn input-group-addon" onClick={this.toggleFieldType.bind(this, 'newPassword')}>
                        <i className={'fa ' + (this.state.newPasswordFieldType === 'password' ? 'fa-eye-slash' : 'fa-eye')}></i>
                      </button>
                    </div>
                    <FormErrors formErrors={this.state.step2FormErrors.newPassword} />
                  </div>

                  <div className="form-group">
                    <div className="input-group">
                      <label htmlFor="confirmPassword">{i18n.t('noauth.form.label.confirmPassword')} <StyledText uiText="*" /></label>
                      <input type={this.state.confirmPasswordFieldType} required className="form-control" name="confirmPassword"
                        placeholder={i18n.t('noauth.form.placeholder.confirmPassword')}
                        value={this.state.step2FormFields.confirmPassword}
                        onChange={this.handleUserInputStep2Form.bind(this)} minLength="8" 
                        data-testid="reset-pwd-input-confirm-pwd" />
                      <button className="btn input-group-addon" onClick={this.toggleFieldType.bind(this, 'confirmPassword')}>
                        <i className={'fa ' + (this.state.confirmPasswordFieldType === 'password' ? 'fa-eye-slash' : 'fa-eye')}></i>
                      </button>
                    </div>
                    <FormErrors formErrors={this.state.step2FormErrors.confirmPassword} 
                      testId="confirm-pwd"/>
                  </div>

                  <div className="col-md-12 text-right">
                    <button type="button" className="btn btn-secondary mr-2"
                      onClick={this.goToLogin.bind(this)}>
                      {i18n.t('noauth.form.button.back')}
                    </button>
                    <button type="button" className="btn btn-primary"
                      disabled={!this.getStep2Validity()}
                      onClick={this.resetPassword.bind(this)} 
                      data-testid="reset-pwd-btn-save">
                      {i18n.t('noauth.form.button.savenewpassword')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default ResetPassword;
