import React from "react";
import BaseScreen from "../../BaseScreen";
import { SCREENS } from "../../../../constants/screens.constant";
import AlertMessage from "../../../../Component/alert/AlertMessage";
import { FormErrors } from "../../../../Component/SubmissionStatus";
import { DevicesService } from "../../../../services/service.devices";
import { StyledText } from "../../../../Component/StyledText";
import i18n from 'i18next';
import LoadingOverlay from "../../../../Component/loader/LoadingOverlay";

/**
 * Activate Device component
 */
class ActivateDevice extends BaseScreen {
    constructor(props) {
        super(props);
        let isAuthenticated = true;
        if (!this.isAuth()) {
            isAuthenticated = false;
            this.goToScreen(SCREENS.login);
        }
        this.state = {
            isAuthenticated: isAuthenticated,
            formfields: {
                serialNumber: '',
                activationCode: ''
            },
            formErrors: { serialNumber: '', activationCode: '' },
            formValid: false,
            isLoading: false,
            alert: {
                type: null,
                message: null
            },
            activationCodeFieldType: "password",
        }
    }

    /**
     * form submit handler
     */
    activateDeviceOnSubmit = (event) => {
        event.preventDefault();
        this.setState({
            isLoading: true
        });
        DevicesService.activateDevice(this.state.formfields).then(
            (theResponse) => {
                let message = i18n.t('userVehicles.devices.alert.activateDevice');
                this.setState({
                    isLoading: false,
                    alert: {
                        type: "success",
                        message: message
                    }
                });
            },
            (error) => {
                let errorMsg = i18n.t('common.genericApiError');
                if (error && error.data && error.data.message) {
                    errorMsg = error.data.message;
                }
                this.setState(
                    {
                        isLoading: false,
                        alert: {
                            type: "danger",
                            message: errorMsg,
                        }
                    });
            }
        );
    }

    /**
     * handle to validate user inputs.
     */
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formfields = this.state.formfields;
        formfields[name] = value;
        let fieldValidationErrors = this.state.formErrors;
        let msg = '';

        switch (name) {
            case 'serialNumber':
                if (value.length === 0) {
                    msg = i18n.t('userVehicles.devices.alert.required');
                } else if (value.length > 32) {
                    msg = i18n.t('userVehicles.devices.alert.serialNumber');
                }
                break;
            case 'activationCode':
                if (value.length === 0) {
                    msg = i18n.t('userVehicles.devices.alert.required');
                } else if (value.length > 32) {
                    msg = i18n.t('userVehicles.devices.alert.activationCode');
                }
                break;
            default:
                break;
        }

        fieldValidationErrors[name] = msg;

        let isFormValid = (formfields.serialNumber && formfields.activationCode) &&
            (fieldValidationErrors.serialNumber === '' && fieldValidationErrors.activationCode === '');

        this.setState({
            formfields: formfields,
            formErrors: fieldValidationErrors,
            formValid: isFormValid,
        });
    }

    /**
     * reset form to previous state
     */
    onResetForm = () => {
        this.setState({
            formfields: {
                serialNumber: '',
                activationCode: ''
            },
            formErrors: {
                serialNumber: '',
                activationCode: ''
            },
            formValid: false
        });
    }

    /**
     * dismiss alerts
     */
    handleAlertDismiss() {
        this.setState({
            alert: {
                type: null,
                message: null
            }
        });
    }

    /**
     * toggle field from normal text to encrypted type.
     * @param {*} fieldName 
     * @param {*} event 
     */
    toggleFieldType(fieldName, event) {
        event.preventDefault();
        if (fieldName === "activationCode") {
            this.setState({ activationCodeFieldType: this.state.activationCodeFieldType === "password" ? "text" : "password" });
        }
    }

    /**
     * activate device form
     */
    renderActivateDeviceForm() {
        return (
            <LoadingOverlay active={this.state.isLoading}>
                <form className="createImplementForm" id="createImplementForm" autoComplete="off">
                    <h2 data-testid="activate-device-heading">
                        {i18n.t('userVehicles.devices.activateDevice')}
                    </h2>
                    <div className="view-profile-details width-70per">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label htmlFor="serialNumber" data-testid="device-label-serial-number">
                                        {i18n.t('userVehicles.devices.form.label.serialNumber')} <StyledText uiText="*" />
                                    </label>
                                    <input type="text" required className="form-control" name="serialNumber"
                                        placeholder={i18n.t('userVehicles.devices.form.placeholder.serialNumber')}
                                        value={this.state.formfields.serialNumber}
                                        onChange={this.handleUserInput} maxLength="32"
                                        data-testid="device-input-serial-number" />
                                    <FormErrors formErrors={this.state.formErrors.serialNumber} />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label htmlFor="activationCode">{i18n.t('userVehicles.devices.form.label.activationCode')} <StyledText uiText="*" /> </label>
                                    <div className="input-group">
                                        <input type={this.state.activationCodeFieldType}
                                            required
                                            className="form-control"
                                            name="activationCode"
                                            placeholder={i18n.t('userVehicles.devices.form.placeholder.activationCode')}
                                            value={this.state.formfields.activationCode}
                                            onChange={this.handleUserInput} maxLength="32"
                                            autoComplete="new-password" 
                                            data-testid="device-input-activation-code"/>
                                        <button onClick={this.toggleFieldType.bind(this, "activationCode")}>
                                            <i className={"fa " + (this.state.activationCodeFieldType === "password" ? "fa-eye-slash" : "fa-eye")}></i>
                                        </button>
                                    </div>
                                    <FormErrors formErrors={this.state.formErrors.activationCode} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 text-right">
                        <div>
                            <button type="button" className="btn btn-secondary mr-2"
                                onClick={this.onResetForm.bind(this)} 
                                data-testid="reset-btn">
                                {i18n.t('userVehicles.devices.form.button.reset')}
                            </button>
                            <button type="submit" className="btn btn-primary"
                                disabled={!this.state.formValid}
                                onClick={this.activateDeviceOnSubmit} 
                                data-testid="device-activate-btn" >
                                {i18n.t('userVehicles.devices.form.button.activate')}
                            </button>
                        </div>
                    </div>
                </form>
            </LoadingOverlay>
        );
    }

    /**
     * react render to render activate device form
     */
    render() {
        return (
            <div className="container-fluid p-4">
                {
                    this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                        type={this.state.alert.type}
                        isAlertOpen={!!(this.state.alert.message)}
                        handleDismiss={this.handleAlertDismiss.bind(this)} 
                        testId="activate-device"
                    />
                }

                {
                    this.renderActivateDeviceForm()
                }
            </div>
        );
    }
}

export default ActivateDevice;
