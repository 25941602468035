import React from "react";
import i18n from 'i18next';
import BaseScreen from "../../BaseScreen";
import { SCREENS } from "../../../../constants/screens.constant";
import AlertMessage from "../../../../Component/alert/AlertMessage";
import { FormErrors } from "../../../../Component/SubmissionStatus";
import { ImplementsService } from "../../../../services/service.implements";
import { StyledText } from "../../../../Component/StyledText";
import LoadingOverlay from "../../../../Component/loader/LoadingOverlay";

/**
 * create Implement components. holds all functionalities for creating an Implement.
 */
class CreateImplement extends BaseScreen {
    constructor(props) {
        super(props);
        let isAuthenticated = true;
        if (!this.isAuth()) {
            isAuthenticated = false;
            this.goToScreen(SCREENS.login);
        }
        this.state = {
            isAuthenticated: isAuthenticated,
            formfields: {
                name: '',
                length: '',
                localId: '',
                position: 1
            },
            formErrors: { name: '', length: '', localId: '', position: '' },
            formValid: false,
            isLoading: false,
            alert: {
                type: null,
                message: null
            }
        }
    }

    /**
   * handles implement submit function. this calls api to create an implement.
   * @param {*} event 
   */
    handleSubmit = (event) => {
        event.preventDefault();

        // set loader on
        this.setState({
            isLoading: true,
        });

        ImplementsService.createImplement(this.state.formfields, ((this.props.location.params && this.props.location.params.accountHolder)
            ?
            this.props.location.params.accountHolder[0].id
            :
            null)).then(
                (theResponse) => {
                    let message = i18n.t('userVehicles.implements.alert.implementCreate');
                    this.setState({
                        isLoading: false,
                        alert: {
                            type: "success",
                            message: message
                        }
                    });
                },
                (error) => {
                    let errorMsg = i18n.t('common.genericApiError');
                    if (error && error.data && error.data.message) {
                        errorMsg = error.data.message;
                    }
                    this.setState(
                        {
                            isLoading: false,
                            alert: {
                                type: "danger",
                                message: errorMsg,
                            }
                        });
                }
            );
    }

    /**
     * helper function to validate decimal values entered.
     * @param {*} num 
     */
    validateDecimalNumbers(num) {
        if ((num.length > 0) &&
            ((isNaN(num) || (num.indexOf(".") === 0) || (num.indexOf(".") === num.length - 1)) ||
                !(/^\d+(\.\d{1,6})?$/.test(num)))) {
            return false;
        }
        return true;
    }

    /**
   * handler to validate form inputes
   */
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formfields = this.state.formfields;
        formfields[name] = value;

        let fieldValidationErrors = this.state.formErrors;
        let msg = '';

        switch (name) {
            case 'name':
                if (value.length === 0) {
                    msg = i18n.t('userVehicles.implements.alert.required');
                } else if (value.length > 64) {
                    msg = i18n.t('userVehicles.implements.alert.char64');
                }
                break;
            case 'length':
                if (value.length < 1) {
                    msg = i18n.t('userVehicles.implements.alert.required');
                } else if (!this.validateDecimalNumbers(value)) {
                    msg = i18n.t('userVehicles.implements.alert.decimal');
                }
                break;
            case 'position':
                if (value.length < 1) {
                    msg = i18n.t('userVehicles.implements.alert.required');
                } else if (value && !value.match(/^[1-2]$/i)) {
                    msg = i18n.t('userVehicles.implements.alert.integerLimit');
                }
                break;
            case 'localId':
                if (value.length > 256) {
                    msg = i18n.t('userVehicles.implements.alert.char256');
                }
                break;
            default:
                break;
        }

        fieldValidationErrors[name] = msg;

        let isFormValid = (formfields.name && formfields.length && formfields.position) && 
                            (fieldValidationErrors.name === '' && fieldValidationErrors.length === '' && 
                            fieldValidationErrors.localId === '' && fieldValidationErrors.position === '');

        this.setState({
            formfields: formfields,
            formErrors: fieldValidationErrors,
            formValid: isFormValid,
        });
    }

    /**
     * reset form handler
     */
    onResetForm = () => {
        this.setState({
            formfields: {
                name: '',
                length: '',
                localId: '',
                position: ''
            },
            formErrors: {
                name: '',
                length: '',
                localId: '',
                position: ''
            },
            formValid: false
        });
    }

    /*
   alert message handling
   */
    handleAlertDismiss() {
        this.setState({
            alert: {
                type: null,
                message: null
            }
        });
    }

    /**
     * create implement form
     */
    renderCreateImplementForm() {
        return (
            <LoadingOverlay active={this.state.isLoading}>
                <form className="createImplementForm" id="createImplementForm">
                    <h2 data-testid="create-implement-heading">
                        {i18n.t('userVehicles.implements.createImplement')}
                    </h2>
                    <div className="view-profile-details width-70per">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label htmlFor="name" data-testid="create-implement-label-name">
                                        {i18n.t('userVehicles.implements.form.label.name')} <StyledText uiText="*" />
                                    </label>
                                    <input type="text" required className="form-control" name="name"
                                        placeholder={i18n.t('userVehicles.implements.form.placeholder.name')}
                                        value={this.state.formfields.name}
                                        onChange={this.handleUserInput} maxLength="32" 
                                        data-testid="create-implement-input-name"/>
                                    <FormErrors formErrors={this.state.formErrors.name} />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label htmlFor="length">{i18n.t('userVehicles.implements.form.label.length')} <StyledText uiText="*" /> </label>
                                    <input type="text" required className="form-control" name="length"
                                        placeholder={i18n.t('userVehicles.implements.form.placeholder.length')}
                                        value={this.state.formfields.length}
                                        onChange={this.handleUserInput} maxLength="32" 
                                        data-testid="create-implement-input-length"/>
                                    <FormErrors formErrors={this.state.formErrors.length} 
                                        testId="implement-length"/>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label htmlFor="position">{i18n.t('userVehicles.implements.form.label.position')} <StyledText uiText="*" /></label>
                                    <input type="text" className="form-control" name="position"
                                        value={this.state.formfields.position}
                                        placeholder={i18n.t('userVehicles.implements.form.placeholder.position')}
                                        onChange={this.handleUserInput} 
                                        data-testid="create-implement-input-position"/>
                                    <FormErrors formErrors={this.state.formErrors.position} 
                                        testId="implement-position"/>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label htmlFor="localId">{i18n.t('userVehicles.implements.form.label.localId')}</label>
                                    <input type="text" className="form-control" name="localId"
                                        value={this.state.formfields.localId}
                                        placeholder={i18n.t('userVehicles.implements.form.placeholder.localId')}
                                        onChange={this.handleUserInput} maxLength="256" 
                                        data-testid="create-implement-input-local-id"/>
                                    <FormErrors formErrors={this.state.formErrors.localId} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 text-right">
                        <div>
                            <button type="button" className="btn btn-secondary mr-2"
                                onClick={this.onResetForm.bind(this)} 
                                data-testid="create-implement-btn-reset">
                                {i18n.t('userVehicles.implements.form.button.reset')}
                            </button>
                            <button type="submit" className="btn btn-primary"
                                disabled={!this.state.formValid} 
                                onClick={this.handleSubmit} 
                                data-testid="create-implement-btn-save">
                                {i18n.t('userVehicles.implements.form.button.save')}
                            </button>
                        </div>
                    </div>
                </form>
            </LoadingOverlay>
        )
    }
    /**
     * react render to render create form
     */
    render() {
        return (
            <div className="container-fluid p-4">
                {
                    this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                        type={this.state.alert.type}
                        isAlertOpen={!!(this.state.alert.message)}
                        handleDismiss={this.handleAlertDismiss.bind(this)} 
                        testId="create-implement"
                    />
                }

                {
                    this.renderCreateImplementForm()
                }
            </div>
        );
    }
}

export default CreateImplement;
