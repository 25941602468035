import { API_INTERFACE } from '../constants/uri.constant';
import baseService from './services';
import { AuthUtils } from './service.auth.utils';

/**
 * service to fetch list of created devices.
 * @param {*} queryString
 */
function getDevices (queryString) {
  const url = API_INTERFACE.GET_DEVICES + '?' + queryString;

  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

/**
 * endpoint to activate device.
 * @param {*} body
 */
function activateDevice (body) {
  const url = API_INTERFACE.ACTIVATE_DEVICE;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.post(url, header, body);
}

export const DevicesService = {
  getDevices,
  activateDevice
};
