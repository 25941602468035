import React from 'react';
import Grid from '../../../Component/grid/Grid';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { TaskService } from '../../../services/service.tasks';
import AlertMessage from '../../../Component/alert/AlertMessage';
import i18n from 'i18next';

/**
 * Informations to be shown in task activities listing grid, along with options per rows in support details page.
 */
let activitiesListConfig = {};

/**
 * List task activities component
 */
class ListTaskActivities extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    // initialize activitiesListConfig with updated i18n resources
    activitiesListConfig = {
      command: {
        label: i18n.t('tasks.list.command'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      },
      executedAt: {
        label: i18n.t('tasks.list.executedAt'),
        sort: false,
        filter: false,
        display: true,
        isDate: true,
        filterMaxLength: 0
      }
    };

    this.state = {
      isAuthenticated: isAuthenticated,
      taskId: '',
      taskActivities: [],
      loader: true,
      pgnConfig: {
        currentPage: 1,
        numberOfPageLinks: 0,
        countPerPage: 10, // default page size
        totalCount: 0,
        pageList: [10]
      },
      alert: {
        type: null,
        message: null
      }
    };
  }

  /**
   * get task activities on mount
   */
  componentDidMount () {
    if (this.state.isAuthenticated) {
      const arg = {
        currentPage: 0,
        pageSize: this.state.pgnConfig.countPerPage
      };

      this.getActivitesByTaskId(arg).then(response => {
        const pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage, this.state.pgnConfig.countPerPage, response.data.total);
        this.setState({
          taskActivities: response.data.items,
          pgnConfig: pgnConfig,
          loader: false
        });
      }, error => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            taskActivities: [],
            loader: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /**
   * get activities by task id
   * @param {*} requiredParams 
   */
  getActivitesByTaskId (requiredParams) {
    const taskId = this.props.taskId;
    let reqQueryParams = '';

    let params;
    if (requiredParams) {
      params = {
        currentPage: requiredParams.currentPage,
        pageSize: requiredParams.pageSize
      };
    } else {
      params = {
        currentPage: this.state.pgnConfig.currentPage - 1,
        pageSize: this.state.pgnConfig.countPerPage
      };
    }

    reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' + params.pageSize;

    return TaskService.getTaskActivities(taskId, reqQueryParams)
      .then(response => {
        return response;
      }, errResponse => {
        return Promise.reject(errResponse);
      });
  }

  /**
   * helper function for pagination configuration
   * @param {*} currentPage 
   * @param {*} countPerPage 
   * @param {*} totalCount 
   */
  setPaginationConfig (currentPage, countPerPage, totalCount) {
    return {
      currentPage: currentPage,
      numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
      countPerPage: countPerPage,
      totalCount: totalCount,
      pageList: [...this.state.pgnConfig.pageList]
    };
  }

  /**
   * handler when page number change, like user move to next page or previous page from current page he is viewing
   * @param {*} pageNumber 
   */
  onPgnChange (pageNumber) {
    if (pageNumber !== this.state.pgnConfig.currentPage) {
      this.setState({ loader: true, taskActivities: [] });

      const arg = {
        currentPage: (pageNumber - 1),
        pageSize: this.state.pgnConfig.countPerPage
      };

      this.getActivitesByTaskId(arg).then(response => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        this.setState({
          taskActivities: response.data.items,
          pgnConfig: pgnConfig,
          loader: false,
          alert: {
            type: null,
            message: null
          }
        });
      }, error => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            taskActivities: [],
            loader: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /*
   alert message handling
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render function for redering a listing of tasks activities
   */
  render () {
    return (
      <div>
        {
          this.state.alert.message &&
          <AlertMessage message={this.state.alert.message}
            type={this.state.alert.type}
            isAlertOpen={!!(this.state.alert.message)}
            handleDismiss={this.handleAlertDismiss.bind(this)}
          />
        }
        <div>
          <Grid id="taskActivitiesListGrid"
            keyColumn="id"
            rowData={this.state.taskActivities}
            columnDefs={activitiesListConfig}
            loading={this.state.loader}
            pagination={true}
            pgnConfig={this.state.pgnConfig}
            onPgnChange={this.onPgnChange.bind(this)} />
        </div>
      </div>
    );
  }
}

export default ListTaskActivities;
