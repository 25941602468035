import axios from 'axios';
import _ from 'lodash';
import { AppStorageHelper } from '../shared/utils/appStorageHelper';
import { AuthUtils } from './service.auth.utils';
import { AuthenticationServices } from './service.authentication';
import { API_ERROR_CODES } from '../constants/app.constants';
import { REFRESH_TOKEN_PATH } from '../constants/uri.constant';

/**
 * http status codes to check: Unauthorized
 */
const defaults = {
  statusCodes: [
    401
  ]
};

/**
 An axios response interceptor to intercept error responses, it handles the http status code 401.
 On receiving  401 code, it executes refreshToken call to refetch the access_token and refresh_token.
 After refreshToken call is completed, any pending api calls are re-executed.
 Reject promise if the error status is not the one in -
    1. options or defaults
    2. apiResponseErrCode is not "API_19"

 */
function createAuthRefreshInterceptor (axios, refreshTokenCall, options = {}) {
  const id = axios.interceptors.response.use(res => res, error => {
    const statusCodes = options.hasOwnProperty('statusCodes') && options.statusCodes.length
      ? options.statusCodes
      : defaults.statusCodes;
    const apiResponseErrCode = _.has(error, 'response.data.error') ? error.response.data.error : undefined;
    if (!error.response || (error.response.status && statusCodes.indexOf(+error.response.status) === -1) ||
      (apiResponseErrCode !== API_ERROR_CODES.API_19)) {
      return Promise.reject(error);
    }

    /**
     * remove the interceptor to prevent a loop in case token refresh also causes the 401
     */
    axios.interceptors.response.eject(id);

    const refreshCall = refreshTokenCall(error);

    /**
     * create interceptor that will bind all the others requests until refreshTokenCall is resolved
     */
    const requestQueueInterceptorId = axios.interceptors
      .request
      .use(request => refreshCall.then(() => {
        if (_.has(request, 'headers.Authorization')) {
          request.headers.Authorization = AuthUtils.getAccessToken();
        }
        return request;
      }));

    /**
     * when response code is 401 (Unauthorized), try to refresh the token.
     */
    return refreshCall.then(() => {
      axios.interceptors.request.eject(requestQueueInterceptorId);
      return axios(error.response.config);
    }).catch(errResponse => {
      axios.interceptors.request.eject(requestQueueInterceptorId);

      const reqUrl = _.has(errResponse, 'config.url') ? errResponse.config.url : '';
      /**
       * if refresh_token fails, move to login page
       */
      if (_.isString(reqUrl) && (reqUrl.indexOf(REFRESH_TOKEN_PATH) > -1)) {
        AppStorageHelper.clearApplicationData();
        window.location.href = '#/login';
      }
      return Promise.reject(errResponse);
    }).finally(() => {
      createAuthRefreshInterceptor(axios, refreshTokenCall, options);
    });
  });
  return axios;
}

/**
 * endpoint refresh_token api call
 * update "Authorization" token for failed request.
 * @param {*} failedRequest 
 */
const callRefreshAccessToken = failedRequest => AuthenticationServices.refreshToken()
  .then(resonse => {
    console.log('refreshing api access_token ...');
    if (_.has(failedRequest, 'response.config.headers.Authorization')) {
      failedRequest.response.config.headers.Authorization = AuthUtils.getAccessToken();
    }
    return Promise.resolve();
  });

/**
  * execute the interceptor to take effect
  */
createAuthRefreshInterceptor(axios, callRefreshAccessToken);
