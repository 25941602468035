import React from 'react';
import queryString from 'query-string';
import _ from 'lodash';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { FormErrors } from '../../../Component/SubmissionStatus';
import { UserService } from '../../../services/service.users';
import { CommonUtilities } from '../../../shared/utils/commonUtilities';
import { AppStorageHelper } from '../../../shared/utils/appStorageHelper';
import AlertMessage from '../../../Component/alert/AlertMessage';
import i18n from 'i18next';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';

const DELAY_AFTER_PROFILE_UPDATE = 10000; // 10 seconds

/**
 * Force Change Password component.
 */
class ForceChangePassword extends BaseScreen {
  constructor (props) {
    super(props);
    this.state = {
      isWaitingApiResponse: false,
      formFields: {
        email: '',
        tempPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      formErrors: {
        email: '',
        tempPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      formValid: false,
      alertMessage: {
        message: '',
        type: ''
      },
      tempPasswordFieldType: 'password',
      newPasswordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      disableAllApiHandlers: false
    };

    this.profileUpdatedTimer = null;
  }

  /**
   * check url query params (if emailId and resetCode is provided via email link)
   */
  componentDidMount () {
    const urlParams = this.props.routerData.location.search;
    const parsedValues = queryString.parse(urlParams);
    const emailIdInQueryString = _.trim(parsedValues.email);
    this.setState({
      formFields: {
        tempPassword: '',
        email: emailIdInQueryString,
        newPassword: '',
        confirmPassword: ''
      }
    });
  }

  /**
   * cleanup resources
   */
  componentWillUnmount () {
    clearTimeout(this.profileUpdatedTimer);
  }

  /**
   * redirect to login page
   */
  goToLogin () {
    AppStorageHelper.clearApplicationData();
    this.goToScreen(SCREENS.login);
  }

  /**
   * validate form for valid data
   * @param {*} event 
   */
  handleUserInput (event) {
    event.preventDefault();

    const name = event.target.name;
    const value = _.trim(event.target.value);

    const formFields = this.state.formFields;
    formFields[name] = value;

    const fieldValidationErrors = { ...this.state.formErrors };
    let msg = '';

    const newPwd = formFields.newPassword;
    const cnfPwd = formFields.confirmPassword;

    switch (name) {
      case 'tempPassword':
        if (value.length === 0) {
          msg = i18n.t('noauth.alert.required');
        }
        fieldValidationErrors[name] = msg;
        break;

      case 'email':
        if (value.length === 0) {
          msg = i18n.t('noauth.alert.required');
        } else if (value.length > 320) {
          msg = i18n.t('noauth.alert.email320');
        } else if (!CommonUtilities.isValidEmail(value)) {
          msg = i18n.t('noauth.alert.emailInvalid');
        }
        fieldValidationErrors[name] = msg;
        break;

      case 'newPassword':
      case 'confirmPassword':
        if (value.length === 0) {
          msg = i18n.t('noauth.alert.required');
        } else if (value.length < 8) {
          msg = i18n.t('noauth.alert.passwordMin');
        } else if (name === 'newPassword' && cnfPwd) {
          // handle password mismatch
          if (cnfPwd !== value) {
            msg = i18n.t('noauth.alert.passConfirmPassNoMatch');
          } else {
            fieldValidationErrors.confirmPassword = '';
          }
        } else if (name === 'confirmPassword' && newPwd) {
          // handle password mismatch
          if (newPwd !== value) {
            msg = i18n.t('noauth.alert.passConfirmPassNoMatch');
          } else {
            fieldValidationErrors.newPassword = '';
          }
        }
        fieldValidationErrors[name] = msg;
        break;

      default:
        break;
    }

    const isFormValid = (formFields.tempPassword && formFields.email && formFields.newPassword && formFields.confirmPassword) &&
      (fieldValidationErrors.tempPassword === '' && fieldValidationErrors.email === '' && fieldValidationErrors.newPassword === '' &&
        fieldValidationErrors.confirmPassword === '');

    this.setState({
      formFields: formFields,
      formErrors: fieldValidationErrors,
      formValid: isFormValid
    });
  }

  /**
   * check if form is valid
   */
  getFormValidity () {
    let valid = false;
    if (this.state.formValid) {
      valid = true;
    }

    return valid && !this.state.disableAllApiHandlers;
  }

  /**
   * handles submit functionality, communicate with backend and update the password
   */
  submitHandler () {
    this.setState({ isWaitingApiResponse: true });

    UserService.forceChangePassword(this.state.formFields.email,
      this.state.formFields.tempPassword, this.state.formFields.newPassword).then(
      response => {
        // handle success case
        this.setState({
          isWaitingApiResponse: false,
          disableAllApiHandlers: true,
          alertMessage: {
            message: i18n.t('noauth.alert.profileUpdateSuccess'),
            type: 'success'
          }
        });

        /**
         * go back to login screen
         */
        this.profileUpdatedTimer = setTimeout(() => this.goToLogin(), DELAY_AFTER_PROFILE_UPDATE);
      },
      error => {
        // handle error message
        let alertMsg = i18n.t('common.genericApiError');

        if (error && error.data && error.data.message) {
          alertMsg = error.data.message;
        }

        this.setState({
          isWaitingApiResponse: false,
          alertMessage: {
            message: alertMsg,
            type: 'danger'
          }
        });
      }
    );
  }

  /*
   alert message handling
   */
  handleDismiss () {
    this.setState({
      alertMessage: {
        type: null,
        message: null
      }
    });
  }

  /**
   * toggle view from plain text to encrypted
   * @param {*} fieldName 
   * @param {*} event 
   */
  toggleFieldType (fieldName, event) {
    event.preventDefault();

    if (fieldName === 'tempPassword') {
      this.setState({ tempPasswordFieldType: this.state.tempPasswordFieldType === 'password' ? 'text' : 'password' });
    } else if (fieldName === 'newPassword') {
      this.setState({ newPasswordFieldType: this.state.newPasswordFieldType === 'password' ? 'text' : 'password' });
    } else if (fieldName === 'confirmPassword') {
      this.setState({ confirmPasswordFieldType: this.state.confirmPasswordFieldType === 'password' ? 'text' : 'password' });
    }
  }

  /**
   * react render function to show change password form
   */
  render () {
    return (
      <LoadingOverlay active={this.state.isWaitingApiResponse} text="Your new password is being processed.  This may take a moment">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <br />
              <h3>{i18n.t('noauth.forceChangePassword')}</h3>
              {
                this.state.alertMessage.message &&
                <AlertMessage message={this.state.alertMessage.message}
                  type={this.state.alertMessage.type}
                  isAlertOpen={!!(this.state.alertMessage.message)}
                  handleDismiss={this.handleDismiss.bind(this)}
                />
              }
              <form autoComplete="off">
                <div className="form-group">
                  <label htmlFor="tempPassword">{i18n.t('noauth.form.label.tempPassword')} *</label>
                  <input type={this.state.tempPasswordFieldType} required className="form-control"
                    name="tempPassword"
                    placeholder={i18n.t('noauth.form.placeholder.tempPassword')}
                    value={this.state.formFields.tempPassword}
                    onChange={this.handleUserInput.bind(this)} minLength="8" />
                  <button onClick={this.toggleFieldType.bind(this, 'tempPassword')}>
                    <i className={'fa ' + (this.state.tempPasswordFieldType === 'password' ? 'fa-eye-slash' : 'fa-eye')}></i>
                  </button>
                  <FormErrors formErrors={this.state.formErrors.tempPassword} />
                </div>

                <div className="form-group">
                  <label htmlFor="email">{i18n.t('noauth.form.label.emailId')} *</label>
                  <input type="text" required className="form-control" name="email"
                    placeholder={i18n.t('noauth.form.placeholder.emailId')}
                    value={this.state.formFields.email}
                    onChange={this.handleUserInput.bind(this)}
                    maxLength="320" />
                  <FormErrors formErrors={this.state.formErrors.email} />
                </div>

                <div className="form-group">
                  <label htmlFor="newPassword">{i18n.t('noauth.form.label.newPassword')} *</label>
                  <input type={this.state.newPasswordFieldType} required className="form-control" name="newPassword"
                    placeholder={i18n.t('noauth.form.placeholder.newPassword')}
                    value={this.state.formFields.newPassword}
                    onChange={this.handleUserInput.bind(this)} minLength="8" />
                  <button onClick={this.toggleFieldType.bind(this, 'newPassword')}>
                    <i className={'fa ' + (this.state.newPasswordFieldType === 'password' ? 'fa-eye-slash' : 'fa-eye')}></i>
                  </button>
                  <FormErrors formErrors={this.state.formErrors.newPassword} />
                </div>

                <div className="form-group">
                  <label htmlFor="confirmPassword">{i18n.t('noauth.form.label.confirmPassword')} *</label>
                  <input type={this.state.confirmPasswordFieldType} required className="form-control" name="confirmPassword"
                    placeholder={i18n.t('noauth.form.placeholder.confirmPassword')}
                    value={this.state.formFields.confirmPassword}
                    onChange={this.handleUserInput.bind(this)} minLength="8" />
                  <button onClick={this.toggleFieldType.bind(this, 'confirmPassword')}>
                    <i className={'fa ' + (this.state.confirmPasswordFieldType === 'password' ? 'fa-eye-slash' : 'fa-eye')}></i>
                  </button>
                  <FormErrors formErrors={this.state.formErrors.confirmPassword} />
                </div>

                <div className="col-md-12 text-right">
                  <button type="button" className="btn btn-secondary mr-2"
                    onClick={this.goToLogin.bind(this)}>
                    {i18n.t('noauth.form.button.back')}
                  </button>
                  <button type="button" className="btn btn-primary"
                    disabled={!this.getFormValidity()}
                    onClick={this.submitHandler.bind(this)}>
                    {i18n.t('noauth.form.button.save')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default ForceChangePassword;
