import { combineReducers } from 'redux';
import userInfo from './userInfoReducer';
import selectedAccHolderInfo from './accHolderInfoReducer'

/**
 * Description: Combining reducer
 */
const reducer = combineReducers({
  user: userInfo,
  selectedAccHolder: selectedAccHolderInfo
});

export default reducer;
