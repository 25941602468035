import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import i18n from 'i18next';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { UserService } from '../../../services/service.users';
import { VehiclesService } from '../../../services/service.vehicles';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import AlertMessage from '../../../Component/alert/AlertMessage';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import { ROLE_TYPES } from '../../../constants/app.constants';
import { CalibrationsService } from '../../../services/service.calibrations';
import { CommonUtilities } from '../../../shared/utils/commonUtilities';

/**
 * Component to display calibration details.
 */
class ViewCalibration extends BaseScreen {
  /**
   * Component initialization
   * @param {object} props
   */
  constructor(props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    let calibrationId = '';
    if (_.has(this.props, 'match.params.calibrationId')) {
      calibrationId = this.props.match.params.calibrationId;
    }

    this.state = {
      isAuthenticated: isAuthenticated,
      isLoading: true,
      calibrationId: calibrationId,
      calibrationData: {},
      calibrationInternalData: {
        vehicleMake: i18n.t('calibrations.form.loadingText'),
        vehicleModel: i18n.t('calibrations.form.loadingText'),
        userEmail: i18n.t('calibrations.form.loadingText')
      },
      isErrorOnLoad: false,
      alert: {
        type: null,
        message: null
      }
    };
  }

  /**
   * React component life cycle method called after first render. Communicate with
   * backend to fetch details of a calibration based on calibrationId
   */
  componentDidMount() {
    if (this.state.isAuthenticated) {
      CalibrationsService.getCalibration(this.state.calibrationId)
        .then(response => {
          this.handleCalibrationInfo(response.data);
        }, error => {
          let errorMsg = i18n.t('common.genericApiError');
          if (error && error.data && error.data.message) {
            errorMsg = error.data.message;
          }

          this.handleCalibrationError(errorMsg);
        });
    }
  }

  /**
   * function to display calibration data in ui once available.
   * @param {object} calibData
   */
  handleCalibrationInfo(calibData) {
    this.setState({
      calibrationData: calibData,
      isLoading: false
    }, () => {
      this.getCalibrationInternalData();
    });
  }

  /**
   * function to communicate with backend to fetch other informations 
   * related to a calibration
   */
  getCalibrationInternalData() {
    // get vehicle make
    VehiclesService.getVehicle(this.state.calibrationData.vehicleId).then(response => {
      let calibrationInternalData = this.state.calibrationInternalData;
      calibrationInternalData.vehicleMake = response.data.make;
      calibrationInternalData.vehicleModel = response.data.model;
      this.setState({
        calibrationInternalData: calibrationInternalData
      });
    }, error => {
      let calibrationInternalData = this.state.calibrationInternalData;
      calibrationInternalData.vehicleMake = i18n.t('calibrations.form.notAvailable');
      calibrationInternalData.vehicleModel = i18n.t('calibrations.form.notAvailable');
      this.setState({
        calibrationInternalData: calibrationInternalData
      });
    });

    // get and set user email for the calibration (for userId data field)
    this.getCalibrationUserEmail(this.state.calibrationData.userId);
  }

  /**
   * Fetch email for the userId field of calibration data.
   * @param {string} calibrationUserId
   */
  getCalibrationUserEmail(calibrationUserId) {
    if (_.isEmpty(this.props.userInfo) || !_.isObject(this.props.userInfo)) {
      this.setCalibrationUserEmail(i18n.t('calibrations.form.notAvailable'));
    } else {
      let userId = this.props.userInfo.id;
      // check if calibration "userId" belongs to the loggedin user
      if (userId === calibrationUserId) {
        this.setCalibrationUserEmail(this.props.userInfo.email);
      } else {
        // else fetch the user assignments for the logged in User (accountHoldersList for an operator or 
        // operatorList for an accountHolder), then find the user whose userId matches with 
        // the calibration "userId"
        let forUserType = "&accountId=";
        if (this.props.userInfo.roleId === ROLE_TYPES.OPERATOR.ID) {
          forUserType = "&operatorId=";
        }
        // as getAssignementsForUser call is a paginated api call, setting 
        // pageSize to be more than the maximum possible
        let reqQueryParams = "currentPage=0&pageSize=100" + forUserType + userId;

        UserService.getAssignementsForUser(userId, reqQueryParams).then(
          response => {
            let matchedUser;
            if (_.has(response, 'data.items') && _.isArray(response.data.items)) {
              matchedUser = _.find(response.data.items, function (obj) {
                return (obj.userId === calibrationUserId);
              });
            }

            let emailToDisplay = _.has(matchedUser, 'email') ? matchedUser.email : i18n.t('calibrations.form.notAvailable');
            this.setCalibrationUserEmail(emailToDisplay);
          },
          errResponse => {
            this.setCalibrationUserEmail(i18n.t('calibrations.form.notAvailable'));
          });
      }
    }
  }

  /**
   * Update calibration internal data for user Email in the component state.
   * @param {string} email
   */
  setCalibrationUserEmail(email) {
    let calibrationInternalData = this.state.calibrationInternalData;
    calibrationInternalData.userEmail = email;
    this.setState({
      calibrationInternalData: calibrationInternalData
    });
  }

  /**
   * function to handle calibration retrieval errors.
   * @param {string} errorMsg
   */
  handleCalibrationError(errorMsg) {
    this.setState({
      isLoading: false,
      isErrorOnLoad: true,
      alert: {
        type: 'danger',
        message: errorMsg
      }
    });
  }

  /**
   * go to previous page.
   */
  handleBack = () => {
    this.props.history.goBack();
  }

  /*
   * alert message handling
   */
  handleAlertDismiss = () => {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * Check if calibration field value is empty. For empty 
   * values, return "N/A".
   */
  handleEmptyCalibrationValue = (val) => {
    if (_.isEmpty(this.state.calibrationData)) {
      return val;
    } else {
      return CommonUtilities.isEmpty(val) ? i18n.t('calibrations.form.notAvailable') : val;
    }
  }

  /**
   * Calibration details ui layout.
   */
  renderCalibrationDetails() {
    return (
      <React.Fragment>
        <h2>
          {i18n.t('calibrations.viewCalibration')}
        </h2>
        <div className="view-profile-details width-70per">
          <div className="row">
            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.type')}
              </label>
              <p>
                {this.handleEmptyCalibrationValue(this.state.calibrationData.calibrationType)}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.status')}
              </label>
              <p>
                {this.handleEmptyCalibrationValue(this.state.calibrationData.calibrationStatus)}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.ecuId')}
              </label>
              <p>
                {this.handleEmptyCalibrationValue(this.state.calibrationData.ecuId)}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.leftRadius')}
              </label>
              <p>
                {this.handleEmptyCalibrationValue(this.state.calibrationData.leftRadius)}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.lockLeft')}
              </label>
              <p>
                {this.handleEmptyCalibrationValue(this.state.calibrationData.lockToLockLeft)}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.lockRight')}
              </label>
              <p>
                {this.handleEmptyCalibrationValue(this.state.calibrationData.lockToLockRight)}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.pitchBias')}
              </label>
              <p>
                {this.handleEmptyCalibrationValue(this.state.calibrationData.pitchBias)}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.profileVersion')}
              </label>
              <p>
                {this.handleEmptyCalibrationValue(this.state.calibrationData.profileVersion)}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.rightRadius')}
              </label>
              <p>
                {this.handleEmptyCalibrationValue(this.state.calibrationData.rightRadius)}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.rollBias')}
              </label>
              <p>
                {this.handleEmptyCalibrationValue(this.state.calibrationData.rollBias)}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.steeringRatio')}
              </label>
              <p>
                {this.handleEmptyCalibrationValue(this.state.calibrationData.steeringRatio)}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.tiltPointALatitude')}
              </label>
              <p>
                {this.handleEmptyCalibrationValue(this.state.calibrationData.tiltPointALat)}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.tiltPointALongitude')}
              </label>
              <p>
                {this.handleEmptyCalibrationValue(this.state.calibrationData.tiltPointALong)}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.tiltPointBLatitude')}
              </label>
              <p>
                {this.handleEmptyCalibrationValue(this.state.calibrationData.tiltPointBLat)}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.tiltPointBLongitude')}
              </label>
              <p>
                {this.handleEmptyCalibrationValue(this.state.calibrationData.tiltPointBLong)}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.userEmail')}
              </label>
              <p>
                {this.state.calibrationInternalData.userEmail}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.vehicleMake')}
              </label>
              <p>
                {this.state.calibrationInternalData.vehicleMake}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">
                {i18n.t('calibrations.form.label.vehicleModel')}
              </label>
              <p>
                {this.state.calibrationInternalData.vehicleModel}
              </p>
            </div>

          </div>
        </div>
      </React.Fragment>
    );
  }

  /**
   * ui layout for error scenario.
   */
  renderError() {
    return (
      <div className="col-md-12 text-right f-btn">
        <button className="btn btn-secondary mr-2" onClick={this.handleBack}>
          {i18n.t('calibrations.form.button.back')}
        </button>
      </div>
    );
  }

  /**
   * react render function
   */
  render() {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('calibrations.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'calibration', displayName: i18n.t('calibrations.breadcrumb.calibration'), className: '', link: false },
      { id: 'detail', displayName: i18n.t('calibrations.breadcrumb.detail'), className: '', link: false }
    ];

    return (
      <div className="container-fluid p-4">
        <BreadcrumbCustom breadcrumb={breadcrumb} />
        <div className="clearfix"></div>
        {
          this.state.alert.message &&
          <AlertMessage message={this.state.alert.message}
            type={this.state.alert.type}
            isAlertOpen={!!(this.state.alert.message)}
            handleDismiss={this.handleAlertDismiss}
          />
        }

        <LoadingOverlay active={this.state.isLoading}>
          {
            this.state.isErrorOnLoad ? this.renderError() : this.renderCalibrationDetails()
          }
        </LoadingOverlay>
      </div>
    );
  }
}

/**
 * From the redux store, map "user and selectedAccHolder" to the props of the component.
 * @param {object}
 * @return {object}
 */
function mapStateToProps({ user, selectedAccHolder }) {
  return {
    userInfo: user.userInfo,
    selectedAccHolderInfo: selectedAccHolder.selectedAccHolderInfo
  };
}

export default connect(mapStateToProps, null)(ViewCalibration);
