import React from 'react';
import _ from 'lodash';
import BaseScreen from "../../BaseScreen";
import { SCREENS } from "../../../../constants/screens.constant";
import { VehiclesService } from "../../../../services/service.vehicles";
import AlertMessage from "../../../../Component/alert/AlertMessage";
import { FormErrors } from "../../../../Component/SubmissionStatus";
import { ROLE_TYPES, CommonConstants } from "../../../../constants/app.constants";
import AppInfoHelper from "../../../../shared/utils/appInfoHelper";
import { CommonUtilities } from "../../../../shared/utils/commonUtilities";
import { StyledText } from "../../../../Component/StyledText";
import i18n from 'i18next';
import LoadingOverlay from "../../../../Component/loader/LoadingOverlay";

/**
 * Edit vehicle components. holds all functionalities for editing a vehicle.
 */
class EditVehicle extends BaseScreen {
  constructor(props) {
    super(props);
    let isAuthenticated = true;

    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    this.state = {
      isAuthenticated: isAuthenticated,
      formfields: {
        make: "",
        model: "",
        year: "",
        type: "",
        localId: "",
        steeringWheelType: "",
        wheelbase: "",
        antennaHeight: "",
        antennaPivot: "",
        antennaOffset: "",
        frontHitch: "",
        rearHitch: "",
        attack: "",
        backlash: "",
        sensitivity: "",
        profileVersion: "",
        ecuOrientation: ""
      },
      formErrors: {
        make: "",
        model: "",
        year: "",
        type: "",
        localId: "",
        steeringWheelType: "",
        wheelbase: "",
        antennaHeight: "",
        antennaPivot: "",
        antennaOffset: "",
        frontHitch: "",
        rearHitch: "",
        attack: "",
        backlash: "",
        sensitivity: "",
        profileVersion: "",
      },
      formValid: false,

      // for alert messages
      alert: {
        type: null,
        message: null
      },

      isWaitingApiResponse: false,
      isLoading: true,
      savedFormFields: {},
      /**
       * to check if initial vehicle info available or not
       */
      isErrorOnLoad: false,
      isDefault: false,
      /**
       * for enabling save button by default (in case of default vehicle assignment)
       */
      isFormSavedSuccessfully: false,
      xyStickeFace: "", 
      cableAttachDirection: ""
    }

    this.vehicleTypes = CommonConstants.getVehicleTypes();
    this.ecuOrientationTypes = CommonConstants.getEcuOrientationTypes();
    this.xyStickeFaceTypes = CommonConstants.getXYStickeFace();
    this.cableAttachDirectionTypes = CommonConstants.getCableAttachDirection();
  }

  /**
   * utility function to remove null values, this utility function works on single object 
   * @param {*} obj 
   */
  removeNullValues(obj) {
    let newObj = _.mapValues(obj, (value, key) => {
      if (key !== "accountId" && key !== "operatorId") {
        if ((value === null) || (value === undefined)) {
          value = "";
        }
      }
      return value;
    });
    return newObj;
  }

  componentDidMount() {
    let self = this;
    
    if (this.state.isAuthenticated) {
      let userInfoInstance = AppInfoHelper.getUserInstance();
      userInfoInstance.getUserInfo().then(function (response) {
        let userInfo = response;
        let selectedAccountHolder = userInfoInstance.getSelectedAccountHolderForOperator();
        if (userInfo.roleId === ROLE_TYPES.OPERATOR.ID && !selectedAccountHolder) {
          //
          let warningMsg = i18n.t('userVehicles.vehicles.alert.selectAccountholder');
          self.setState(
            {
              isLoading: false,
              loggedInUserInfo: userInfo,
              selectedAccountHolder: selectedAccountHolder,
              alert: {
                type: "danger",
                message: warningMsg,
              }
            });
        } else {
          self.setState(
            {
              loggedInUserInfo: userInfo,
              selectedAccountHolder: selectedAccountHolder
            });
          self.displayVehicleInfo();
        }
      }, function (error) {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        self.setState(
          {
            vehicleData: {},
            isLoading: false,
            isErrorOnLoad: true,
            alert: {
              type: "danger",
              message: errorMsg
            },
          });
      });
    }
  }

  /**
   * fetch specific vehicle data from backend
   */
  displayVehicleInfo() {
    let vehicleData;
    let vehicleId = this.props.match.params.vehicleId;
    VehiclesService.getVehicle(vehicleId).then(response => {
      vehicleData = this.removeNullValues(response.data);
      this.setState({
        formfields: vehicleData,
        savedFormFields: { ...vehicleData },
        isLoading: false,
        isDefault: CommonUtilities.isDefaultVehicle(vehicleData),
        xyStickeFace: CommonUtilities.getxyStickeFace(vehicleData.ecuOrientation), 
        cableAttachDirection: CommonUtilities.getcableAttachDirection(vehicleData.ecuOrientation)
      });
    }, error => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }

      this.setState({
        vehicleData: {},
        isLoading: false,
        isErrorOnLoad: true,
        apiResponseMsg: errorMsg,
        alert: {
          type: "danger",
          message: errorMsg
        },
      });
    });
  }

  /**
   * helper function to validate decimal values entered.
   * @param {*} num 
   */
  validateDecimalNumbers(num) {
    if ((num.length > 0) &&
      ((isNaN(num) || (num.indexOf(".") === 0) || (num.indexOf(".") === num.length - 1)) ||
        !(/^\d+(\.\d{1,6})?$/.test(num)))) {
      return false;
    }

    return true;
  }

  /**
   * handler to validate form inputes
   */
  handleUserInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    
    let formfields = this.state.formfields;
    let xyStickeFace = this.state.xyStickeFace;
    let cableAttachDirection = this.state.cableAttachDirection;
    if(name === 'xyStickeFace') {
      xyStickeFace = value
    } else if(name === 'cableAttachDirection') {
      cableAttachDirection = value
    } else {
      formfields[name] = value;
    }

    let fieldValidationErrors = this.state.formErrors;
    let msg = "";

    switch (name) {
      case 'make':
      case 'model':
        if (value.length === 0) {
          msg = i18n.t('userVehicles.vehicles.alert.required');
        } else if (value.length > 32) {
          msg = i18n.t('userVehicles.vehicles.alert.char32');
        }
        break;

      case 'year':
        let parsedValue = parseInt(value, 10);

        if ((value.length > 0) && (!(/^\d+$/.test(value)) || (parsedValue < 1970) || (parsedValue > 9999))) {
          msg = i18n.t('userVehicles.vehicles.alert.year');
        }
        break;

      case 'type':
        if (value === "") {
          msg = i18n.t('userVehicles.vehicles.alert.type');
        }
        break;

      case 'localId':
        if (value.length > 256) {
          msg = i18n.t('userVehicles.vehicles.alert.char256');
        }
        break;

      case 'steeringWheelType':
        if (value.length > 32) {
          msg = i18n.t('userVehicles.vehicles.alert.char32');
        }
        break;

      case 'wheelbase':
      case 'antennaHeight':
      case 'antennaPivot':
      case 'antennaOffset':
      case 'frontHitch':
      case 'rearHitch':
        if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('userVehicles.vehicles.alert.decimal');
        }
        break;

      case 'attack':
        if (value.length === 0) {
          msg = i18n.t('userVehicles.vehicles.alert.required');
        } else if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('userVehicles.vehicles.alert.decimal');
        } else if (value > 70 || value < 1) {
          msg = i18n.t('userVehicles.vehicles.alert.attack');
        }
        break;

      case 'backlash':
        if (value.length === 0) {
          msg = i18n.t('userVehicles.vehicles.alert.required');
        } else if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('userVehicles.vehicles.alert.decimal');
        } else if (value > 90 || value < 0) {
          msg = i18n.t('userVehicles.vehicles.alert.backlash');
        }
        break;

      case 'sensitivity':
        if (value.length === 0) {
          msg = i18n.t('userVehicles.vehicles.alert.required');
        } else if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('userVehicles.vehicles.alert.decimal');
        } else if (value > 50 || value < 0) {
          msg = i18n.t('userVehicles.vehicles.alert.sensitivity');
        }
        break;

      case 'profileVersion':
        if (value.length > 64) {
          msg = i18n.t('userVehicles.vehicles.alert.char64');
        }
        break;

      default:
        break;
    }

    fieldValidationErrors[name] = msg;

    let isFormValid = (formfields.make && formfields.model && formfields.type && formfields.attack && 
                        !CommonUtilities.isEmpty(formfields.backlash) && 
                        !CommonUtilities.isEmpty(formfields.sensitivity)) &&
      (fieldValidationErrors.make === "" && fieldValidationErrors.model === "" && fieldValidationErrors.year === "" &&
        fieldValidationErrors.type === "" && fieldValidationErrors.localId === "" && fieldValidationErrors.steeringWheelType === "" &&
        fieldValidationErrors.wheelbase === "" && fieldValidationErrors.antennaHeight === "" && fieldValidationErrors.antennaPivot === "" &&
        fieldValidationErrors.antennaOffset === "" && fieldValidationErrors.frontHitch === "" && fieldValidationErrors.rearHitch === "" &&
        fieldValidationErrors.profileVersion === "" && fieldValidationErrors.attack === "" && 
        fieldValidationErrors.backlash === "" && fieldValidationErrors.sensitivity === "");

    this.setState({
      formfields: formfields,
      formErrors: fieldValidationErrors,
      formValid: isFormValid,
      xyStickeFace: xyStickeFace,
      cableAttachDirection: cableAttachDirection
    });
  }

  /**
   * handles vehicle submit function. this calls api to upload the updated vehicle.
   * update own vehicle or assign default vehicle
   * @param {*} event 
   */
  handleSubmit(event) {
    event.preventDefault();
    let that = this;

    that.setState({
      isWaitingApiResponse: true
    });
    that.updateVehicle().then(function (success) {
      let lastSavedFormFields = that.state.formfields;
      let successMsg = that.state.isDefault ? 
                          i18n.t('userVehicles.vehicles.alert.vehicleAssign') : i18n.t('userVehicles.vehicles.alert.vehicleUpdate');
      that.setState({
        alert: {
          type: "success",
          message: successMsg
        },
        isWaitingApiResponse: false,
        savedFormFields: { ...lastSavedFormFields },
        isFormSavedSuccessfully: true
      });
    }, function (error) {
      let alertMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        alertMsg = error.data.message;
      }
      that.setState({
        alert: {
          type: "danger",
          message: alertMsg
        },
        isWaitingApiResponse: false
      });
    });
  }

  /**
   * update vehicle, assign default vehicle or update own/assigned vehicle
   */
  updateVehicle() {
    let reqParams = _.cloneDeep(this.state.formfields);

    if (reqParams.accountId === null || reqParams.accountId === undefined) {
      let accountId = "";
      let operatorId = "";
      if (this.state.loggedInUserInfo.roleId === ROLE_TYPES.OPERATOR.ID) {
        accountId = this.state.selectedAccountHolder.id;
        operatorId = this.state.loggedInUserInfo.id;
      } else {
        accountId = this.state.loggedInUserInfo.id;
        operatorId = null;
      }
      reqParams.accountId = accountId;
      reqParams.operatorId = operatorId;
    }

    if (this.state.isDefault) {
      reqParams.ecuOrientation="CONNECTOR_" + this.state.cableAttachDirection.toLowerCase() + "_TOP_" + this.state.xyStickeFace.toLowerCase()
      return VehiclesService.updateVehicleAssignment(reqParams).then(function (response) {
        return response;
      }, function (error) {
        return Promise.reject(error);
      });
    } else {
      return VehiclesService.updateVehicle(reqParams).then(function (response) {
        return response;
      }, function (error) {
        return Promise.reject(error);
      });
    }
  }

  /*
   alert message handling
   */
  handleAlertDismiss() {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * helper function
   * @param {*} vehicleObj1 
   * @param {*} vehicleObj2 
   */
  isEqual(vehicleObj1, vehicleObj2) {
    let result = true;
    _.forOwn(vehicleObj1, function (value, key) {
      if (_.toString(value) !== _.toString(vehicleObj2[key])) {
        result = false;
      }
    });

    return result;
  }

  /**
   * check if valid form
   */
  isFormValid() {
    let result = this.isEqual(this.state.formfields, this.state.savedFormFields);
    let returnVal = (!result && this.state.formValid) ||
      (this.state.isDefault && !this.state.isFormSavedSuccessfully);
    return returnVal;
  }

  /**
   * go back to previous page
   */
  handleBack() {
    this.props.history.goBack();
  }

  /**
   * reaset vehicle form 
   */
  resetVehicleFormFields() {
    let prevSavedFormFields = this.state.savedFormFields;

    this.setState({
      formfields: { ...prevSavedFormFields },
      formErrors: {
        make: "",
        model: "",
        year: "",
        type: "",
        localId: "",
        steeringWheelType: "",
        wheelbase: "",
        antennaHeight: "",
        antennaPivot: "",
        antennaOffset: "",
        frontHitch: "",
        rearHitch: "",
        attack: "",
        backlash: "",
        sensitivity: "",
        profileVersion: ""
      },
    });
  }

  /**
   * edit vehicle form
   */
  renderCreateVehicleForm() {
    return (
      <LoadingOverlay active={this.state.isWaitingApiResponse || this.state.isLoading}>
        <div className="container-fluid ">
          <form autoComplete="off">
            <h2>{i18n.t('userVehicles.vehicles.editVehicle')}</h2>
            <div className="view-profile-details width-70per">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="make">{i18n.t('userVehicles.vehicles.form.label.make')} <StyledText uiText="*" /></label>
                    <input type="text" required className="form-control" name="make"
                      placeholder={i18n.t('userVehicles.vehicles.form.placeholder.make')}
                      value={this.state.formfields.make}
                      onChange={this.handleUserInput} maxLength="32" 
                      data-testid="edit-vehicle-input-make" />
                    <FormErrors formErrors={this.state.formErrors.make} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="model">{i18n.t('userVehicles.vehicles.form.label.model')} <StyledText uiText="*" /></label>
                    <input type="text" required className="form-control" name="model"
                      placeholder={i18n.t('userVehicles.vehicles.form.placeholder.model')}
                      value={this.state.formfields.model}
                      onChange={this.handleUserInput} maxLength="32" />
                    <FormErrors formErrors={this.state.formErrors.model} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="year">{i18n.t('userVehicles.vehicles.form.label.year')}</label>
                    <input type="text" className="form-control" name="year"
                      placeholder={i18n.t('userVehicles.vehicles.form.placeholder.year')}
                      value={this.state.formfields.year}
                      onChange={this.handleUserInput} maxLength="4" />
                    <FormErrors formErrors={this.state.formErrors.year} />
                  </div>
                </div></div>
              <div className="hr-line-dashed"></div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="type">{i18n.t('userVehicles.vehicles.form.label.type')} <StyledText uiText="*" /></label>
                    <select className="form-control" id="type" name="type" onChange={this.handleUserInput}
                      required value={this.state.formfields.type}>
                      <option key="placeHolderVehicleType" value="">{i18n.t('userVehicles.vehicles.form.placeholder.type')}</option>
                      {
                        this.vehicleTypes.map((elem, id) => {
                          return <option key={elem.value + id} value={elem.value}>{elem.displayName}</option>
                        })
                      }
                    </select>
                    <FormErrors formErrors={this.state.formErrors.type} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="attack">{i18n.t('userVehicles.vehicles.form.label.attack')} <StyledText uiText="*" /></label>
                    <input type="text" className="form-control" name="attack"
                      placeholder={i18n.t('userVehicles.vehicles.form.placeholder.attack')}
                      value={this.state.formfields.attack}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.attack} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="backlash">{i18n.t('userVehicles.vehicles.form.label.backlash')} <StyledText uiText="*" /></label>
                    <input type="text" className="form-control" name="backlash"
                      placeholder={i18n.t('userVehicles.vehicles.form.placeholder.backlash')}
                      value={this.state.formfields.backlash}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.backlash} />
                  </div>
                </div>
              </div>
              <div className="hr-line-dashed"></div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="sensitivity">{i18n.t('userVehicles.vehicles.form.label.sensitivity')} <StyledText uiText="*" /></label>
                    <input type="text" className="form-control" name="sensitivity"
                      placeholder={i18n.t('userVehicles.vehicles.form.placeholder.sensitivity')}
                      value={this.state.formfields.sensitivity}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.sensitivity} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="localId">{i18n.t('userVehicles.vehicles.form.label.localId')}</label>
                    <input type="text" className="form-control" name="localId"
                      placeholder={i18n.t('userVehicles.vehicles.form.placeholder.localId')}
                      value={this.state.formfields.localId ? this.state.formfields.localId : ""}
                      onChange={this.handleUserInput} maxLength="256" />
                    <FormErrors formErrors={this.state.formErrors.localId} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="steeringWheelType">{i18n.t('userVehicles.vehicles.form.label.steeringWheelType')}</label>
                    <input type="text" className="form-control" name="steeringWheelType"
                      placeholder={i18n.t('userVehicles.vehicles.form.placeholder.steeringWheelType')}
                      value={this.state.formfields.steeringWheelType}
                      onChange={this.handleUserInput} maxLength="32" />
                    <FormErrors formErrors={this.state.formErrors.steeringWheelType} />
                  </div>
                </div>
              </div>
              <div className="hr-line-dashed"></div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="wheelbase">{i18n.t('userVehicles.vehicles.form.label.wheelbase')}</label>
                    <input type="text" className="form-control" name="wheelbase"
                      placeholder={i18n.t('userVehicles.vehicles.form.placeholder.wheelbase')}
                      value={this.state.formfields.wheelbase}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.wheelbase} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="antennaHeight">{i18n.t('userVehicles.vehicles.form.label.antennaHeight')}</label>
                    <input type="text" className="form-control" name="antennaHeight"
                      placeholder={i18n.t('userVehicles.vehicles.form.placeholder.antennaHeight')}
                      value={this.state.formfields.antennaHeight}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.antennaHeight} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="antennaPivot">{i18n.t('userVehicles.vehicles.form.label.antennaPivot')}</label>
                    <input type="text" className="form-control" name="antennaPivot"
                      placeholder={i18n.t('userVehicles.vehicles.form.placeholder.antennaPivot')}
                      value={this.state.formfields.antennaPivot}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.antennaPivot} />
                  </div>
                </div>
              </div>

              <div className="hr-line-dashed"></div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="antennaOffset">{i18n.t('userVehicles.vehicles.form.label.antennaOffset')}</label>
                    <input type="text" className="form-control" name="antennaOffset"
                      placeholder={i18n.t('userVehicles.vehicles.form.placeholder.antennaOffset')}
                      value={this.state.formfields.antennaOffset}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.antennaOffset} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="frontHitch">{i18n.t('userVehicles.vehicles.form.label.frontHitch')}</label>
                    <input type="text" className="form-control" name="frontHitch"
                      placeholder={i18n.t('userVehicles.vehicles.form.placeholder.frontHitch')}
                      value={this.state.formfields.frontHitch}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.frontHitch} />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="rearHitch">{i18n.t('userVehicles.vehicles.form.label.rearHitch')}</label>
                    <input type="text" className="form-control" name="rearHitch"
                      placeholder={i18n.t('userVehicles.vehicles.form.placeholder.rearHitch')}
                      value={this.state.formfields.rearHitch}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.rearHitch} />
                  </div>
                </div>
              </div>

              <div className="hr-line-dashed"></div>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="ecuOrientation">{i18n.t('userVehicles.vehicles.form.label.ecuOrientation')}</label>
                    <div className="row">
                      <div className="col-md-6">
                      <label htmlFor="CableAttachDirection">{i18n.t('userVehicles.vehicles.form.label.CableAttachDirection')}</label>
                        {!this.state.isDefault && !this.state.cableAttachDirection
                        ?
                          <input type="text" className="form-control" name="cableAttachDirection" disabled value={'N/A'}/>
                        : 
                          <select className="form-control" id="cableAttachDirection" name="cableAttachDirection"
                            onChange={this.handleUserInput} disabled={!this.state.isDefault}
                            value={this.state.cableAttachDirection} >
                            <option key="placeHolderVehicleEcu" value="">{i18n.t('userVehicles.vehicles.form.placeholder.CableAttachDirection')}</option>
                            {
                              this.cableAttachDirectionTypes.map((elem, id) => {
                                return <option key={elem.value + id} value={elem.value}>{elem.displayName}</option>
                              })
                            }
                          </select>
                        }
                      </div>
                      <div className="col-md-6">
                      <label htmlFor="XYStickeFace">{i18n.t('userVehicles.vehicles.form.label.XYStickeFace')}</label>
                        {!this.state.isDefault && !this.state.xyStickeFace
                        ?
                          <input type="text" className="form-control" name="xyStickeFace" disabled value={'N/A'}/>
                        :
                          <select className="form-control" id="xyStickeFace" name="xyStickeFace"
                            onChange={this.handleUserInput} disabled={!this.state.isDefault} 
                            value={this.state.xyStickeFace} >
                            <option key="placeHolderVehicleEcu" value="">{i18n.t('userVehicles.vehicles.form.placeholder.XYStickeFace')}</option>
                            {
                              this.xyStickeFaceTypes.map((elem, id) => {
                                return <option key={elem.value + id} value={elem.value}>{elem.displayName}</option>
                              })
                            }
                          </select>
                        }
                      </div>                    
                    </div>
                  </div>
                </div>
              </div>
              <div className="hr-line-dashed"></div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="profileVersion">{i18n.t('userVehicles.vehicles.form.label.profileVersion')}</label>
                    <input type="text" className="form-control" name="profileVersion"
                      placeholder={i18n.t('userVehicles.vehicles.form.placeholder.profileVersion')}
                      value={this.state.formfields.profileVersion}
                      disabled={true}
                      onChange={this.handleUserInput} maxLength="64" />
                    <FormErrors formErrors={this.state.formErrors.profileVersion} />
                  </div>
                </div>
              </div>
              <div className="hr-line-dashed"></div>
            </div>
            <div className="row">
              <div className="col-md-12 text-right">
                <button type="button" className="btn btn-secondary mr-2"
                  onClick={this.resetVehicleFormFields.bind(this)}>
                  {i18n.t('userVehicles.vehicles.form.button.reset')}
                </button>
                <button type="submit" className="btn btn-primary"
                  disabled={(!this.isFormValid() || this.state.isWaitingApiResponse)}
                  onClick={this.handleSubmit.bind(this)} 
                  data-testid="edit-vehicle-submit-btn" >
                  {i18n.t('userVehicles.vehicles.form.button.save')}
                </button>
              </div>
            </div>

          </form>
        </div>
      </LoadingOverlay>
    );
  }

  /**
   * render error
   */
  renderError() {
    return (
      <div className="col-md-12 text-right f-btn">
        <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>
          {i18n.t('userVehicles.vehicles.form.button.back')}
        </button>
      </div>
    );
  }

  /**
   * react render to render edit vehicle form
   */
  render() {

    return (
      <div className="container-fluid p-4">


        {
          this.state.alert.message &&
          <AlertMessage message={this.state.alert.message}
            type={this.state.alert.type}
            isAlertOpen={!!(this.state.alert.message)}
            handleDismiss={this.handleAlertDismiss.bind(this)} 
            testId="editVehicle"
          />
        }

        {
          this.state.isErrorOnLoad ? this.renderError() : this.renderCreateVehicleForm()
        }
      </div>
    );
  }
}

export default EditVehicle;
