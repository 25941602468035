import JwtDecode from 'jwt-decode';
import i18n from 'i18next';
import { UserService } from '../../services/service.users';
import _ from 'lodash';
import { AppStorageHelper } from './appStorageHelper';
import { APP_COMMON_CONSTANTS } from '../../constants/app.constants';

/**
 * UserInfo private class. With all initial config set in constructor.
 */
class UserInfo {
  /**
   * Initialize the object instance
   */
  constructor () {
    console.log('-- UserInfo constructor called....');

    // configuration for userInfo
    this.loggedInUserData = undefined;
    this.selectedAccountHolderForOperator = undefined;
    this.isBusyLoadingUserInfo = false;
    this.userInfoApiPromise = undefined;
    this.shouldHandlePendingPromises = true;
  }

  /**
   * Before reinitializing "UserInfo" instance, disable handling of any pending promises
   */
  disableHandlingPromises () {
    this.shouldHandlePendingPromises = false;
  }

  /**
   * For an operator user, set the currently selected account holder information in localstorage.
   * @param {object} accountHolder
   */
  setSelectedAccountHolderForOperator (accountHolder) {
    AppStorageHelper.setSelectedAccHolderToLocalStorage(accountHolder);
  }

  /**
   * For an operator user, get currently selected account holder information from localstorage.
   * @return {object|undefined}
   */
  getSelectedAccountHolderForOperator () {
    const selAccHolderObj = AppStorageHelper.getSelectedAccHolderFromLocalStorage();
    if (_.isObject(selAccHolderObj)) {
      return selAccHolderObj;
    } else {
      return undefined;
    }
  }

  /**
   * Function to get information of logged in user. Information from backend is fetched only
   * once. If there are any subsequent request for getUserInfo, they are either returned in-progress
   * request promise or the cached user information(if the request promise is resolved).
   */
  getUserInfo () {
    var self = this;
    if (this.loggedInUserData) {
      return Promise.resolve({ ...this.loggedInUserData });
    } else if (this.isBusyLoadingUserInfo) {
      return this.userInfoApiPromise;
    } else {
      this.isBusyLoadingUserInfo = true;
      this.userInfoApiPromise = new Promise(function (resolve, reject) {
        try {
          const apiAuthInfo = AppStorageHelper.getTokenFromLocalStorage(APP_COMMON_CONSTANTS.AUTH_INFO);
          const apiAccessToken = apiAuthInfo.acessToken;
          const decodedData = JwtDecode(apiAccessToken);
          const userSub = decodedData.sub;
          UserService.getUser(userSub).then(
            (successRes) => {
              self.isBusyLoadingUserInfo = false;
              self.loggedInUserData = successRes.data;
              if (self.shouldHandlePendingPromises) {
                resolve(successRes.data);
              }
            },
            (errRes) => {
              self.isBusyLoadingUserInfo = false;

              if (self.shouldHandlePendingPromises) {
                reject(errRes);
              }
            });
        } catch (err) {
          const errorMsg = {
            data: {
              message: i18n.t('common.userInfoError')
            }
          };
          self.isBusyLoadingUserInfo = false;
          if (self.shouldHandlePendingPromises) {
            reject(errorMsg);
          }
        }
      });
      return this.userInfoApiPromise;
    }
  }
}

/**
 * A wrapper class to create and hold instance of UserInfo.
 */
class AppInfoHelperClass {
  /**
   * Initialize the object instance
   */
  constructor () {
    this.userInfoObj = new UserInfo();
  }

  /**
   * Return the instance of userInfoObj
   * @return {object}
   */
  getUserInstance () {
    return this.userInfoObj;
  }

  /**
   * Reinitialize the UserInfo instance, to refresh the information of logged in user. Used
   * when current user logs out and then login again (with different user).
   */
  reInitializeUserInfo () {
    this.userInfoObj.disableHandlingPromises();
    this.userInfoObj = null;

    this.userInfoObj = new UserInfo();
  }
}

const AppInfoHelper = new AppInfoHelperClass();

export default AppInfoHelper;
