import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

/**
 * Breadcrumb functionality encapsulation for UI pages.
 * @param {object} props
 */
const BreadcrumbCustom = (props) => {
  return (
    <div className="float-left">
      <Breadcrumb>
        {
          props.breadcrumb.map(function (item, index) {
            if (item.link) {
              return (
                <BreadcrumbItem data-testid={"bcrumb-holder-" + item.id} key={index}>
                  <a href={item.href}>{item.displayName}</a>
                </BreadcrumbItem>
              );
            } else {
              return (
                <BreadcrumbItem key={index} data-testid={"bcrumb-holder-" + item.id}>
                  {item.displayName}
                </BreadcrumbItem>
              );
            }
          })
        }

      </Breadcrumb>
    </div>
  );
};
export default BreadcrumbCustom;
