import React from 'react';
import queryString from 'query-string';
import _ from 'lodash';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { FormErrors } from '../../../Component/SubmissionStatus';
import { UserService } from '../../../services/service.users';
import { CommonUtilities } from '../../../shared/utils/commonUtilities';
import { AppStorageHelper } from '../../../shared/utils/appStorageHelper';
import AlertMessage from '../../../Component/alert/AlertMessage';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import i18n from 'i18next';

const DELAY_AFTER_PROFILE_UPDATE = 10000; // 10 seconds

/**
 * confirm signup component
 */
class ConfirmSignup extends BaseScreen {
  constructor (props) {
    super(props);
    this.state = {
      isWaitingApiResponse: false,
      formFields: {
        confirmationCode: '',
        email: ''
      },
      formErrors: {
        confirmationCode: '',
        email: ''
      },
      formValid: true,
      alertMessage: {
        message: '',
        type: ''
      },
      disableAllApiHandlers: false
    };
    this.profileUpdatedTimer = null;
  }

  /**
   * check url query params (if emailId and resetCode is provided via email link)
   */
  componentDidMount () {
    const urlParams = this.props.routerData.location.search;
    const parsedValues = queryString.parse(urlParams);
    const confirmationCode = _.trim(parsedValues.confirmationCode);
    const emailIdInQueryString = _.trim(parsedValues.email);
    this.setState({
      formFields: {
        confirmationCode: confirmationCode,
        email: emailIdInQueryString
      }
    });
  }

  /**
   * cleanup resources
   */
  componentWillUnmount () {
    clearTimeout(this.profileUpdatedTimer);
  }

  /**
   * redirect to login page
   */
  goToLogin () {
    AppStorageHelper.clearApplicationData();
    this.goToScreen(SCREENS.login);
  }

  /**
   * validate form for valid data
   * @param {*} event 
   */
  handleUserInput (event) {
    event.preventDefault();

    const name = event.target.name;
    const value = _.trim(event.target.value);

    const formFields = this.state.formFields;
    formFields[name] = value;

    const fieldValidationErrors = { ...this.state.formErrors };
    let msg = '';

    switch (name) {
      case 'confirmationCode':
        if (value.length === 0) {
          msg = i18n.t('noauth.alert.required');
        }
        fieldValidationErrors[name] = msg;
        break;

      case 'email':
        if (value.length === 0) {
          msg = i18n.t('noauth.alert.required');
        } else if (value.length > 320) {
          msg = i18n.t('noauth.alert.email320');
        } else if (!CommonUtilities.isValidEmail(value)) {
          msg = i18n.t('noauth.alert.emailInvalid');
        }
        fieldValidationErrors[name] = msg;
        break;

      default:
        break;
    }

    const isFormValid = (formFields.confirmationCode && formFields.email) && (fieldValidationErrors.confirmationCode === '' &&
                        fieldValidationErrors.email === '');

    this.setState({
      formFields: formFields,
      formErrors: fieldValidationErrors,
      formValid: isFormValid
    });
  }

  /**
   * check if form is valid
   */
  getFormValidity () {
    let valid = false;
    if (this.state.formValid) {
      valid = true;
    }

    return valid && !this.state.disableAllApiHandlers;
  }

  /**
   * handles submit functionality, communicate with backend and update the password
   */
  submitHandler () {
    this.setState({ isWaitingApiResponse: true });

    UserService.confirmSignUp(_.cloneDeep(this.state.formFields)).then(
      response => {
        this.setState({
          isWaitingApiResponse: false,
          disableAllApiHandlers: true,
          alertMessage: {
            message: i18n.t('noauth.alert.profileUpdateSuccess'),
            type: 'success'
          }
        });

        /**
         * go back to login screen
         */
        this.profileUpdatedTimer = setTimeout(() => this.goToLogin(), DELAY_AFTER_PROFILE_UPDATE);
      },
      error => {
        // handle error message
        let alertMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          alertMsg = error.data.message;
        }
        this.setState({
          isWaitingApiResponse: false,
          alertMessage: {
            message: alertMsg,
            type: 'danger'
          }
        });
      }
    );
  }

  /*
   alert message handling
   */
  handleDismiss () {
    this.setState({
      alertMessage: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render function to show change password form
   */
  render () {
    return (
      <LoadingOverlay active={this.state.isWaitingApiResponse} customClass="full_page_loading_overlay" text="Please wait">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <br />
              <h3>{i18n.t('noauth.confirmSignUp')}</h3>
              <br />
              {
                this.state.alertMessage.message &&
                <AlertMessage message={this.state.alertMessage.message}
                  type={this.state.alertMessage.type}
                  isAlertOpen={!!(this.state.alertMessage.message)}
                  handleDismiss={this.handleDismiss.bind(this)}
                />
              }

              <form autoComplete="off">
                <div className="form-group">
                  <label htmlFor="confirmationCode">{i18n.t('noauth.form.label.confirmationCode')} *</label>
                  <input type="text" required className="form-control" name="confirmationCode"
                    placeholder={i18n.t('noauth.form.placeholder.confirmationCode')}
                    value={this.state.formFields.confirmationCode}
                    onChange={this.handleUserInput.bind(this)} />
                  <FormErrors formErrors={this.state.formErrors.confirmationCode} />
                </div>

                <div className="form-group">
                  <label htmlFor="email">{i18n.t('noauth.form.label.emailId')} *</label>
                  <input type="text" required className="form-control" name="email"
                    placeholder={i18n.t('noauth.form.placeholder.emailId')}
                    value={this.state.formFields.email}
                    onChange={this.handleUserInput.bind(this)}
                    maxLength="320" />
                  <FormErrors formErrors={this.state.formErrors.email} />
                </div>

                <div className="col-md-12 text-right">
                  <button type="button" className="btn btn-secondary mr-2"
                    onClick={this.goToLogin.bind(this)}>
                    {i18n.t('noauth.form.button.back')}
                  </button>
                  <button type="button" className="btn btn-primary"
                    disabled={!this.getFormValidity()}
                    onClick={this.submitHandler.bind(this)}>
                    {i18n.t('noauth.form.button.confirm')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default ConfirmSignup;
