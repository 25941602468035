import { API_INTERFACE } from '../constants/uri.constant';
import Method from './services';
import { AuthUtils } from './service.auth.utils';
import { AppStorageHelper } from '../shared/utils/appStorageHelper';

const userRoleIds = {
  adminRoleId: 1,
  accountHolderRoleId: 2,
  operatorRoleId: 3
};

/**
 * service to create a user.
 * @param {*} values
 */
function createUserSubmission (values) {
  const params = values;

  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.post(API_INTERFACE.CREATE_USER, header, params);
}

/**
 * service to fetch a user by id.
 * @param {*} id
 * @param {*} cancelToken
 */
function fetchUserById (id) {
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.fetchDataByIdAsParam(
    API_INTERFACE.GET_USER + '/' + id,
    headers
  );
}

/**
 * service to update properties of a user
 * @param {*} values
 */
function updateUserSubmission (values) {
  const params = values;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.updateDataById(API_INTERFACE.UPDATE_USER, header, params);
}

/**
 * service to get roles of users accross OEM's from backend.
 */
function getOemRoles () {
  const allRoles = AppStorageHelper.getUserTypesFromSessionStorage();
  if (allRoles) {
    return Promise.resolve(allRoles);
  } else {
    const url = API_INTERFACE.GET_ROLES;
    const header = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-api-key': API_INTERFACE.X_API_KEY,
      Authorization: AuthUtils.getAccessToken()
    };
    return Method.get(url, header).then(function (response) {
      AppStorageHelper.setUserTypesToSessionStorage(
        response.data.items
      );
      return response.data.items;
    }, function (error) {
      return Promise.reject(error);
    });
  }
}

/**
 * service to get a user details with id as passed userId.
 * @param {*} userId
 */
function getUser (userId) {
  const url = API_INTERFACE.GET_USER + '/' + userId;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return Method.get(url, header);
}
/**
 * service to fetch all registered users from backend.
 * @param {*} queryParams
 */
function getUsers (queryParams) {
  const url = API_INTERFACE.GET_USERS + '?' + queryParams;

  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return Method.get(url, header);
}

/**
 * deletes a specific user.
 * @param {*} userId
 */
function deleteUser (userId) {
  const url = API_INTERFACE.GET_USER + '/' + userId;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.delete(url, header);
}

/**
 * get assignments or account holder - operator mapping from backend.
 * if userId is of account holder, it returns operators
 * if userId is of operator, it returns account holder
 * @param {*} userId 
 * @param {*} queryParams 
 */
function getAssignementsForUser (userId, queryParams) {
  const url = API_INTERFACE.GET_USER + '/' + userId + '/assignments?' + queryParams;

  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return Method.get(url, header);
}

/**
 * change password for a user.
 * @param {*} currentPassword
 * @param {*} newPassword
 */
function changePassword (currentPassword, newPassword) {
  const reqBody = {
    oldPassword: currentPassword,
    newPassword: newPassword
  };
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.put(API_INTERFACE.CHANGE_PASSWORD, header, reqBody);
}

/**
 * service to request a assignment for one user to other.
 * Account holder initiate a assignment for a operator.
 * Operator initiate asssignment request to a account holder.
 * @param {*} values
 * @param {*} assigner
 */
function assignUsers (values, assigner) {
  let params = {};
  if (values.roleId === userRoleIds.accountHolderRoleId) {
    params = {
      operatorEmailId: assigner,
      accountEmailId: values.email
    };
  } else {
    params = {
      operatorEmailId: values.email,
      accountEmailId: assigner
    };
  }
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.mapUsersForAssignment(API_INTERFACE.ASSIGN_USER, header, params);
}

/**
 * service to force change an initial password.
 * @param {*} emailId
 * @param {*} tempPwd
 * @param {*} newPwd
 */
function forceChangePassword (emailId, tempPwd, newPwd) {
  const reqBody = {
    email: emailId,
    password: tempPwd,
    newPassword: newPwd
  };

  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    oemid: API_INTERFACE.OEM_ID
  };

  return Method.put(API_INTERFACE.FORCE_CHANGE_PASSWORD, header, reqBody);
}

/**
 * service to get all account holders of OEM.
 * @param {*} queryParams
 * @param {*} forid
 */
function getAssociatedAccountHolders () {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return Method.get(API_INTERFACE.GET_ASSOCIATED_ACCOUNT_HOLDERS, header);
}

/**
 * signup
 * @param {*} reqParams 
 */
function signUp (reqParams) {
  const reqBody = {
    email: reqParams.email,
    firstName: reqParams.firstName,
    lastName: reqParams.lastName,
    password: reqParams.newPassword,
    roleId: reqParams.roleId,
    phone: reqParams.phone
  };

  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    oemid: API_INTERFACE.OEM_ID
  };

  return Method.post(API_INTERFACE.USER_SIGN_UP, header, reqBody);
}

/**
 * confirmSignUp
 * @param {*} reqParams 
 */
function confirmSignUp (reqParams) {
  const reqBody = {
    email: reqParams.email,
    confirmationCode: reqParams.confirmationCode
  };
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    oemid: API_INTERFACE.OEM_ID
  };

  return Method.put(API_INTERFACE.USER_CNFIRM_SIGN_UP, header, reqBody);
}

/**
 * service to revoke an assignment request.
 * @param {*} userId
 * @param {*} forUser
 */
function deleteAssignment (assignmentId, forUser) {
  const url = API_INTERFACE.DELETE_ASSIGNMENT + forUser + '/assign/' + assignmentId;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.delete(url, header);
}

/**
 * update Assignment status.
 * @param {*} assignmentId 
 * @param {*} forUser 
 * @param {*} assignmentStatus 
 */
function updateAssignment (assignmentId, forUser, assignmentStatus) {
  const reqBody = {
    id: assignmentId,
    status: assignmentStatus
  };

  const url = API_INTERFACE.UPDATE_ASSIGNMENT + forUser + '/assign';
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.put(url, header, reqBody);
}

/**
 * user assignment request
 * @param {*} forUser 
 * @param {*} accountEmailId 
 * @param {*} operatorEmailId 
 */
function createAssignment (forUser, accountEmailId, operatorEmailId) {
  const reqBody = {
    accountEmailId: accountEmailId,
    operatorEmailId: operatorEmailId

  };

  const url = API_INTERFACE.UPDATE_ASSIGNMENT + forUser + '/assign';
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.post(url, header, reqBody);
}

/**
 * email change request for user
 * @param {*} newEmail
 */
function getEmailChangeConfirmationCode (newEmail) {
  const reqBody = {
    email: newEmail
  };
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.put(API_INTERFACE.CHANGE_EMAIL, header, reqBody);
}

/**
 * confirm email change
 * @param {*} newEmail 
 * @param {*} confirmationCode 
 */
function confirmChangeEmail (newEmail, confirmationCode) {
  const reqBody = {
    email: newEmail,
    confirmationCode: confirmationCode
  };
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.put(API_INTERFACE.CONFIRM_CHANGE_EMAIL, header, reqBody);
}

/**
 * Request for confirm sign up email
 * @param {string} userEmail
 */
function confirmSignUpEmailRequest (userEmail) {
  const reqBody = {
    email: userEmail
  };
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    oemid: API_INTERFACE.OEM_ID
  };
  return Method.put(API_INTERFACE.CONFIRM_SIGN_UP_EMAIL_REQUEST, header, reqBody);
}

export const UserService = {
  createUserSubmission,
  fetchUserById,
  updateUserSubmission,
  getOemRoles,
  getUser,
  getUsers,
  deleteUser,
  getAssignementsForUser,
  changePassword,
  assignUsers,
  forceChangePassword,
  getAssociatedAccountHolders,
  signUp,
  confirmSignUp,
  deleteAssignment,
  updateAssignment,
  createAssignment,
  getEmailChangeConfirmationCode,
  confirmChangeEmail,
  confirmSignUpEmailRequest
};
