import React from 'react';
import _ from 'lodash';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import { TaskService } from '../../../services/service.tasks';
import { OperationsService } from '../../../services/service.operations';
import { CropsService } from '../../../services/service.crop';
import AlertMessage from '../../../Component/alert/AlertMessage';
import ListConcernedVehicle from './ListConcernedVehicle';
import ListConcernedImplement from './ListConcernedImplement';
// import ListConcernedCrop from './ListConcernedCrop';
import ListTaskActivities from './ListTaskActivities';
import { UserService } from '../../../services/service.users';
import i18n from 'i18next';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import AppInfoHelper from '../../../shared/utils/appInfoHelper';
import { CommonUtilities } from '../../../shared/utils/commonUtilities';
import { ROLE_TYPES } from '../../../constants/app.constants';

/**
 * view task component
 */
class ViewTask extends BaseScreen {
  constructor(props) {
    super(props);

    let isAuthenticated = true;

    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    this.state = {
      isAuthenticated: isAuthenticated,
      isLoading: true,
      taskId: '',
      taskData: {},
      taskInternalData: {
        operationName: i18n.t('tasks.form.loadingText'),
        cropName: i18n.t('tasks.form.loadingText'),
        userEmail: i18n.t('tasks.form.loadingText')
      },
      isErrorOnLoad: false,

      alert: {
        type: null,
        message: null
      }
    };
  }

  /**
   * load task information on mount
   */
  componentDidMount() {
    if (this.state.isAuthenticated) {
      this.displayTaskInfo();
    }
  }

  /**
   * function to communicate backend to fetch task data along with crop and operation.
   */
  displayTaskInfo() {
    const taskId = this.props.match.params.taskId;
    TaskService.getTask(taskId).then(response => {
      const taskResponse = response.data;
      this.setState({
        taskId: taskId,
        taskData: taskResponse,
        isLoading: false
      });

      /**
       * fetch task internal data get operatrion Name
       */
      const operationId = taskResponse.operationId;
      OperationsService.getOperation(operationId).then((response) => {
        const taskInternalData = this.state.taskInternalData;
        taskInternalData.operationName = response.data.name;
        this.setState({
          taskInternalData: taskInternalData
        });
      }, (error) => {
        const taskInternalData = this.state.taskInternalData;
        taskInternalData.operationName = i18n.t('tasks.form.notAvailable');
        this.setState({
          taskInternalData: taskInternalData
        });
      });

      /**
       * get crop data from backend
       */
      const cropId = taskResponse.cropId;
      CropsService.getCrop(cropId).then((response) => {
        const taskInternalData = this.state.taskInternalData;
        taskInternalData.cropName = response.data.name;
        this.setState({
          taskInternalData: taskInternalData
        });
      }, (error) => {
        const taskInternalData = this.state.taskInternalData;
        taskInternalData.cropName = i18n.t('tasks.form.notAvailable');
        this.setState({
          taskInternalData: taskInternalData
        });
      });

      /**
       * get and set user email for the task (for task userId data field)
       */
      this.getTaskUserEmail(taskResponse.userId);
    }, error => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }

      this.setState({
        taskData: {},
        isLoading: false,
        isErrorOnLoad: true,
        alert: {
          type: 'danger',
          message: errorMsg
        }
      });
    });
  }

  /**
   * Fetch email for the userId field of the task data.
   * @param {string} taskUserId
   */
  getTaskUserEmail(taskUserId) {
    const self = this;

    const userInfoInstance = AppInfoHelper.getUserInstance();
    userInfoInstance.getUserInfo().then(function (userInfo) {
      let userId = userInfo.id;
      // check if task "userId" belongs to the loggedin user
      if (userId === taskUserId) {
        self.setTaskUserEmail(userInfo.email);
      } else {
        // else fetch the user assignments for the logged in User (accountHoldersList for an operator or 
        // operatorList for an accountHolder), then find the user whose userId matches with the task "userId"
        let forUserType = "&accountId=";
        if (userInfo.roleId === ROLE_TYPES.OPERATOR.ID) {
          forUserType = "&operatorId=";
        }
        // as getAssignementsForUser call is a paginated api call, setting 
        // pageSize to be more than the maximum possible
        let reqQueryParams = "currentPage=0&pageSize=100" + forUserType + userId;

        UserService.getAssignementsForUser(userId, reqQueryParams).then(
          response => {
            let matchedUser;
            if (_.has(response, 'data.items') && _.isArray(response.data.items)) {
              matchedUser = _.find(response.data.items, function (obj) {
                return (obj.userId === taskUserId);
              });
            }

            let emailToDisplay = _.has(matchedUser, 'email') ? matchedUser.email : i18n.t('tasks.form.notAvailable');
            self.setTaskUserEmail(emailToDisplay);
          },
          errResponse => {
            self.setTaskUserEmail(i18n.t('tasks.form.notAvailable'));
          });
      }
    }, function (error) {
      self.setTaskUserEmail(i18n.t('tasks.form.notAvailable'));
    });
  }

  /**
   * Update task internal data for user Email in the component state.
   * @param {string} email
   */
  setTaskUserEmail(email) {
    const taskInternalData = this.state.taskInternalData;
    taskInternalData.userEmail = email;
    this.setState({
      taskInternalData: taskInternalData
    });
  }

  /**
   * go back to previous page
   */
  handleBack() {
    this.props.history.goBack();
  }

  /**
   * convert timestamp to human readable format
   * @param {*} timeInSeconds
   */
  getReadableTime(timeInSeconds) {
    let returnValue = '';
    if (_.isNumber(timeInSeconds) && _.isFinite(timeInSeconds)) {
      var h = Math.floor(timeInSeconds / 3600);
      var m = Math.floor(timeInSeconds % 3600 / 60);
      var s = Math.floor(timeInSeconds % 3600 % 60);

      var hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
      var mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
      var sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
      returnValue = hDisplay + mDisplay + sDisplay;
    }
    return returnValue;
  }

  /**
   * Check if task field value is empty. For empty 
   * values, return "N/A".
   */
  handleEmptyTaskValue = (val) => {
    if (_.isEmpty(this.state.taskData)) {
      return val;
    } else {
      return CommonUtilities.isEmpty(val) ? i18n.t('tasks.form.notAvailable') : val;
    }
  }

  /**
   * task form to be rendered
   */
  renderTaskDetails() {
    return (
      <>
        <h2 data-testid="view-task-heading">
          {i18n.t('tasks.viewTask')}
        </h2>
        <div className="view-profile-details width-70per">
          <div className="row">
            <div className="col-md-4">
              <label className="emphasized-label" data-testid="view-task-label-task-name">
                {i18n.t('tasks.form.label.taskName')}
              </label>
              <p>{this.state.taskData.name}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('tasks.form.label.farmName')}</label>
              <p>
                {this.handleEmptyTaskValue(this.state.taskData.farmName)}
              </p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('tasks.form.label.operationName')}</label>
              <p>{this.state.taskInternalData.operationName}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('tasks.form.label.workedTime')}</label>
              <p>{this.getReadableTime(this.state.taskData.workedTime)}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('tasks.form.label.cropName')}</label>
              <p>{this.state.taskInternalData.cropName}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('tasks.form.label.userEmail')}</label>
              <p>{this.state.taskInternalData.userEmail}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('tasks.form.label.notes')}</label>
              <textarea disabled className="form-control" name="description" cols={40} rows={3}
                value={this.state.taskData.notes} />
            </div>

          </div>
        </div>

        <br />

        {this.state.isLoading ? null : this.renderVehicles()}
        {this.state.isLoading ? null : this.renderImplements()}
        {/* {this.state.isLoading ? null : this.renderCrops()} */}
        {this.state.isLoading ? null : this.renderActivities()}
      </>
    );
  }

  /**
   * render component for listing concerned vehicle
   */
  renderVehicles() {
    return (
      <div>
        <div className="col-md-12 clear-all">
          <h2 data-testid="view-task-vehicles-list">
            {i18n.t('tasks.vehicleDetails')}
          </h2>
        </div>
        <ListConcernedVehicle vehicleId={this.state.taskData.vehicleId} />
      </div>
    );
  }

  /**
   * render component for listing concerned implement
   */
  renderImplements() {
    return (
      <div>
        <div className="col-md-12 clear-all">
          <h2 data-testid="view-task-implements-list">
            {i18n.t('tasks.implementDetails')}
          </h2>
        </div>
        <ListConcernedImplement implementId={this.state.taskData.implementId} />
      </div>
    );
  }

  /**
   * render component for crop in the task. commenting as of now as discussed.
   */

  // renderCrops() {
  //   return (
  //     <div>
  //       <div className="col-md-12 clear-all">
  //         <h2 data-testid="view-task-crops-list">
  //           {i18n.t('tasks.cropsDetails')}
  //         </h2>
  //       </div>
  //       <ListConcernedCrop cropId={this.state.taskData.cropId} />
  //     </div>
  //   );
  // }


  /**
   * render component for listing concerned activities
   */
  renderActivities() {
    return (
      <div>
        <div className="col-md-12 clear-all">
          <h2 data-testid="view-task-activities-list">
            {i18n.t('tasks.activities')}
          </h2>
        </div>
        <ListTaskActivities taskId={this.state.taskId} />
      </div>
    );
  }

  /**
   * render errors
   */
  renderError() {
    return (
      <div className="col-md-12 text-right f-btn">
        <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>{i18n.t('tasks.form.button.back')}</button>
      </div>
    );
  }

  /*
    alert message handling
   */
  handleAlertDismiss() {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render func to render task form
   */
  render() {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('tasks.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'task', displayName: i18n.t('tasks.breadcrumb.task'), className: '', link: false },
      { id: 'detail', displayName: i18n.t('tasks.breadcrumb.detail'), className: '', link: false }
    ];

    return (
      <div className="container-fluid p-4">
        <BreadcrumbCustom breadcrumb={breadcrumb} />
        <div className="clearfix"></div>
        {
          this.state.alert.message &&
          <AlertMessage message={this.state.alert.message}
            type={this.state.alert.type}
            isAlertOpen={!!(this.state.alert.message)}
            handleDismiss={this.handleAlertDismiss.bind(this)}
          />
        }

        <LoadingOverlay active={this.state.isLoading}>
          {
            this.state.isErrorOnLoad ? this.renderError() : this.renderTaskDetails()
          }
        </LoadingOverlay>
      </div>
    );
  }
}

export default ViewTask;
