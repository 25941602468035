import React from 'react';
import i18n from 'i18next';
import _ from 'lodash';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { FormErrors } from '../../../Component/SubmissionStatus';
import { UserService } from '../../../services/service.users';
import { CommonUtilities } from '../../../shared/utils/commonUtilities';
import { AppStorageHelper } from '../../../shared/utils/appStorageHelper';
import AlertMessage from '../../../Component/alert/AlertMessage';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import { StyledText } from '../../../Component/StyledText';

/**
 * "Resend ConfirmSignUp Email" component. Provides an interface to the user 
 * to request for confirm sign up email.
 */
class ResendConfirmSignupEmail extends BaseScreen {
  /**
   * Component initialization
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formfields: {
        email: ''
      },
      formErrors: {
        email: ''
      },
      isFormValid: false,
      alert: {
        type: null,
        message: null
      }
    };
  }

  /**
   * go to login page
   */
  goToLogin = () => {
    AppStorageHelper.clearApplicationData();
    this.goToScreen(SCREENS.login);
  }

  /**
   * Handles form submission, communicates with backend for re-sending confirm sign up email
   * @param {object} e 
   */
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    UserService.confirmSignUpEmailRequest(this.state.formfields.email).then(response => {
      this.setState({
        isLoading: false,
        alert: {
          message: i18n.t('noauth.alert.confirmationCodeSent'),
          type: 'success'
        }
      });
    }, error => {
        let alertMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          alertMsg = error.data.message;
        }

        this.setState({
          isLoading: false,
          alert: {
            message: alertMsg,
            type: 'danger'
          }
        });
      });
  }

  /**
   * Validate form fields for valid data.
   * @param {object} event 
   */
  handleUserInput = (event) => {
    event.preventDefault();

    const name = event.target.name;
    const value = _.trim(event.target.value);

    const formfields = this.state.formfields;
    formfields[name] = value;

    const fieldValidationErrors = { ...this.state.formErrors };
    let msg = '';

    if (name === 'email') {
      if (value.length === 0) {
        msg = i18n.t('noauth.alert.required');
      } else if (value.length > 320) {
        msg = i18n.t('noauth.alert.email320');
      } else {
        msg = CommonUtilities.isValidEmail(value) ? '' : i18n.t('noauth.alert.emailInvalid');
      }
      fieldValidationErrors[name] = msg;
    }

    const isFormValid = formfields.email && (fieldValidationErrors.email === '');

    this.setState({
      formfields: formfields,
      formErrors: fieldValidationErrors,
      isFormValid: isFormValid
    });
  }

  /*
    alert message handling
  */
  handleAlertDismiss = () => {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * React render function to display "ResendConfirmSignupEmail" component.
   */
  render() {
    return (
      <LoadingOverlay active={this.state.isLoading} customClass="full_page_loading_overlay">
        <div className="container-fluid p-4 reset-section">
          <div className="row">
            <div className="col-md-12 reset-password-wrap">
              <div className="ibox-title">
                {i18n.t('noauth.form.label.resendConfirmSignupEmail')}
              </div>
              <div className="ibox-content">
                {
                  this.state.alert.message &&
                  <AlertMessage message={this.state.alert.message}
                    type={this.state.alert.type}
                    isAlertOpen={!!(this.state.alert.message)}
                    handleDismiss={this.handleAlertDismiss}
                  />
                }
                <form autoComplete="off">
                  <div className="form-group">
                    <label htmlFor="email">{i18n.t('noauth.form.label.emailId')} <StyledText uiText="*" /></label>
                    <input type="text" required className="form-control" name="email"
                      placeholder={i18n.t('noauth.form.placeholder.emailId')}
                      value={this.state.formfields.email}
                      onChange={this.handleUserInput}
                      maxLength="320" />
                    <FormErrors formErrors={this.state.formErrors.email} />
                  </div>

                  <div className="col-md-12 text-right">
                    <button type="button" className="btn btn-secondary mr-2"
                      onClick={this.goToLogin}>
                      {i18n.t('noauth.form.button.back')}
                    </button>
                    <button type="submit" className="btn btn-primary"
                      onClick={this.handleSubmit}
                      disabled={!this.state.isFormValid}>
                      {i18n.t('noauth.form.button.resendConfirmSignupEmail')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default ResendConfirmSignupEmail;
