import React from 'react';
import { Link } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import AlertMessage from '../../../Component/alert/AlertMessage';
import { OperationsService } from '../../../services/service.operations';
import i18n from 'i18next';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';

/**
 * view operation components. holds all functionalities for viewing an operation.
 */
class ViewOperations extends BaseScreen {
  constructor(props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    this.state = {
      isAuthenticated: isAuthenticated,
      OperationId: this.props.match.params.id,
      isLoading: true,
      OperationData: '',
      isErrorOnLoad: false,
      apiResponseMsg: '',
      alert: {
        type: null,
        message: null
      }
    };
  }

  componentDidMount() {
    if (this.state.isAuthenticated) {
      this.displayOperationInfo();
    }
  }

  /**
   *  fetch data from backend to display on ui.
   */
  displayOperationInfo() {
    const OperationId = this.state.OperationId;
    OperationsService.getOperation(OperationId).then(response => {
      this.setState({
        OperationData: response.data,
        isLoading: false
      });
    }, error => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }

      this.setState({
        OperationData: {},
        isLoading: false,
        isErrorOnLoad: true,
        apiResponseMsg: errorMsg,
        alert: {
          type: 'danger',
          message: errorMsg
        }
      });
    });
  }

  /**
   *  navigate to previous page
   */
  handleBack() {
    this.props.history.goBack();
  }

  /**
   * view operation form
   */
  renderoperationDetails() {
    return (
      <>
        <h2>{i18n.t('operations.form.label.viewOperation')}</h2>
        <div className="view-profile-details width-70per">
          <div className="row">
            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('operations.form.label.operationName')}</label>
              <p>{this.state.OperationData.name}</p>
            </div>
            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('operations.form.label.active')}</label>
              <p>{this.state.OperationData.active ? 'True' : 'False'}</p>
            </div>
            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('operations.form.label.localId')}</label>
              <p>
                {
                  this.state.OperationData.localId
                    ? this.state.OperationData.localId
                    : i18n.t('operations.form.label.notAvailable')
                }
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-12 text-right f-btn">
          <Link className="btn btn-primary" to={{
            pathname: '/operations/edit-operations/' + this.state.OperationId
          }}>
            {i18n.t('operations.form.button.edit')}
          </Link>
        </div>
      </>
    );
  }

  /**
   * renders errors
   */
  renderError() {
    return (
      <div className="col-md-12 text-right f-btn">
        <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>
          {i18n.t('operations.form.button.back')}
        </button>
      </div>
    );
  }

  /**
   * dissmiss alert message
   */
  handleAlertDismiss() {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * render view operation form
   */
  render() {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('operations.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'operation', displayName: i18n.t('operations.breadcrumb.operation'), className: '', link: false },
      { id: 'detail', displayName: i18n.t('operations.breadcrumb.detail'), className: '', link: false }
    ];
    return (
      <div className="container-fluid p-4">
        <BreadcrumbCustom breadcrumb={breadcrumb} />
        <div className="clearfix"></div>
        {
          this.state.alert.message &&
          <AlertMessage message={this.state.alert.message}
            type={this.state.alert.type}
            isAlertOpen={!!(this.state.alert.message)}
            handleDismiss={this.handleAlertDismiss.bind(this)}
          />
        }
        <LoadingOverlay active={this.state.isLoading}>
          {
            this.state.isErrorOnLoad ? this.renderError() : this.renderoperationDetails()
          }
        </LoadingOverlay>
      </div>
    );
  }
}

export default ViewOperations;
