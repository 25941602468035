import React from 'react';
import Grid from '../../../Component/grid/Grid';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { VehiclesService } from '../../../services/service.vehicles';
import i18n from 'i18next';

/**
 * Informations to be shown in vehicle listing grid, along with options per rows in support details page.
 */
let vehicleListConfig = {};

/**
 * List associated vehicles with the support
 */
class ListConcernedVehicle extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    // initialize vehicleListConfig with updated i18n resources
    vehicleListConfig = {
      make: {
        label: i18n.t('tasks.list.make'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      },
      model: {
        label: i18n.t('tasks.list.model'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      },
      year: {
        label: i18n.t('tasks.list.year'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      },
      options: {
        label: i18n.t('tasks.list.options.options'),
        sort: false,
        filter: false,
        display: true,
        list: [
          {
            actionType: 'link',
            action: 'view',
            label: i18n.t('tasks.list.options.view')
          }
        ]
      }
    };

    this.state = {
      isAuthenticated: isAuthenticated,
      vehicleId: this.props.vehicleId,
      vehiclesData: [],
      loader: true
    };
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      if (this.props.vehicleId) {
        this.getVehiclesById(this.props.vehicleId).then(response => {
          const vehicle = [];
          vehicle.push(response.data);
          this.setState({
            vehiclesData: vehicle,
            loader: false
          });
        }, error => {
          this.setState(
            {
              vehiclesData: [],
              loader: false
            });
        });
      } else {
        this.setState(
          {
            vehiclesData: [],
            loader: false
          });
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.vehicleId !== this.props.vehicleId && this.props.vehicleId) {
      this.getVehiclesById(this.props.vehicleId).then(response => {
        const vehicle = [];
        vehicle.push(response.data);
        this.setState({
          vehiclesData: vehicle,
          loader: false
        });
      }, error => {
        this.setState(
          {
            vehiclesData: [],
            loader: false
          });
      });
    }
  }

  /**
   * function to fetch vehicle details by id
   * @param {*} id 
   */
  getVehiclesById (id) {
    return VehiclesService.getVehicle(id)
      .then(response => {
        return response;
      }, errResponse => {
        return Promise.reject(errResponse);
      });
  }

  /**
   * routing to relevant page based on row option selection
   * @param {*} actionType 
   * @param {*} row 
   */
  actionPaths (actionType, row) {
    if (actionType.action === 'view') {
      return ({
        pathname: '/user-vehicles/vehicles/view-vehicle/' + row.id,
        state: { vehicleData: row }
      });
    }
  }

  /**
   * render listing of associated vehicle.
   */
  render () {
    return (
      <div>
        <Grid id="taskVehicleListGrid"
          keyColumn="id"
          rowData={this.state.vehiclesData}
          columnDefs={vehicleListConfig}
          loading={this.state.loader}
          pagination={false}
          actionPaths={this.actionPaths.bind(this)} />
      </div>
    );
  }
}

export default ListConcernedVehicle;
