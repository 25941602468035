import React from 'react';
import _ from 'lodash';
import BaseScreen from "../../BaseScreen";
import { SCREENS } from "../../../../constants/screens.constant";
import AlertMessage from "../../../../Component/alert/AlertMessage";
import { ImplementsService } from "../../../../services/service.implements";
import { FormErrors } from "../../../../Component/SubmissionStatus";
import { StyledText } from "../../../../Component/StyledText";
import i18n from 'i18next';
import LoadingOverlay from "../../../../Component/loader/LoadingOverlay";

/**
 * edit Implement components. holds all functionalities for editing an Implement.
 */
class EditImplement extends BaseScreen {
  constructor(props) {
    super(props);

    let isAuthenticated = true;

    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    this.state = {
      isAuthenticated: isAuthenticated,
      formfields: {
        name: "",
        length: "",
        localId: "",
        position: ""
      },
      formErrors: {
        name: "",
        length: "",
        localId: "",
        position: ""
      },
      formValid: false,

      alert: {
        type: null,
        message: null
      },

      isWaitingApiResponse: false,
      isLoading: true,
      savedFormFields: {},
      isErrorOnLoad: false,
    }
  }

  /**
   * utility function to remove null values, this utility function works on single object 
   * @param {*} obj 
   */
  removeNullValues(obj) {
    // this utility function works on single object 
    let newObj = _.mapValues(obj, (value, key) => {
      // do not update value for "accountId" and "operatorId"
      if (key !== "accountId" && key !== "operatorId") {
        if ((value === null) || (value === undefined)) {
          value = "";
        }
      }
      return value;
    });
    return newObj;
  }

  componentDidMount() {
    if (this.state.isAuthenticated) {
      this.displayImplementInfo();
    }
  }

  /**
   * fetch data from backend to display on ui.
   */
  displayImplementInfo() {
    let implementData;
    let implementId = this.props.match.params.implementId;

    // fetch data
    ImplementsService.getImplement(implementId).then(response => {
      implementData = this.removeNullValues(response.data);

      this.setState({
        formfields: implementData,
        savedFormFields: { ...implementData },
        isLoading: false
      });
    }, error => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }

      this.setState({
        implementData: {},
        isLoading: false,
        isErrorOnLoad: true,
        apiResponseMsg: errorMsg,
        alert: {
          type: "danger",
          message: errorMsg
        },
      });
    });
  }

  /**
   * helper function to validate decimal values entered.
   * @param {*} num 
   */
  validateDecimalNumbers(num) {
    if ((num.length > 0) &&
      ((isNaN(num) || (num.indexOf(".") === 0) || (num.indexOf(".") === num.length - 1)) ||
        !(/^\d+(\.\d{1,6})?$/.test(num)))) {
      return false;
    }

    return true;
  }

  /**
   * handler to validate form inputes
   */
  handleUserInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    let formfields = this.state.formfields;
    formfields[name] = value;

    let fieldValidationErrors = this.state.formErrors;
    let msg = "";

    switch (name) {
      case "name":
        if (value.length === 0) {
          msg = i18n.t('userVehicles.implements.alert.required');
        } else if (value.length > 64) {
          msg = i18n.t('userVehicles.implements.alert.char64');
        }
        
        break;

      case "length":
        if (value.length < 1) {
          msg = i18n.t('userVehicles.implements.alert.required');
        } else if (!this.validateDecimalNumbers(value)) {
          msg = i18n.t('userVehicles.implements.alert.decimal');
        }
        
        break;

      case 'position':
        if (value.length < 1) {
            msg = i18n.t('userVehicles.implements.alert.required');
        } else if (value && !value.match(/^[1-2]$/i)) {
            msg = i18n.t('userVehicles.implements.alert.integerLimit');
        }
        
        break;

      case "localId":
        if (value.length > 256) {
          msg = i18n.t('userVehicles.implements.alert.char256');
        }
        
        break;

      default:
        break;
    }

    fieldValidationErrors[name] = msg;

    let isFormValid = (formfields.name && formfields.length && formfields.position) &&
                        (fieldValidationErrors.name === "" && fieldValidationErrors.length === "" 
                        && fieldValidationErrors.position === "" && fieldValidationErrors.localId === "");

    this.setState({
      formfields: formfields,
      formErrors: fieldValidationErrors,
      formValid: isFormValid,
    });
  }
  
  /**
   * handles implements submit function. this calls api to upload the updated implement.
   * @param {*} event 
   */
  submitHandler(event) {
    event.preventDefault();

    let that = this;

    that.setState({
      isWaitingApiResponse: true
    });

    ImplementsService.updateImplement(this.state.formfields).then(function (res) {
      let lastSavedFormFields = that.state.formfields;
      that.setState({
        alert: {
          type: "success",
          message: i18n.t('userVehicles.implements.alert.implementUpdate')
        },
        isWaitingApiResponse: false,
        savedFormFields: { ...lastSavedFormFields }
      });
    }, function (res) {
      let alertMsg = i18n.t('common.genericApiError');

      if (res && res.data && res.data.message) {
        alertMsg = res.data.message;
      }

      that.setState({
        alert: {
          type: "danger",
          message: alertMsg
        },
        isWaitingApiResponse: false
      });
    });

  }

  /*
   alert message handling
   */
  handleAlertDismiss() {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * helper function
   * @param {*} implementObj1 
   * @param {*} implementObj2 
   */
  isEqual(implementObj1, implementObj2) {
    let result = true;
    _.forOwn(implementObj1, function (value, key) {
      if (_.toString(value) !== _.toString(implementObj2[key])) {
        result = false;
      }
    });

    return result;
  }

  /**
   * handler to check if form is valid
   */
  isFormValid() {
    let result = this.isEqual(this.state.formfields, this.state.savedFormFields);
    let returnVal = (!result && this.state.formValid);

    return returnVal;
  }

  /**
   * navigate to previous page
   */
  handleBack() {
    this.props.history.goBack();
  }

  /**
   * reset form fields to previous state
   */
  resetImplementFormFields() {
    let prevSavedFormFields = this.state.savedFormFields;

    this.setState({
      formfields: { ...prevSavedFormFields },
      formErrors: {
        name: "",
        length: "",
        position: "",
        localId: ""
      },
    });
  }

  /**
   * edit implement form
   */
  renderImplementForm() {
    return (
      <LoadingOverlay active={this.state.isWaitingApiResponse || this.state.isLoading}>
        <div className="container-fluid">
          <form>

            <h2>{i18n.t('userVehicles.implements.editImplement')}</h2>
            <div className="view-profile-details width-70per">
              <div className="row">
                <div className="col-md-7">
                  <div className="form-group">
                    <label htmlFor="name">{i18n.t('userVehicles.implements.form.label.name')} <StyledText uiText="*"/></label>
                    <input type="text" required className="form-control" name="name"
                      placeholder={i18n.t('userVehicles.implements.form.placeholder.name')}
                      value={this.state.formfields.name}
                      onChange={this.handleUserInput} maxLength="32" />
                    <FormErrors formErrors={this.state.formErrors.name} />
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="form-group">
                    <label htmlFor="length">{i18n.t('userVehicles.implements.form.label.length')} <StyledText uiText="*"/></label>
                    <input type="text" required className="form-control" name="length"
                      placeholder={i18n.t('userVehicles.implements.form.placeholder.length')}
                      value={this.state.formfields.length}
                      onChange={this.handleUserInput} maxLength="32" />
                    <FormErrors formErrors={this.state.formErrors.length} />
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="form-group">
                    <label htmlFor="position">{i18n.t('userVehicles.implements.form.label.position')} <StyledText uiText="*"/></label>
                    <input type="text" required className="form-control" name="position"
                      placeholder={i18n.t('userVehicles.implements.form.placeholder.position')}
                      value={this.state.formfields.position}
                      onChange={this.handleUserInput}/>
                    <FormErrors formErrors={this.state.formErrors.position} />
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="form-group">
                    <label htmlFor="localId">{i18n.t('userVehicles.implements.form.label.localId')}</label>
                    <input type="text" className="form-control" name="localId"
                      placeholder={i18n.t('userVehicles.implements.form.placeholder.localId')}
                      value={this.state.formfields.localId}
                      onChange={this.handleUserInput} maxLength="256" />
                    <FormErrors formErrors={this.state.formErrors.localId} />
                  </div>
                </div>
              </div>

            </div>

            <div className="col-md-12 text-right">
              <button type="button" className="btn btn-secondary mr-2"
                onClick={this.resetImplementFormFields.bind(this)}>
                {i18n.t('userVehicles.implements.form.button.reset')}
              </button>
              <button type="submit" className="btn btn-primary"
                disabled={(!this.isFormValid() || this.state.isWaitingApiResponse)}
                onClick={this.submitHandler.bind(this)}>
                {i18n.t('userVehicles.implements.form.button.save')}
              </button>
            </div>

          </form>
        </div>
      </LoadingOverlay>
    );
  }

  /**
   * renders errors
   */
  renderError() {
    return (
      <div className="col-md-12 text-right f-btn">
        <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>
          {i18n.t('userVehicles.implements.form.button.back')}
        </button>
      </div>
    );
  }

  /**
   * render edit implement form
   */
  render() {
    return (
      <div className="container-fluid p-4">
        {
          this.state.alert.message &&
          <AlertMessage message={this.state.alert.message}
            type={this.state.alert.type}
            isAlertOpen={!!(this.state.alert.message)}
            handleDismiss={this.handleAlertDismiss.bind(this)}
          />
        }

        {
          this.state.isErrorOnLoad ? this.renderError() : this.renderImplementForm()
        }
      </div>
    );
  }

}

export default EditImplement;
