import React from 'react';
import Grid from '../../../../Component/grid/Grid';
import ModalDialog from '../../../../Component/modaldialog/ModalDialog';
import AlertMessage from '../../../../Component/alert/AlertMessage';
import BaseScreen from '../../BaseScreen';
import { SCREENS } from '../../../../constants/screens.constant';
import { VehiclesService } from '../../../../services/service.vehicles';
import AppInfoHelper from '../../../../shared/utils/appInfoHelper';
import { ROLE_TYPES } from '../../../../constants/app.constants';
import i18n from 'i18next';

/**
 * Informations to be shown in vehicle listing grid, along with options per rows.
 * vehicle table data configuration - for own vehicles
 */
let vehicleListConfigAssigned = {};
/**
 * vehicle table data configuration - for default vehicles
 */
let vehicleListConfigDefault = {};

/**
 * initialize vehicleListConfig with updated i18n resources
 */
function setVehicleListConfig() {
  vehicleListConfigAssigned = {
    make: {
      label: i18n.t('userVehicles.vehicles.list.make'),
      sort: false,
      filter: true,
      display: true,
      filterMaxLength: 50
    },
    model: {
      label: i18n.t('userVehicles.vehicles.list.model'),
      sort: false,
      filter: true,
      display: true,
      filterMaxLength: 50
    },
    year: {
      label: i18n.t('userVehicles.vehicles.list.year'),
      sort: true,
      filter: false,
      display: true,
      filterMaxLength: 0
    },
    options: {
      label: i18n.t('userVehicles.vehicles.list.options.options'),
      sort: false,
      filter: false,
      display: true,
      list: [
        {
          actionType: 'link',
          action: 'view',
          label: i18n.t('userVehicles.vehicles.list.options.view')
        }, {
          actionType: 'link',
          action: 'edit',
          label: i18n.t('userVehicles.vehicles.list.options.edit')
        }, {
          actionType: '',
          action: 'delete',
          label: i18n.t('userVehicles.vehicles.list.options.delete')
        }
      ]
    }
  };

  vehicleListConfigDefault = {
    make: {
      label: i18n.t('userVehicles.vehicles.list.make'),
      sort: false,
      filter: true,
      display: true,
      filterMaxLength: 50
    },
    model: {
      label: i18n.t('userVehicles.vehicles.list.model'),
      sort: false,
      filter: true,
      display: true,
      filterMaxLength: 50
    },
    year: {
      label: i18n.t('userVehicles.vehicles.list.year'),
      sort: true,
      filter: false,
      display: true,
      filterMaxLength: 0
    },
    options: {
      label: i18n.t('userVehicles.vehicles.list.options.options'),
      sort: false,
      filter: false,
      display: true,
      list: [
        {
          actionType: 'link',
          action: 'view',
          label: i18n.t('userVehicles.vehicles.list.options.view')
        }, {
          actionType: 'link',
          action: 'assign',
          label: i18n.t('userVehicles.vehicles.list.options.assign')
        }
      ]
    }
  };
}

/**
 * List vehicle component
 */
class ListVehicle extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    // update vehicleListConfig
    setVehicleListConfig();

    this.state = {
      isAuthenticated: isAuthenticated,
      gridListConfig: vehicleListConfigAssigned,
      // for managing accountHolder/operator userType
      loggedInUserInfo: undefined,
      selectedAccountHolder: undefined,
      accountId: '',

      selectedVehicleType: 'assigned', // default or own vehicles
      vehiclesData: [],
      loader: true,
      pgnConfig: {
        currentPage: 1,
        numberOfPageLinks: 0,
        countPerPage: 25, // default page size
        totalCount: 0,
        pageList: [25, 50, 100]
      },
      modal: {
        modalShow: false,
        shouldRenderModal: false,
        modalTitle: '',
        modalMsg: '',
        modalData: '',
        modalAction: ''
      },
      alert: {
        type: null,
        message: null
      },
      sortFieldsConfig: {},
      filterFieldsConfig: {},
      reinitialize: false,
      // default sorting
      defaultSortField: 'modifiedAt',
      defaultSortOrder: -1
    };
  }

  /**
   * on mount user role resolve and vehicles fetching from backend service
   */
  componentDidMount () {
    const self = this;
    const userInfoInstance = AppInfoHelper.getUserInstance();

    userInfoInstance.getUserInfo().then(function (response) {
      const userInfo = response;
      const selectedAccountHolder = userInfoInstance.getSelectedAccountHolderForOperator();
      if (userInfo.roleId === ROLE_TYPES.OPERATOR.ID && !selectedAccountHolder) {
        const warningMsg = i18n.t('userVehicles.vehicles.alert.selectAccountholder');
        self.setState(
          {
            vehiclesData: [],
            loader: false,
            loggedInUserInfo: userInfo,
            modalShow: false,
            reinitialize: false,
            alert: {
              type: 'danger',
              message: warningMsg
            }
          });
      } else {
        /**
         * fetch data for assigned vehicles
         */
        const accountHolderId = userInfo.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID
          ? userInfo.id : selectedAccountHolder.id;

        self.setState(
          {
            loader: true,
            loggedInUserInfo: userInfo,
            selectedAccountHolder: selectedAccountHolder,
            accountId: accountHolderId
          });

        const arg = {
          currentPage: 0,
          pageSize: self.state.pgnConfig.countPerPage
        };

        self.getVehicleData(arg, self.state.sortFieldsConfig, self.state.filterFieldsConfig)
          .then(response => {
            const pgnConfig = self.setPaginationConfig(self.state.pgnConfig.currentPage, self.state.pgnConfig.countPerPage, response.data.total);
            self.setState({ vehiclesData: response.data.items, loader: false, pgnConfig: pgnConfig, modalShow: false, reinitialize: false });
          }, error => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
              errorMsg = error.data.message;
            }
            self.setState(
              {
                vehiclesData: [],
                loader: false,
                modalShow: false,
                reinitialize: false,
                alert: {
                  type: 'danger',
                  message: errorMsg
                }
              });
          });
      }
    }, function (error) {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }
      self.setState(
        {
          vehiclesData: [],
          loader: false,
          modalShow: false,
          reinitialize: false,
          alert: {
            type: 'danger',
            message: errorMsg
          }
        });
    });
  }

  /**
   * service to get vehicles from backend service
   * @param {*} requiredParams 
   * @param {*} sortFieldsObj 
   * @param {*} filterFieldsObj 
   */
  getVehicleData (requiredParams, sortFieldsObj, filterFieldsObj) {
    let reqQueryParams = '';

    // build required params
    let params;
    if (requiredParams) {
      params = {
        currentPage: requiredParams.currentPage,
        pageSize: requiredParams.pageSize
      };
    } else {
      params = {
        currentPage: this.state.pgnConfig.currentPage - 1,
        pageSize: this.state.pgnConfig.countPerPage
      };
    }

    reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' + params.pageSize;

    // add sort parameters (if available)
    let sortFieldsQueryParams = '';
    if (!sortFieldsObj) {
      sortFieldsObj = this.state.sortFieldsConfig;
    }

    let sortQueryTemp = '';
    let key;
    for (key in sortFieldsObj) {
      const sortValue = sortFieldsObj[key].sortOrder;
      if (sortValue) {
        sortQueryTemp = 'sortfield=' + key + '&orderType=' + ((sortValue === 1) ? 'asc' : 'desc');
        sortFieldsQueryParams += '&' + sortQueryTemp;
      }
    }

    // add filter parameters (if available)
    let filterFielddQueryParams = '';
    if (!filterFieldsObj) {
      filterFieldsObj = this.state.filterFieldsConfig;
    }

    let filterQueryTemp = '';
    for (key in filterFieldsObj) {
      const filterValue = filterFieldsObj[key].value;
      if (filterValue) {
        filterQueryTemp = key + '=' + filterValue;
        filterFielddQueryParams += '&' + filterQueryTemp;
      }
    }

    reqQueryParams += sortFieldsQueryParams + filterFielddQueryParams;

    if (this.state.selectedVehicleType === 'assigned') {
      reqQueryParams += '&accountId=' + this.state.accountId;
    }

    return VehiclesService.getVehicles(reqQueryParams)
      .then(response => {
        return response;
      }, errResponse => {
        return Promise.reject(errResponse);
      });
  }

  /**
   * helper function for pagination configuration
   * @param {*} currentPage 
   * @param {*} countPerPage 
   * @param {*} totalCount 
   */
  setPaginationConfig (currentPage, countPerPage, totalCount) {
    return {
      currentPage: currentPage,
      numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
      countPerPage: countPerPage,
      totalCount: totalCount,
      pageList: [...this.state.pgnConfig.pageList]
    };
  }

  /**
   * handler when page number change, like user move to next page or previous page from current page he is viewing
   * @param {*} pageNumber 
   */
  onPgnChange (pageNumber) {
    if (pageNumber !== this.state.pgnConfig.currentPage) {
      window.scrollTo(0, 0);
      this.setState({ loader: true, vehiclesData: [] });

      const arg = {
        currentPage: (pageNumber - 1),
        pageSize: this.state.pgnConfig.countPerPage
      };

      this.getVehicleData(arg).then((response) => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        this.setState({
          vehiclesData: response.data.items,
          loader: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          },
          modalShow: false
        });
      }, (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            vehiclesData: [],
            loader: false,
            modalShow: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /**
   * handles the grid listing when there is any change per page row count, like if user chose default row count 25 to 50 or something else.
   * @param {*} event 
   */
  onPgnRowsCountChange (event) {
    window.scrollTo(0, 0);

    const perPageItems = Number(event.target.value);
    let currentPage;
    if ((this.state.pgnConfig.currentPage - 1) * perPageItems >= this.state.pgnConfig.totalCount) {
      currentPage = 1;
    } else {
      currentPage = this.state.pgnConfig.currentPage;
    }

    // re-fetch data
    this.setState({ loader: true, vehiclesData: [] });
    const arg = {
      currentPage: currentPage - 1,
      pageSize: perPageItems
    };

    this.getVehicleData(arg).then((response) => {
      const pgnConfig = this.setPaginationConfig(currentPage, perPageItems, response.data.total);
      this.setState({
        vehiclesData: response.data.items,
        loader: false,
        pgnConfig: pgnConfig,
        alert: {
          type: null,
          message: null
        },
        modalShow: false
      });
    }, (error) => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }
      this.setState(
        {
          vehiclesData: [],
          loader: false,
          modalShow: false,
          alert: {
            type: 'danger',
            message: errorMsg
          }
        });
    });
  }

  /**
   * lazy load function when user use filter or sort functionality from grid header
   * @param {*} sortFieldsObj 
   * @param {*} filterFieldsObj 
   */
  onLazyLoad (sortFieldsObj, filterFieldsObj) {
    this.setState({ loader: true, vehiclesData: [] });
    const pageNumber = 1;
    const arg = {
      currentPage: pageNumber - 1,
      pageSize: this.state.pgnConfig.countPerPage
    };

    this.getVehicleData(arg, sortFieldsObj, filterFieldsObj)
      .then((response) => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);

        // sortingData
        if (!sortFieldsObj) {
          sortFieldsObj = this.state.sortFieldsConfig;
        }

        // filteringData
        if (!filterFieldsObj) {
          filterFieldsObj = this.state.filterFieldsConfig;
        }

        this.setState({
          vehiclesData: response.data.items,
          loader: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          },
          modalShow: false,
          sortFieldsConfig: sortFieldsObj,
          filterFieldsConfig: filterFieldsObj
        });
      }, (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            vehiclesData: [],
            loader: false,
            modalShow: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
  }

  /**
   * routing to relevant page based on row option selection
   * @param {*} actionType 
   * @param {*} row 
   */
  actionPaths (actionType, row) {
    if (actionType.action === 'view') {
      return ({
        pathname: '/user-vehicles/vehicles/view-vehicle/' + row.id
      });
    } else if (actionType.action === 'edit' || actionType.action === 'assign') {
      return ({
        pathname: '/user-vehicles/vehicles/edit-vehicle/' + row.id
      });
    }
  }

  /**
   * for handling options having confirmation modals
   * @param {*} actionObject 
   * @param {*} row 
   */
  optionHandler (actionObject, row) {
    let modalInfo;
    if (actionObject.action === 'delete') {
      modalInfo = {
        modalMsg: i18n.t('userVehicles.vehicles.form.modal.deleteVehicleMsg', { make: row.make }),
        modalTitle: i18n.t('userVehicles.vehicles.form.modal.deleteVehicleTitle'),
        modalAction: 'delete_vehicle'
      };
    }

    this.modalPopup(true, row, modalInfo);
  }

  /**
   * Modal window to show each row options
   * @param {*} state 
   * @param {*} rowData 
   * @param {*} modalInfo 
   */
  modalPopup (state, rowData, modalInfo) {
    if (state) {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalMsg: modalInfo.modalMsg,
          modalTitle: modalInfo.modalTitle,
          modalData: rowData || '',
          modalAction: modalInfo.modalAction
        }
      });
    } else {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalData: rowData || '',
          modalAction: this.state.modal.modalAction
        }
      });
    }
  }

  /**
   * modal dismissal action
   */
  handleModalDismiss () {
    this.modalPopup(false);
  }

  /**
   *  handle modal delete action.
   * @param {*} data 
   */
  onModalAction (data) {
    const modalAction = this.state.modal.modalAction;
    if (modalAction === 'delete_vehicle') {
      this.modalPopup(false, data);
    }
  }

  /**
   * function after modal is closed, re-render listing page after delete operation.
   */
  onModalClosed () {
    if (this.state.modal.modalAction === 'delete_vehicle') {
      this.deleteVehicle();
    }
  }

  /**
   * delete a vehicle from backend
   */
  deleteVehicle () {
    // execute delete vehicle query
    if (this.state.modal.modalData) {
      const modalData = this.state.modal.modalData;
      // set loader on
      this.setState({
        loader: true,
        modal: {
          modalShow: false,
          shouldRenderModal: false
        }
      });

      VehiclesService.deleteVehicle(modalData.id)
        .then((successResponse) => {
          let pageNumber = this.state.pgnConfig.currentPage;
          if ((this.state.vehiclesData.length <= 1) && (this.state.pgnConfig.currentPage > 1)) {
            pageNumber = this.state.pgnConfig.currentPage - 1;
          }

          this.setState({
            vehiclesData: [],
            alert: {
              type: 'success',
              message: i18n.t('userVehicles.vehicles.alert.vehicleDelete')
            }
          });

          const arg = {
            currentPage: (pageNumber - 1),
            pageSize: this.state.pgnConfig.countPerPage
          };

          this.getVehicleData(arg).then((response) => {
            const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
            this.setState({
              vehiclesData: response.data.items,
              loader: false,
              pgnConfig: pgnConfig,
              modal: {
                modalShow: false,
                shouldRenderModal: false
              }
            });
          }, (error) => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
              errorMsg = error.data.message;
            }
            this.setState(
              {
                vehiclesData: [],
                loader: false,
                alert: {
                  type: 'danger',
                  message: errorMsg
                }
              });
          });
        }, (error) => {
          let errorMsg = i18n.t('common.genericApiError');
          if (error && error.data && error.data.message) {
            errorMsg = error.data.message;
          }
          this.setState(
            {
              loader: false,
              alert: {
                type: 'danger',
                message: errorMsg
              }
            });
        });
    }
  }

  /*
   alert message handling
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * handler to handle event when vehicle type change from assigned to default and vice versa
   * @param {*} vehicleTypeSelection 
   */
  onVehicleTypeChange (vehicleTypeSelection) {
    const currentVehicleType = this.state.selectedVehicleType;
    if (currentVehicleType !== vehicleTypeSelection) {
      const gridListConfig = vehicleTypeSelection === 'assigned' ? vehicleListConfigAssigned : vehicleListConfigDefault;

      // start from first page
      const pageNumber = 1;
      const arg = {
        currentPage: pageNumber - 1,
        pageSize: this.state.pgnConfig.countPerPage
      };

      const sortFieldsObj = {};
      const filterFieldsObj = {};

      this.setState({
        loader: true,
        vehiclesData: [],
        reinitialize: true,
        selectedVehicleType: vehicleTypeSelection,
        gridListConfig: gridListConfig
      }, () => {
        // setState callback function to get updated state value of vehicleTypeSelection
        this.getVehicleData(arg, sortFieldsObj, filterFieldsObj)
          .then(response => {
            const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
            this.setState({
              vehiclesData: response.data.items,
              loader: false,
              pgnConfig: pgnConfig,
              modalShow: false,
              reinitialize: false,
              sortFieldsConfig: sortFieldsObj,
              filterFieldsConfig: filterFieldsObj
            });
          }, error => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
              errorMsg = error.data.message;
            }
            this.setState(
              {
                vehiclesData: [],
                loader: false,
                modalShow: false,
                reinitialize: false,
                alert: {
                  type: 'danger',
                  message: errorMsg
                }
              });
          });
      });
    }
  }

  /**
   * render assigned vehicle button
   */
  renderAssignedVehicleBtn () {
    if (this.state.loggedInUserInfo) {
      if ((this.state.loggedInUserInfo.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID) ||
        (this.state.loggedInUserInfo.roleId === ROLE_TYPES.OPERATOR.ID && this.state.selectedAccountHolder)) {
        return (
          <button data-testid="list-my-vehicles-btn" type="button" className={'btn mr-2 ' +
            (this.state.selectedVehicleType === 'assigned' ? 'btn-primary-outline' : 'btn-secondary-outline')}
          onClick={this.onVehicleTypeChange.bind(this, 'assigned')}>
            {i18n.t('userVehicles.vehicles.form.button.myVehicles')}
          </button>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  /**
   * react render
   */
  render () {
    return (
      <div>
        <div className="container-fluid p-4 ">
          <div className="vehicles-content">
            <div >
              {
                this.renderAssignedVehicleBtn()
              }
              <button data-testid="list-default-vehicles-btn" 
                className={'btn mr-2 ' + (this.state.selectedVehicleType === 'assigned' ? 'btn-secondary-outline' : 'btn-primary-outline')}
                onClick={this.onVehicleTypeChange.bind(this, 'default')}>
                {i18n.t('userVehicles.vehicles.form.button.defaultVehicles')}
              </button>
            </div>

            <br />
            {
              this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)}
            />
            }
          </div>
          <div>
            <Grid id="vehiclesListGrid"
              optionHandler={this.optionHandler.bind(this)}
              keyColumn="id"
              rowData={this.state.vehiclesData}
              reinitialize={this.state.reinitialize}
              columnDefs={this.state.gridListConfig}
              loading={this.state.loader}
              pagination={true}
              pgnConfig={this.state.pgnConfig}
              onPgnChange={this.onPgnChange.bind(this)}
              onPgnRowsCountChange={this.onPgnRowsCountChange.bind(this)}
              pageList={this.state.pageList}
              lazyLoading={true}
              onLazyLoad={this.onLazyLoad.bind(this)}
              actionPaths={this.actionPaths.bind(this)}
            />
          </div>
        </div>

        {
          this.state.modal.shouldRenderModal &&
          <ModalDialog modalTitle={this.state.modal.modalTitle}
            modalMessage={this.state.modal.modalMsg}
            modalData={this.state.modal.modalData}
            isModalOpen={this.state.modal.modalShow}
            modalAction={this.onModalAction.bind(this)}
            handleModalDismiss={this.handleModalDismiss.bind(this)}
            onModalClosed={this.onModalClosed.bind(this)}
          />
        }
      </div>);
  }
}

export default ListVehicle;
