import React from 'react';

/**
 * Component for generating styled text, like 
 * styling mandatory (*) text in red
 * @param {object} props
 */
export const StyledText = (props) => {
  return (
    <>
      <span className={props.className ? props.className : "styled-text-default"}>
        {props.uiText}
      </span>
    </>
  );
};
